import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { getPassiveVoice } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, highlightTextBlast, quillContentInsertBlotByContentIndex, highlightTextByIndex } from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function PassiveVoice() {
  const [
    docId,
    report,
    setReport,
    quill,
    setQuill,
    userData,
    setUserData,
    propsRouter,
    setAvailableTokens,
    loader,
    handleLoaderOff,
    checkPage,
    setCheckPage, 
    setAiResultOutput, 
    setShowAIResultPopup
  ] = useOutletContext();

  useEffect(() => {
    setReport({});
    setCheckPage('passive voice');
    setAiResultOutput([]);
    setShowAIResultPopup(false);
    if (quill && quill.getLength() > 3) {
      loadPassiveVoice();
    }
  }, [propsRouter]);

  const loadPassiveVoice = async () => {
    handleLoaderOff(true);
    setTimeout(async () => {
      try {
        if (userData.packages[0] && userData.user) {
          let quillContent = await sanitizeResetQuillAndGetContent(quill);
          let images = document.querySelectorAll('.ql-editor img');
          for (const image of images) {
            let blot = await Quill.find(image);
            let index = blot.offset(quill.scroll);
            quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
          }
          let response = await getPassiveVoice(quillContent);
          response = response.data.msg.filter((d) => d.reason.slice(d.reason.indexOf('" ') + 1, d.reason.length) == " may be passive voice");

          let resultArr = [];
          response = response.map((d) => {
            let arr = d.reason.split('"')[1].split(" ");
            var start = d.index + d.reason.indexOf(arr[arr.length - 1]) - 1;
            resultArr.push({ word: arr[arr.length - 1], wordStartIndex: start, wordEndIndex: start + arr[arr.length - 1].length });
          });

          quillContentInsertBlotByContentIndex(quill, 0, resultArr, "wordStartIndex", "wordEndIndex", 
              [{
                  blotName: "span",
                  id: `sentence-starter-{index}`,
                  classes: [`sentence-starter`, `sentence-starter-{word}`],
                  classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
              }]
          );

          setReport({
            lastReportIndexes: {
              start: 0,
              end: quill.getLength(),
            },
            data: {
              keywordsData: resultArr,
            },
          });

          handleLoaderOff(false);
        } else {
          handleLoaderOff(false);
          generateNotification({
              title: "Account Expired!",
              text: "Please check your account for the payment, This report is currently not available.",
              icon: "error",
          });
        }
      } catch (error) {
        handleLoaderOff(false);
        generateNotification({
            title: "Network Error!",
            text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
            icon: "error",
        });
      }
    });
  };

  let keywordsData = [],
    unnecessary = [];
  if (report?.data) {
    keywordsData = report.data.keywordsData || [];
    unnecessary = report.data.unnecessary || [];
  }
  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
          <span>Passive Voice</span>
          <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/passive-voice/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
          <span className="ms-auto">
              <button onClick={loadPassiveVoice} className="btn theme-btn4 fs-12px">Refresh</button>
          </span>
        </div>
      </div>
      <div className="result_card_cover text-start">
        <div className="reportContainer mt-0">
          <div className="reportContainer">
            {!loader && keywordsData.length == 0 && 
                <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
            }
            {loader && keywordsData.length == 0 && 
                <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
            }
            {keywordsData && keywordsData.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 sentence-starter-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `sentence-starter-${_.kebabCase(v.word)}`)} key={i} >
                <div className="card-body">
                  <input className="form-check-input" id={`sentence-starter-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  {v.word} <span className="count">{v.wordsCount}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
