export const glueWords = [
    "a", 
    "about", 
    "an", 
    "and", 
    "asked", 
    "be", 
    "but", 
    "by", 
    "every", 
    "for", 
    "from", 
    "get", 
    "go", 
    "have", 
    "if", 
    "in", 
    "into", 
    "is", 
    "it", 
    "Just", 
    "like", 
    "main", 
    "make", 
    "much", 
    "new", 
    "of", 
    "on", 
    "or", 
    "over", 
    "said", 
    "should", 
    "so", 
    "some", 
    "than", 
    "that", 
    "the", 
    "there", 
    "think", 
    "this", 
    "to", 
    "was", 
    "what", 
    "would", 
    "regards", 
    "will", 
    "with", 
    "many", 
    "when", 
    "while", 
    "lot",
    "great"
];