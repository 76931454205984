import React, { useState, useEffect } from "react";
import { getData } from "../../services/dataService";
import { getAllPackages } from "../../services/documentService";
import { handleHttpError } from "../../services/httpErrorService";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getProfile } from "../../services/userService";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const Pricing = () => {
  const navigate = useNavigate();
  const [handleLoaderOff] = useOutletContext();
  const [packages, setPackages] = useState([]);
  const [user, setUser] = useState("");

  useEffect(() => {
    const token = getData("token");
    const user = getData("user");
    setUser(JSON.parse(user));
    if (token != undefined || token != null) {
      getAllPackages()
        .then((httpResponse) => handleListPackageSuccess(httpResponse))
        .catch((httpResponse) => handleHttpError(httpResponse.response));
    } else {
      navigate(`/login`);
    }
  }, []);

  const handleListPackageSuccess = (httpResponse) => {
    getProfile().then((response) => {
      let filtered = [];
      if (response.data.result.user.user_mode == 'Student') {
        filtered = httpResponse.data.result.filter((res) => (res.id == 17 || res.id == 18))
        setPackages(filtered);
      } else {
        filtered = httpResponse.data.result.filter((res) => (res.id == 2 || res.id == 3))
        setPackages(filtered);
      }
      handleLoaderOff(false);
    })
  };

  return (
    <div className="container-fluid">
      <div className="col-md-12 text-center">
        <h2 className="py-4 text-uppercase fw-normal" style={{ color: "#ef5d14" }}>
          Pricing Plans
        </h2>
      </div>
      <div className="pricing__table">
        <div className="pt__title">
          <div className="pt__title__wrap">
            <div className="pt__row"></div>
            <div className="pt__row"></div>
            
            <div className="pt__row">Can create documents</div>
            <div className="pt__row"> Access to 36 distinct report kinds.</div>
            <div className="pt__row">Citation</div>
            <div className="pt__row" style={{fontSize:"12px"}}> Access to Quick AI Writing and Editing with 99.99% Precision</div>
            <div className="pt__row">Tokens per month</div>
            <div className="pt__row"> Upto 200k words (per doc.)</div>
            <div className="pt__row">Autosave</div>
            <div className="pt__row"> Access to 30+ AI Templates</div>
            <div className="pt__row">Access to lore book</div>
            <div className="pt__row"> Number AI Model Options</div>
          </div>
        </div>
        <div className="pt__option">
          <Swiper className="mySwiper"
            slidesPerView={1}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{ 640: { slidesPerView: 1, spaceBetween: 10 }, 768: { slidesPerView: 2, spaceBetween: 10 }, 1024: { slidesPerView: 3, spaceBetween: 10 }, }} 
          >
            <SwiperSlide>
              <div className="swiper-slide pt__option__item">
                <div className="pt__item recommend">
                  <div className="pt__item__wrap">
                    <div className="pt__row">
                        <small style={{fontSize:'12px',letterSpacing:'2px'}}>MOST POPULAR</small>
                        PROFESSIONAL
                    </div>
                    <div className="pt__row pt__row2">
                      <div className="row">
                        <div className="col-12">
                          <small>$29/mo</small>
                          <a href="" style={{color:'#fff', background:'#051d33'}} className="px-1 py-2 text-decoration-none cursor-pointer d-lg-block d-md-block d-none fs-12px light_orange_btn mx-1 my-2 join_price">
                            <span className="mdi mdi-plus me-1"></span>
                            Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-md-12">Unlimited</div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                          <div className="col-md-12">
                            200,000
                          </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          8
                        </div>
                      </div>
                    </div>
                    <div className="pt__row w-100">
                      <div className="row w-100">
                        <div className="col-12">
                          <a href="" style={{color:'#fff', background:'#051d33'}} className="w-100 px-1 py-2 text-decoration-none cursor-pointer d-lg-block d-md-block d-none fs-12px light_orange_btn mx-1 my-2 ">
                            <span className="mdi mdi-plus me-1"></span>Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide pt__option__item">
                <div className="pt__item border-end">
                  <div className="pt__item__wrap">
                    <div className="pt__row">
                        <small style={{fontSize:'12px',letterSpacing:'2px'}}>COMPLETE SOLUTION</small>ANNUAL PROFESSIONAL
                    </div>
                    <div className="pt__row pt__row2">
                      <div className="row">
                        <div className="col-12">
                          <small>$297/Year</small>
                          <a href="" className="px-1 py-2 text-decoration-none cursor-pointer d-lg-block d-md-block d-none fs-12px light_orange_btn mx-1 my-2 join_price">
                            <span className="mdi mdi-plus me-1"></span>
                            Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-md-12">
                          Unlimited
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                          <div className="col-md-12">
                            200,000
                          </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        <div className="col-12">
                          8
                        </div>
                      </div>
                    </div>
                    <div className="pt__row w-100">
                      <div className="row w-100">
                        <div className="col-12">
                          <a href="" className="w-100 px-1 py-2 text-decoration-none cursor-pointer d-lg-block d-md-block d-none fs-12px light_orange_btn mx-1 my-2">
                            <span className="mdi mdi-plus me-1"></span>
                            Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide pt__option__item">
                <div className="pt__item border-end">
                  <div className="pt__item__wrap">
                    <div className="pt__row">
                        <small style={{fontSize:'12px',letterSpacing:'2px'}}>ADD ON PLAN</small>
                        ADD ON TOKENS
                    </div>
                    <div className="pt__row">
                      <div className="row">
                        
                        <div className="col-12">
                          <small>$15</small>
                          <a href="" className="px-1 py-2 text-decoration-none cursor-pointer d-lg-block d-md-block d-none fs-12px light_orange_btn mx-1 my-2 ">
                            <span className="mdi mdi-plus me-1"></span>
                            Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="pt__row pt__row2">
                      <div className="row">
                        <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                      <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                      <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                      <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" style={{height:'160px'}}>
                      <div className="row">
                        <div className="col-md-12">
                          200,000 extra tokens with the add on plan only existing members with active membership will be able to purchase.
                        </div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                        <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                        <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                        <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row" >
                      <div className="row">
                        <div className="col-md-12">-</div>
                      </div>
                    </div>
                    <div className="pt__row w-100">
                      <div className="row w-100">
                        <div className="col-12">
                          <a href="" className="w-100 px-1 py-2 text-decoration-none cursor-pointer d-lg-block d-md-block d-none fs-12px light_orange_btn mx-1 my-2 ">
                            <span className="mdi mdi-plus me-1"></span>Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
