export const future = [
    "will",
    "shall",
    "to be",
    "to ask",
    "to work",
    "to call",
    "to use",
    "to have",
    "won't",
]

export default future;