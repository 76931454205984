import React from "react";

export default function Card2({ title, description }) {
  return (
    <div className={`card fs-12px custom-card2 mb-3 p-2`}>
      <div className="card-body">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <span className="fs-14px fw-medium">{title}</span>
          </div>
          <div className="col-12 d-flex align-items-center">
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </div>
        </div>
      </div>
    </div>
  );
}
