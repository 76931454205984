import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import NLPService from "../../services/nlpService";
import { sanitizeResetQuillAndGetContent, highlightTextBlast } from "../../services/highlightContent";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function TagReport() {

    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();
    
    const [genre, setGenre] = useState(23);

    useEffect(() => {
        setReport({});
        setCheckPage('tag');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
          loadTagReport();
        }
    }, [propsRouter]);

    const loadTagReport = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }
                    let nlpService = new NLPService("");
                    nlpService.setContent(quillContent);
                    let response = await nlpService.getTagReport();

                    let tagReportData = [
                        {
                            label: "Verb", 
                            color: "FE595F", 
                            appearence: response.filter((d) => { if (d.wordTag === "VERB") return d; }),
                        }, {
                            label: "Noun", 
                            color: "FFCA39", 
                            appearence: response.filter((d) => { if (d.wordTag === "NOUN") return d; }),
                        }, {
                            label: "Auxiliaries", 
                            color: "8AC827", 
                            appearence: response.filter((d) => { if (d.wordTag === "AUX") return d; }),
                        }, {
                            label: "Adjective", 
                            color: "1B82C4", 
                            appearence: response.filter((d) => { if (d.wordTag === "ADJ") return d; }),
                        }, {
                            label: "Adverb", 
                            color: "6A4C93", 
                            appearence: response.filter((d) => { if (d.wordTag === "ADV") return d; }),
                        }, {
                            label: "Pronouns", 
                            color: "097E8B", 
                            appearence: response.filter((d) => { if (d.wordTag === "PROPN") return d; }),
                        }, {
                            label: "Conjunctions", 
                            color: "FF9E1D", 
                            appearence: response.filter((d) => { if (d.wordTag === "CCONJ") return d; }),
                        }, {
                            label: "Date", 
                            color: "1b9d2c", 
                            appearence: response.filter((d) => { if (d.wordTag === "DATE") return d; }),
                        }, {
                            label: "Cardinal", 
                            color: "425cfb", 
                            appearence: response.filter((d) => { if (d.wordTag === "CARDINAL") return d; }),
                        }, {
                            label: "Punctuation", 
                            color: "050505", 
                            appearence: response.filter((d) => { if (d.wordTag === "PUNCT") return d; }),
                        }
                    ];

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            tagReport: tagReportData,
                        },
                    });
                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        }, 1000);
    }

    let tagReport = [];
    if (report?.data) {
        tagReport = report.data.tagReport || [];
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Tag Report</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/label-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadTagReport} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer mt-0">
                    {!loader && tagReport.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {loader && tagReport.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    {tagReport && tagReport.map((v, i) => (
                        <div className={`card fs-12px cursor-pointer text-start card-adv card-${v.color} custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.label)}`} key={i} >
                            <div className="card-body py-2">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.label)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.label}</div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.appearence?.length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}