import _ from "lodash";

export default function CardAnalysisProgressBar({ text, value, validator }) {
    const calculateDegLeft = (value) => {
        if (value > 0) {
            if (value <= 50) {
                return 0;
            } else {
                return ((value - 50) / 100) * 360;
            }
        }
    }

    const calculateDegRight = (value) => {
        if (value > 0) {
            if (value <= 50) {
                return value;
            } else {
                return 180;
            }
        }
    }

    return (
        <div className={`card fs-12px text-start custom-card1 mb-3 p-0 reportcard-1`}>
            <div className="card-body">
                <div className="d-flex">
                    <div className="fs-14px mt-3 text-capitalize fw-normal">{text}</div>
                    <div className="ms-auto p-2 rounded">
                        <div className="circular_progressbar progress mx-auto" data-value={value}>
                            <span className="progress-left">
                                <span className={`progress-bar ${value < validator ? 'border-success' : 'border-danger' }`} style={{ transform: `rotate(${calculateDegLeft(value)}deg)` }}></span>
                            </span>
                            <span className="progress-right">
                                <span className={`progress-bar ${value < validator ? 'border-success' : 'border-danger' }`} style={{ transform: `rotate(${calculateDegRight(value)}deg)` }}></span>
                            </span>
                            <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                                <div className="h6 font-weight-bold mb-0">
                                    {value}<span className="small">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
