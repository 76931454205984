import { useState } from "react";
import { useEffect } from "react"
import NLPService from "../../services/nlpService";
import { checkDifference, createAnalytics, triggerHumanizerAPI } from "../../services/reportServices";

import Quill from "../../utils/Quill";

export default function HumanizerMode ({  }) {

    const [quill1, setQuill1] = useState(null);
    const [inputLength, setInputLength] = useState(0);
    const [output, setOutput] = useState('');
    const [loader1, setLoader1] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [copied, setCopied] = useState(false);
    const [type, setType] = useState('Standard');

    const sampleText = 'In the rapidly evolving landscape of artificial intelligence, one of the most fascinating innovations is the development of tools that can transform AI-generated content into something that feels profoundly human. Enter the Humanizer—an innovative technology designed to refine and enhance AI text, making it not only more relatable but also imbued with the richness of human expression and emotion.';

    useEffect(() => {
        let quill1 = new Quill("#input_editor", {
            theme: "bubble",
            placeholder: 'Your content',
            scrollingContainer: ".ql-editor",
            modules: {
                toolbar: false
            },
        });

        quill1.on(Quill.events.EDITOR_CHANGE, (eventType, range, delta, source) => {
            setInputLength(quill1.getLength());
        })

        quill1.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            delta.ops = delta.ops.map(op => {
                return {
                insert: op.insert
                }
            })
            return delta
        })

        setQuill1(quill1)
    }, [])

    const humanizeText = (e, loaderFunc) =>  {
        e.preventDefault();
        setErrMsg(null);
        setCopied(false);
        e.target.disabled = true;
        loaderFunc(true)
        const text = quill1.getText();

        if (!type) {
            setErrMsg({ type: "warning", title: "Input Missing", message: "Please provide selection for Humanizer Type." });
            loaderFunc(false)
            e.target.disabled = false;
            return;
        }

        if (text.length < 100) {
            setErrMsg({ type: "warning", title: "Input Missing", message: "Please provide content you wish to provide as an input." });
            loaderFunc(false)
            e.target.disabled = false;
            return;
        }

        triggerHumanizerAPI(text, type)
        .then((response) => {
            if (response.data.status == 'Success') {
                setOutput(response.data.data);
                createAnalytics({ reportName: "humanizer-acc", charCount: 1, executionTime: 1000, book_id: 0, input: text, output: response.data.data }).catch((error) => { console.log(error) });
                checkDifference(text, response.data.data)
                .then((diff) => {
                    let initial = 0;
                    let content = '';
        
                    const showDeleted = false;
        
                    diff.data.data.map((d) => {
                        if (d.s != 'removed' || showDeleted) {
                            if (initial == 0) {
                                content += d.str.trim();
                            } else {
                                content += d.str;
                            }
            
                            initial += 1;
                        }
                    })
        
                    
                    const nlpService = new NLPService("");
                    nlpService.setContent(content);
                    let sentences = nlpService.getSentaces();
        
                    let index = 0;
                    let sentenceIndex = 0;
                    let sentenceStart = sentences[sentenceIndex].stringEndIndex;
        
                    let blotHtml = `<customtag id="sentence-${sentenceStart}" class="sentence">`;
        
                    diff.data.data.map((d, i) => {
                        let chunk = d.str;
                        if (index == 0) chunk = chunk.trim()
        
                        let arr = chunk.split(' ');
        
                        let html = '';
        
                        if (d.s !== 'removed' || showDeleted) {
                            arr.map((str) => {
                                if (str.length > 0) {
                                    let srtArr = str.split("\n")
                                    if (srtArr.length > 1) {
                                        srtArr.map((words) => {
                                            if (words.length > 0) {
                                                index += words.length;
                                                html += `<span id="word-${index}" class="word-${d.s}">${words}</span>`;
                                            } else {
                                                index += 1;
                                                sentenceIndex += 1;
                                                if (sentenceIndex < sentences.length) {
                                                    sentenceStart = sentences[sentenceIndex].sentenceStartIndex + sentences[sentenceIndex].stringEndIndex;
                                                }
                                                html += `<span class="word-${d.s}"> </span>`;
                                                html += `</customtag><p></br></p><customtag id="sentence-${sentenceStart}" class="sentence">`;
                                            }
                                        })
                                    } else {
                                        if (index > sentenceStart) {
                                            sentenceIndex += 1;
                                            if (sentenceIndex < sentences.length) {
                                                sentenceStart = sentences[sentenceIndex].sentenceStartIndex + sentences[sentenceIndex].stringEndIndex;
                                            }
                                            html += `</customtag><customtag id="sentence-${sentenceStart}" class="sentence">`;
                                        }
                                        index += str.length;
                                        html += `<span id="word-${index}" class="word-${d.s}">${str}</span>`;
                                    }
                                } else {
                                    index += 1;
                                    html += `<span class="word-${d.s}"> </span>`;
                                }
                            })
                        }
        
                        blotHtml += html;
                    })
        
                    blotHtml += `</customtag>`;
        
                    document.querySelector("#dummy-editor").innerHTML = blotHtml;
                    loaderFunc(false)
                    e.target.disabled = false;

                }).catch((error) => {
                    document.querySelector("#dummy-editor").innerHTML = response.data.data;
                    loaderFunc(false)
                    e.target.disabled = false;
                })
            } else {
                loaderFunc(false)
                e.target.disabled = false;
                setErrMsg({ type: "warning", title: "Network Error!", message: "Unable to generate humanize content. Please try again later." });
            }
        })
        .catch((error) => {
            loaderFunc(false)
            e.target.disabled = false;
            setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
        })
    }

    const trySampleText = (e) => {
        e.preventDefault();
        quill1.setText(sampleText);
    }

    const clearOutput = (e) => {
        e.preventDefault();
        setOutput('');
        document.querySelector("#dummy-editor").innerHTML = '';
    }

    const copyText = (e, output) => {
        setCopied(true);
        navigator.clipboard.writeText(output);
    }

    const handleTypeSelection = (value) => {
        setType(value);
    }

    return (
        <>
            {errMsg && 
                <div className="col-12 mb-3">
                    <div className="bg-danger rounded fw-medium text-white fs-12px px-3 py-3 row">
                        <div className="col-9">
                            <p className="mb-0"><span className="fw-semibold">{errMsg.title}: </span>{errMsg.message}</p>
                        </div>
                        <div className="col-3 my-auto text-end">
                            <span onClick={(e) => setErrMsg(null)} className="text-end fw-semibold cursor-pointer bg-white text-danger py-1 px-2 rounded"><span className="mdi mdi-close me-1"></span> Close</span>
                        </div>
                    </div>
                </div>
            }
            <div className="col-12 mb-4">
                <div className="row">
                    <div onClick={(e) => handleTypeSelection('Standard')} className={`col-4 col-lg-2 mb-2`}>
                        <div className={`humanize_type_list ${type == "Standard" ? 'active' : ''}`}>Standard</div>
                    </div>
                    <div onClick={(e) => handleTypeSelection('Academic')} className={`col-4 col-lg-2 mb-2`}>
                        <div className={`humanize_type_list ${type == "Academic" ? 'active' : ''}`}>Academic</div>
                    </div>
                    <div onClick={(e) => handleTypeSelection('Simple')} className={`col-4 col-lg-2 mb-2`}>
                        <div className={`humanize_type_list ${type == "Simple" ? 'active' : ''}`}>Simple</div>
                    </div>
                    <div onClick={(e) => handleTypeSelection('Flowing')} className={`col-4 col-lg-2 mb-2`}>
                        <div className={`humanize_type_list ${type == "Flowing" ? 'active' : ''}`}>Flowing</div>
                    </div>
                    <div onClick={(e) => handleTypeSelection('Formal')} className={`col-4 col-lg-2 mb-2`}>
                        <div className={`humanize_type_list ${type == "Formal" ? 'active' : ''}`}>Formal</div>
                    </div>
                    <div onClick={(e) => handleTypeSelection('Informal')} className={`col-4 col-lg-2 mb-2`}>
                        <div className={`humanize_type_list ${type == "Informal" ? 'active' : ''}`}>Informal</div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 px-0 mb-2">
                <div className="card border-end-0 rounded-start rounded-0">
                    <div className="card-body p-0">
                        <div style={{ minHeight: '60vh', height: '60vh' }} id="input_editor" className="input-editor bg-white rounded" data-gramm="false" translate="no" spellCheck="false"></div>
                    </div>
                    <div className="card-footer py-3 border-0 bg-white">
                        <div className="d-inline-block w-50">
                            <button onClick={(e) => trySampleText(e)} className="btn theme-btn10 mb-2 fs-12px me-3">
                                <span className="me-2">Try sample text</span>
                                <span className="mdi mdi-text-box-plus-outline"></span>
                            </button>
                            <button onClick={(e) => humanizeText(e, setLoader)} className="btn theme-btn10 mb-2 fs-12px me-3">
                                <span className="me-2">Humanize text</span>
                                {loader && 
                                    <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                                }
                                {!loader && 
                                    <span className="mdi mdi-reload"></span>
                                }
                            </button>
                        </div>
                        <div className="d-inline-block w-50 text-end">
                            <span className="fs-10px fw-medium lh-base px-2 py-1 rounded text-uppercase fw-light" style={{letterSpacing:'1px'}}>Characters: <span className="fw-semibold fs-10px">{inputLength > 0 ? inputLength - 1 : inputLength}</span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 px-0 mb-2">
                <div className="card rounded-0 rounded-end">
                    <div className="card-body p-0">
                        <div style={{ minHeight: '60vh', height: '60vh', display: loader ? 'block' : 'none' }} className="p-3">
                            <div className="card__animate mb-2"></div>
                            <div className="card__animate_mini"></div>
                        </div>
                        <div placeholder="Humanize text will come here ..." className="overflow-scroll" data-gramm="false" translate="no" spellCheck="false" style={{ minHeight: '60vh', height: '60vh', display: !loader ? 'block' : 'none' }} id="dummy-editor"></div>
                    </div>
                    <div className="card-footer py-3 border-0 bg-white text-start">
                        <div className="d-inline-block w-50 text-start">
                            <button onClick={(e) => humanizeText(e, setLoader1)} className="btn theme-btn10 mb-2 fs-12px me-3">
                                <span className="me-2">Regenerate</span>
                                {loader1 && 
                                    <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                                }
                                {!loader1 && 
                                    <span className="mdi mdi-reload"></span>
                                }
                            </button>
                            <button onClick={(e) => clearOutput(e)} className="btn theme-btn10 mb-2 fs-12px me-3">
                                <span className="me-2">Reset</span>
                                <span className="mdi mdi-delete-outline"></span>
                            </button>
                            <button onClick={(e) => copyText(e, output)} className="btn theme-btn10 mb-2 fs-12px">
                                <span className="me-2">Copy to clipboard</span>
                                {copied && <span className="mdi mdi-check"></span>}
                                {!copied && <span className="mdi mdi-content-copy"></span>}
                            </button>
                        </div>
                        <div className="d-inline-block w-50 text-end">
                            <span className="fs-10px fw-medium lh-base px-2 py-1 rounded text-uppercase fw-light" style={{letterSpacing:'1px'}}>Characters: <span className="fw-semibold fs-10px">{output.length > 0 ? output.length - 1 : output.length}</span></span>
                        </div>
                    </div>
                </div>
            </div>


            {/* <button className="btn theme-btn px-3 py-2 fs-14px me-3 mt-3 mb-2">
                <span className="me-2">Humanize</span>
                {loader && 
                    <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                }
                {!loader && 
                    <span className="mdi mdi-reload"></span>
                }
            </button>
            <button className="btn theme-btn7 px-3 py-2 fs-14px me-3 mt-3 mb-2">
                <span className="me-2">Try sample text</span>
                {loader && 
                    <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                }
                {!loader && 
                    <span className="mdi mdi-text-box-plus-outline"></span>
                }
            </button> */}
            {/* <div className="col-12 my-2">
                <p className="fw-medium">Your output content</p>
                <div className="card">
                <div style={{ minHeight: '30vh', height: '30vh' }} id="dummy-editor" className="output-editor bg-white rounded" data-gramm="false" translate="no" spellCheck="false"></div>
                </div>
            </div> */}
            {/* <div className="col-lg-3 my-auto">
                <button className="btn theme-btn6 px-3 py-2 fs-14px me-3 my-2">
                    <span className="me-2">Copy to clipboard</span>
                    {loader && 
                        <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                    }
                    {!loader && 
                        <span className="mdi mdi-content-copy"></span>
                    }
                </button>
                <button className="btn theme-btn6 px-3 py-2 fs-14px me-3 my-2">
                    <span className="me-2">Copy to clipboard</span>
                    {loader && 
                        <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                    }
                    {!loader && 
                        <span className="mdi mdi-content-copy"></span>
                    }
                </button>
            </div> */}
        </>
    )
}