import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { SSE } from "sse.js";

import NLPService from "../../services/nlpService";
import { getData } from "../../services/dataService";
import { checkModelTokenAvailability, checkReportPath } from "../../services/openAIService";
import { quillContentInsertBlotByContentIndex, sanitizeResetQuillAndGetContent, sanitizeResetQuillAndGetContentByRange } from "../../services/highlightContent";
import { getAddedTokens, getReportUsage, getTokenCount, updateTokenUsages } from "../../services/reportServices";

import Card6 from "./Cards/Card6";
import Button1 from "./Buttons/Button1";
import { converter, markdown_converter } from "../../services/deltaToMarkdown";

export default function AIResultPopupOld({ userData, quill, start, end, doc, aiReportSettings, setAvailableTokens, showAIResultPopup, setShowAIResultPopup, aiResultOutput, setAiResultOutput, selectedLang, setSelectedLang, noWords, setNoWords, errMsg, setErrMsg, bookDetailsModel, bookDetailsCreativity, setTokenPurchaseErr, setCheckPage }) {

    const [rpLoader, setRpLoader] = useState(false);
    const [highlightIndex, setHighlightIndex] = useState([]);
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionLength, setSelectionLength] = useState(0);
    const [reportOption, setReportOption] = useState('');
    const [reportPrompt, setReportPrompt] = useState('');
    const [helpURI, setHelpURI] = useState('');
    const [modelName, setModelName] = useState(bookDetailsModel);
    const [styleAttr, setStyleAttr] = useState({})

    useEffect(() => {
        setTimeout(() => {
            setCheckPage('')
            let range = quill.getSelection(start, end, true);

            if (!range) {
                range = quill.getSelection(true);
            }

            let bounds = quill.getBounds(range);
    
            setStyleAttr({ top: bounds.top + bounds.height + 120 + "px", display: 'block', width: '60%', minWidth: '300px', left: '2%' });

            document.querySelector("html").scrollTop = bounds.top + bounds.height - 100;

            if (userData.user.user_mode == 'Student') {
                setModelName('openai/gpt-4-turbo');
            } else {
                setModelName(bookDetailsModel);
            }
            
            setErrMsg({});
            setAiResultOutput([]);

            switch (aiReportSettings.report) {
                case 'Paraphrase':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/paraphrasing-using-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/paraphrasing-selection-tools')
                    }
                    break;
                case 'AI Edit':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/ai-edit-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/ai-edit-selection-tools')
                    }
                    break;
                case 'Perspective':
                    setReportOption(aiReportSettings.report_sub);
                    setHelpURI('https://manuscripts.ai/first-person-perspective-selection-tools')
                    break;
                case 'AI Rewrite':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/rewrite-story-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/rewrite-story-selection-tools')
                    }
                    break;
                case 'Expand Content with AI':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/expand-content-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/expand-story-selection-tools')
                    }
                    break;
                case 'Complete Story with AI':
                    setReportOption(aiReportSettings.report_sub);
                    setHelpURI('https://manuscripts.ai/complete-story-selection-tools')
                    break;
                case 'Opposing Arguments':
                    setReportOption(aiReportSettings.report_sub);
                    setHelpURI('https://manuscripts.ai/opposing-arguments-selection-tools/')
                    break;
                case 'Content Improver':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/content-improver-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/content-improver-selection-tools')
                    }
                    break;
                case 'Summary Generator':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/summary-generator-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/summary-generator-selection-tools')
                    }
                    break;
                case 'Voice Update':
                    setReportOption(aiReportSettings.report_sub);
                    setHelpURI('https://manuscripts.ai/paraphrasing-selection-tools')
                    break;
                case 'Improve Structure':
                    setReportOption(aiReportSettings.report_sub);
                    if (userData.user.user_mode == 'Student') {
                        setHelpURI('https://manuscripts.ai/improve-structure-using-selection-tools-students/')
                    } else {
                        setHelpURI('https://manuscripts.ai/improve-structure-selection-tools')
                    }
                    break;
                case 'Translate':
                    setReportOption(aiReportSettings.report_sub);
                    setHelpURI('https://manuscripts.ai/translate-selection-tools-students/')
                    break;
                default:
                    break;
            }
    
            if (start >= 0 && end > 0 && quill) {
                setSelectionStart(start);
                setSelectionLength(end);
                sanitizeResetQuillAndGetContentByRange(quill, start, end).then((content) => {
    
                    let str = '';
                    if (content.length > 100) {
                        str = '...' + content.substring(content.length - 100, content.length)
                    } else {
                        str = '...' + content.substring(0, content.length)
                    }
    
                    // loadParaphrasing(start, end, aiReportSettings.report_sub);
                }).catch((error) => console.log(error))
            } else {
                setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, You need to select the content using mouse selection or keyboard selection to proceed with the report!" });
                setSelectionStart(0);
                setSelectionLength(0);
            }
        }, 0);

    }, [aiReportSettings, bookDetailsModel])

    const handleSseParaphrasing = async (
        sentences = [],
        start,
        length,
        type,
        quillContent,
        usageLimit,
        totalLimit, 
        modelLimit
    ) => {
        try {
            if (!sentences.length) {
                setRpLoader(false);
                setErrMsg({
                    type: "warning",
                    title: "Missing selection!",
                    message: "You have not provided any selection for this report, Please select content and refresh the report!"
                });
                return;
            }
  
            let paraphraseOutput = [];
            let paraphraseData = [];
            let nth = 0;

            const uriPath = await checkReportPath(aiReportSettings.report);
            const urlWithData = new URL(uriPath);
            let input_data = {
                AuthToken: `${getData("token_type")} ${getData("token")}`,
                type: aiReportSettings.report_sub,
                n: 1,
                temperature: bookDetailsCreativity,
                user_mode: userData.user.user_mode,
                maxToken: modelLimit,
                genre_type: doc.genre_type,
                genre: userData.user.genre,
                prompt: reportPrompt,
                content: quillContent,
                model_name: modelName,
                language: selectedLang
            }
  
            const source = new SSE(urlWithData, {
                headers: {
                    "Content-Type": "application/json"
                },
                payload: JSON.stringify(input_data),
            });
  
            source.onreadystatechange = async function(event) {
                if (event.readyState === source.OPEN) {
                    setRpLoader(true);
                }
  
                if (event.readyState === source.CLOSED) {
                    setRpLoader(false);
                    source.close();
                    paraphraseData[nth] = {
                        start: start,
                        end: start + length,
                        input: quillContent,
                        type: aiReportSettings.report_sub,
                        output: paraphraseOutput[nth],
                    };

                    setHighlightIndex({
                        'start': start,
                        'length': length
                    });

                    let all_output = '', total_used_limit = 0;
  
                    for (const data of paraphraseData) {
                        if (data.output && data.output != undefined) {
                            all_output += data.output + "";
                            let charCount = await updateTokenUsages("paraphrasing-report-v1", doc.id, "Paraphrasing " + type + ": " + data.input, data.output);
                            charCount = charCount ? charCount : 0;
                            usageLimit = totalLimit - (Number(charCount) + Number(usageLimit)) > 0 ? totalLimit - (Number(charCount) + Number(usageLimit)) : 0;
                            total_used_limit += charCount;
                            setAvailableTokens(usageLimit);
                        }
                    }
  
                    if (all_output.length < 3) {
                        setErrMsg({
                            type: "warning",
                            title: "Content Processed",
                            message: "Could not generate content for the given selection or Looks like it is already valid content, Please try with some different Content."
                        });
                    }
                }
            };
  
            source.onmessage = async function(event) {
                const data = JSON.parse(event.data);
                if (data?.content) {
                    nth = data?.nth;
                    paraphraseOutput[nth] = (paraphraseOutput[nth] || "") + data?.content;
  
                    paraphraseData[nth] = {
                        start: start,
                        end: start + length,
                        input: quillContent,
                        type: type,
                        output: paraphraseOutput[nth],
                    };

                    await setAiResultOutput(paraphraseOutput);

                    if (data?.close == 'True') {
                        source.close();
                    }
                }
            };
  
            source.onerror = function(event) {
                source.close();
                setErrMsg({
                    type: "warning",
                    title: "Network Error!",
                    message: "Something went wrong, Please check the details provided for the report query are valid and try again."
                });
                setRpLoader(false);
            };
        } catch (error) {
            setRpLoader(false);
            setErrMsg({
                type: "warning",
                title: "Network Error!",
                message: "Something went wrong, This could be due to  API downtime. Please try again later."
            });
        }
    };

    const loadParaphrasing = async (start, length, type) => {
        setErrMsg({});
        setTokenPurchaseErr({})
        setRpLoader(true);
        setTimeout(async () => {
            try {
                let usage = await getReportUsage();
                if (usage[0].data.result.statusCode == 200) {
                    var usageLimit = usage[0].data.result.result;
                    if (userData.packages[0] && userData.user) {
                        if (userData.packages[0].amount <= 100) usageLimit = usage[0].data.result.usage_count_day;
                        let quillContent = await sanitizeResetQuillAndGetContentByRange(quill, start, length);
                        console.log(start, length);
                        const quillDelta = quill.getContents(start, length);
                        quillContent = await converter(quillDelta);

                        if (quillContent.length > 4) {
                            let input_token_data = await getTokenCount(quillContent);
                            let input_token_count = input_token_data.data.result.length;

                            let modelLimit = await checkModelTokenAvailability(modelName);

                            if (input_token_count > modelLimit) {
                                setRpLoader(false);
                                setErrMsg({
                                    type: "warning",
                                    title: "Model Limit Exceed!",
                                    message: "The model you have selected has limitations of "+Number(modelLimit * 0.75)+" words, Please select models with the greater capacity or reduce your selection."
                                });
                                return;
                            }

                            if (input_token_count > 8000) {
                                setRpLoader(false);
                                setErrMsg({
                                    type: "warning",
                                    title: "Limit exceed!",
                                    message: "Character limit exceeded. You can not select more than 8000 characters."
                                });
                            } else {
                                let nlpService = new NLPService("");
                                nlpService.setContent(quillContent);
                                let data = nlpService.getSentaces();
  
                                let addOnTokens = await getAddedTokens(userData.user.id);
                                addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                                let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

                                let today = moment().format("YYYY-MM-DD H:mm:ss");
                                const date_limit = moment(userData.packages[0].package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");

                                if (date_limit < today && parseInt(userData.packages[0].amount) <= 100) {
                                    setRpLoader(false);
                                    setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                                } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                                    setRpLoader(false);
                                    setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit tomorrow again or once your subscription is renewed or you can also purchase additional tokens!" });
                                } else if (usageLimit < totalLimit) {
                                    if (Number(input_token_count) + Number(usageLimit) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                                        await handleSseParaphrasing(data, start, length, type, quillContent, usageLimit, totalLimit, modelLimit);
                                    } else {
                                        setRpLoader(false);
                                        setTokenPurchaseErr({
                                            type: "warning",
                                            title: "Limit exceed!",
                                            message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                                        });
                                    }
                                } else {
                                    setRpLoader(false);
                                    setTokenPurchaseErr({
                                        type: "warning",
                                        title: "Limit exceed!",
                                        message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
                                    });
                                }
                            }
                        } else {
                            setRpLoader(false);
                            setErrMsg({
                                type: "warning",
                                title: "Missing selection!",
                                message: "You have not provided any selection for this report, Please select content and refresh the report!"
                            });
                        }
  
                    } else {
                        setRpLoader(false);
                        setErrMsg({
                            type: "warning",
                            title: "Session missing!",
                            message: "You can login and try this report again!"
                        });
                    }
                } else {
                    setRpLoader(false);
                    setErrMsg({
                        type: "warning",
                        title: "Permission denied!",
                        message: "Paraphrasing service is not available for this account."
                    });
                }
            } catch (error) {
                setRpLoader(false);
                setErrMsg({
                    type: "warning",
                    title: "Network Error!",
                    message: "Something went wrong, Please try again later."
                });
            }
        }, 1000);
    };

    const checkData = async (e) => {
        e.preventDefault();
        setErrMsg({});
        await loadParaphrasing(start, end, reportOption);
    }

    let handleTextParaphrasing = async (replaceString, className) => {
        // await highlightTextParaphrasing(quill, replaceString, highlightIndex, setHighlightIndex, className, selectionStart, selectionLength);
        let ops = await markdown_converter(replaceString);
        let updateOps = [{ retain: highlightIndex.start }, { delete: highlightIndex.length }]

        let startIndex = highlightIndex.start;

        ops.map((op) => {
            if (op?.attributes) {
                updateOps.push({ insert: op?.insert, attributes: op?.attributes })
            } else {
                updateOps.push({ insert: op?.insert })
            }
            startIndex += op.insert.length;
        })

        await quill.updateContents(updateOps, 'user')

        setAiResultOutput([]);
        setShowAIResultPopup(false);
        quill.setSelection(startIndex);
        setErrMsg({ errMsg: null });
        await sanitizeResetQuillAndGetContent(quill);
    };

    let handleTextRewrite = async (replaceString, className) => {
        let ops = await markdown_converter(replaceString);

        let startIndex = highlightIndex.start + highlightIndex.length;
        let updateOps = [{ retain: startIndex }]

        ops.map((op) => {
            if (op?.attributes) {
                updateOps.push({ insert: op?.insert, attributes: op?.attributes })
            } else {
                updateOps.push({ insert: op?.insert })
            }
            startIndex += op.insert.length;
        })

        await quill.updateContents(updateOps, 'user')

        setAiResultOutput([]);
        setShowAIResultPopup(false);
        quill.setSelection(startIndex);
        setErrMsg({ errMsg: null });
        await sanitizeResetQuillAndGetContent(quill);
    };

    let paraPhrasingReport = [];

    if (aiResultOutput.length != 0) {
        paraPhrasingReport = aiResultOutput || [];
    }

    return (
        <div style={styleAttr} id="ai_result_popup" className="position-absolute border-1 rounded">
            <div className="result_cover px-3 py-3 shadow-sm">
                <div className="result_card_cover" style={{ height: 'auto' }}>
                    <div className="form-floating">
                        <input autoFocus={true} type="text" value={reportPrompt} onChange={(e) => setReportPrompt(e.target.value)} className="form-control fs-12px" placeholder={`Provide your custom requirement here`} />
                        <label className="fs-12px">Provide your custom prompt</label>
                    </div>
                    <div className="text-start reportContainer">
                        {rpLoader && paraPhrasingReport.length == 0 && 
                            <div className={`reportcard-1 shadow-sm`}>
                                <Card6 title={''} description={'Manuscripts.ai is typing ...'} index={0}></Card6>
                            </div>
                        }
                        {paraPhrasingReport.map((v, i) => (
                            <div key={i} className={`reportcard-1 shadow-sm paraphrase-${_.kebabCase(v)}`}>
                                <input style={{ visibility: 'hidden' }} className="form-check-input" name="update_content" id={`paraphrase-${_.kebabCase(v)}-${i}-cb`} type="checkbox" />
                                {paraPhrasingReport && paraPhrasingReport.length > 0 && 
                                    <Card6 title={''} description={v} index={i}></Card6>
                                }
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-12 text-start mt-3">
                            {paraPhrasingReport.length == 0 && 
                            <>
                                <button onClick={(e) => checkData(e)} className="btn theme-btn7 py-1 fs-12px ms-2 me-1 me-3" disabled={rpLoader ? true : false}>{rpLoader && <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>} Generate Content</button>
                                <button onClick={(e) => { sanitizeResetQuillAndGetContent(quill); setAiResultOutput([]); setShowAIResultPopup(false); }} className="btn theme-btn2 py-1 px-2 fs-12px my-1 me-3"><span className="mdi mdi-close me-2"></span>Close</button>
                            </>
                            }
                            {paraPhrasingReport && paraPhrasingReport.length > 0 && 
                                <>
                                    {aiReportSettings.report != 'Expand Content with AI' && aiReportSettings.report != 'Complete Story with AI' && aiReportSettings.report != 'Summary Generator' && aiReportSettings.report != 'Opposing Arguments' && (
                                        <>
                                            <Button1 disabled={rpLoader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextParaphrasing(`${paraPhrasingReport[0]}`, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                                <span className="mdi mdi-check me-2"></span>Replace
                                            </Button1>
                                            <Button1 disabled={rpLoader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextRewrite(`${paraPhrasingReport[0]}`, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                                <span className="mdi mdi-check me-2"></span>Insert Below
                                            </Button1>
                                        </>

                                    )}
                                    {(aiReportSettings.report == 'Expand Content with AI' || aiReportSettings.report == 'Complete Story with AI' || aiReportSettings.report == 'Summary Generator' || aiReportSettings.report == 'Opposing Arguments') && (
                                        <Button1  disabled={rpLoader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextRewrite(`${paraPhrasingReport[0]}`, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                            <span className="mdi mdi-check me-2"></span>Insert Below
                                        </Button1>
                                    )}
                                    <button onClick={(e) => checkData(e)} className="btn theme-btn4 px-2 fs-12px m-1" disabled={rpLoader ? true : false}> {rpLoader ? <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : <span className="mdi mdi-refresh me-2"></span> } Regenerate</button>
                                    <button onClick={(e) => { sanitizeResetQuillAndGetContent(quill); setAiResultOutput([]); setShowAIResultPopup(false); }} className="btn theme-btn2 py-1 px-2 fs-12px m-2"><span className="mdi mdi-close me-2"></span>Reject & Close</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}