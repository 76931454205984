import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import _ from "lodash";

import Card2 from "../common/Cards/Card2";

import { getAdverbsData } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex } from "../../services/highlightContent";
import { generateNotification } from "../../services/notificationService";
import Quill from "../../utils/Quill";

export default function Adverb() {
  const [
    docId,
    report,
    setReport,
    quill,
    setQuill,
    userData,
    setUserData,
    propsRouter,
    setAvailableTokens,
    loader,
    handleLoaderOff,
    checkPage,
    setCheckPage,
    setAiResultOutput, 
    setShowAIResultPopup
  ] = useOutletContext();

  const [genre, setGenre] = useState(23);
  const [accSwitch, setAccSwitch] = useState(true);

  useEffect(() => {
    setReport({});
    setCheckPage('adverb');
    setAiResultOutput([]);
    setShowAIResultPopup(false);
    if (quill && quill.getLength() > 3) {
      loadAdverb();
    }
  }, [propsRouter]);

  const addBgClass = (keyWord) => {
    window.$(`#keyword-${keyWord}_cb`).click();

    if (window.$(`#keyword-${keyWord}_cb`).prop("checked")) {
      window.$(`.keyword-${keyWord}, .excess-adverb-${keyWord}, .good-adverb-${keyWord}`).addClass("active");
      let el1 = document.getElementsByClassName(`good-adverb-${keyWord}`);
      let el2 = document.getElementsByClassName(`excess-adverb-${keyWord}`);
      if (el1.length > 0) document.querySelector("html").scrollTop = el1[0].offsetTop;
      else document.querySelector("html").scrollTop = el2[0].offsetTop;
    } else {
      window.$(`.keyword-${keyWord}, .excess-adverb-${keyWord}, .good-adverb-${keyWord}`).removeClass("active");
      window.$(`.keyword-${keyWord}, .excess-adverb-${keyWord}, .good-adverb-${keyWord}`).removeClass("chClass");
    }
  };

  const loadAdverb = async () => {
    handleLoaderOff(true);
    setTimeout(async () => {
      try {
        if (userData.packages[0] && userData.user) {
          let quillContent = await sanitizeResetQuillAndGetContent(quill);
          let images = document.querySelectorAll('.ql-editor img');
          for (const image of images) {
            let blot = await Quill.find(image);
            let index = blot.offset(quill.scroll);
            quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
          }
          let result = [];
          let response = await getAdverbsData(quillContent, userData.user, genre);

          let adverbs = response[0].data.data;
          let excessAdverbs = response[1].data.msg;
          adverbs = _.map(_.groupBy(adverbs, "word"), (dataArr, word) => {
            let wordArr1 = [];
            excessAdverbs.map((adverb) => {
              if (_.lowerCase(adverb.reason).includes(word))
                dataArr.map((d) => {
                  if (adverb.index == d.stringStartIndex) {
                    wordArr1.push(d);
                    result.push(d);
                  }
                });
            });

            return {
              word: word,
              dataArr: dataArr,
              wordsCount: dataArr.length,
              wordArr: wordArr1,
              wordArrCount: wordArr1.length,
            };
          });

          let correct = [];
          adverbs.map((adverb) => { if (adverb.wordArr.length == 0) correct.push(...adverb.dataArr) });

          quillContentInsertBlotByContentIndex(quill, 0, result, "stringStartIndex", "stringEndIndex",
            [{
                blotName: "span",
                id: `excess-adverb-{index}`,
                classes: [`excess-adverbs`, `excess-adverb-{word}`],
                classDynamicKeys: ["", { replaceBy: "{word}", replaceToKey: "word" }],
              },
            ]
          );

          quillContentInsertBlotByContentIndex(quill, 0, correct, "stringStartIndex", "stringEndIndex",
            [{
                blotName: "span",
                id: `good-adverb-{index}`,
                classes: [`good-adverbs`, `good-adverb-{word}`],
                classDynamicKeys: ["", { replaceBy: "{word}", replaceToKey: "word" }],
              },
            ]
          );

          adverbs = _.orderBy(adverbs, ["wordsCount"], ["desc"]);

          setReport({
            lastReportIndexes: { start: 0, end: quill.getLength() },
            data: { keywordsData: adverbs },
          });

          handleLoaderOff(false);
        } else {
          handleLoaderOff(false);
          generateNotification({
            title: "Account Expired!",
            text: "Please check your account for the payment, This report is currently not available.",
            icon: "error",
          });
        }
      } catch (error) {
        handleLoaderOff(false);
        generateNotification({
          title: "Network Error!",
          text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
          icon: "error",
        });
      }
    });
  };

  let keywordsData = [];
  if (report?.data) {
    keywordsData = report.data.keywordsData || [];
  }

  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
          <span>Adverb</span>
          <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/adverbs-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
          <span className="ms-auto">
            <button onClick={loadAdverb} className="btn theme-btn4 fs-12px">Refresh</button>
          </span>
        </div>
      </div>
      <div className="result_card_cover text-start">
        {keywordsData && (
          <div>
            <div className="card bg-light border-0 mb-3">
              <div className="accordion" id="descAcc">
                <div className="adverb-desc accordion-item">
                  <p className="accordion-header" id="desc1" onClick={()=> setAccSwitch(!accSwitch)}>
                    <button className="accordion-button py-2 fs-14px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Keywords Indexes</button>
                  </p>
                  <div id="collapseOne" className={accSwitch ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="desc1" data-bs-parent="#descAcc">
                    <div className="accordion-body">
                      <span className="index_bg1">All</span>
                      <span className="index_bg2">Irrelevant</span>
                      <span className="index_bg3">Relevant</span>
                      <p className="my-2">
                        <span className="fs-12px"><span className="fw-semibold">All:</span> All adverbs used in your manuscript</span><br />
                        <span className="fs-12px"><span className="fw-semibold">Irrelevant:</span> Adverbs which can be replaced in a sentence or can weaken a meaning of sentence.</span><br />
                        <span className="fs-12px"><span className="fw-semibold">Relevant:</span> Adverbs which are used properly in a sentence.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reportContainer mt-0">
              {!loader && keywordsData.length == 0 && 
                <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
              }
              {loader && keywordsData.length == 0 && 
                <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
              }
              {keywordsData.map((v, i) => (
                <div className={`card fs-12px text-start card-adv cursor-pointer custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => addBgClass(`${_.kebabCase(v.word)}`)} key={i}>
                  <div className="card-body">
                    <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}_cb`} type="checkbox" />
                    <div className="d-flex align-items-center">
                      <div>{v.word}<br /><small>({v.wordArrCount} issues - {" "} {parseInt((v.wordArrCount * 100) / v.wordsCount)}%)</small></div>
                      <div className="ms-auto border p-2 rounded">
                        <span className="count">{v.wordsCount}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
