import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import _ from "lodash";
import { getAnalyticsData, getConsolidatedReportsData, getParagraphVariation } from "../../services/reportServices";
import { sanitizeQuillGetContent } from "../../services/highlightContent";
import Card2 from "../common/Cards/Card2";
import CardAnalysis from "../common/CardAnalysis";
import CardAnalysisProgressBarRange from "../common/CardAnalysisProgressBarRange";
import CardAnalysisProgressBar from "../common/CardAnalysisProgressBar";
import CardAnalysisProgressBarRange2 from "../common/CardAnalysisProgressBarRange2";

export default function Analysis () {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage,
        setAiResultOutput, 
        setShowAIResultPopup, 
        runGrammarAnalysis, 
    ] = useOutletContext();

    const [errMsg, setErrMsg] = useState({});
    const [rpLoader, setRpLoader] = useState(false);
    const [grammarAnalysis, setGrammarAnalysis] = useState({});
    const [checkGrammar, setCheckGrammar] = useState(false)

    useEffect(() => {
        setReport({});
        setCheckPage('analysis');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadAnalysis();
        }
    }, [propsRouter]);

    const loadAnalysis = async () => {
        handleLoaderOff(true);
        setRpLoader(true);
        setGrammarAnalysis({});

        let quillContent = await sanitizeQuillGetContent(quill);

        let word_count = quillContent.split(" ");
        let averageSentenceCount = 0;

        if (word_count.length >= 1000) {
            let response = await getAnalyticsData(quillContent);
            let consolidatedReports = await getConsolidatedReportsData(quillContent);

            averageSentenceCount = word_count.length / consolidatedReports?.sentenceCount;
            averageSentenceCount = averageSentenceCount ? averageSentenceCount : 0;
    
            let result = {
                'charCount': response.charCount,
                'clarity': response.clarity,
                'gulpeaseIndex': response.gulpeaseIndex,
                'lexionCount': response.lexionCount,
                'readabilityScores': response.readabilityScores,
                'textStandard': response.readabilityScores[0].value,
                'gradeLevel': response.readabilityScores[1].value,
                'fleschKincaidScore': response.readabilityScores[2].value,
                'fleschScore': response.readabilityScores[3].value,
                'gunningFogScore': response.readabilityScores[4].value,
                'colemanLiauScore': response.readabilityScores[5].value,
                'daleChallScore': response.readabilityScores[6].value,
                'sentenceCount': consolidatedReports.sentenceCount,
                'wordCount': response.readabilityScores[12].value,
                'averageSentenceLength': averageSentenceCount.toFixed(2),
                'readingTime': response.readingTime,
                'wordsCount': response.wordsCount,
                'glueIndexScore': consolidatedReports.averageGlueIndex,
                'sentimentAnalysis': consolidatedReports.totalSentiment,
                'longSentencesPer': (consolidatedReports.greaterThen40 * 100 / consolidatedReports.sentenceCount).toFixed(2),
                'ingStarterPer': (consolidatedReports.ingStart.length * 100 / consolidatedReports.sentenceCount).toFixed(2),
                'conjunctionStarterPer': (consolidatedReports.conjunctionStart.length * 100 / consolidatedReports.sentenceCount).toFixed(2),
            }
    
            setReport({
                lastReportIndexes: { start: 0, end: quill.getLength() },
                data: { 'result': result },
            });

            if (!checkGrammar) {
                setCheckGrammar(true);
                runGrammarAnalysis();
            }

            setErrMsg({});
            setRpLoader(false);
            handleLoaderOff(false);
        } else {
            setRpLoader(false);
            handleLoaderOff(false);
            setErrMsg({ type: "warning", title: "Limit Error!", message: "Your content must have atleast 1000 words to run this report!" });
        }
    }

    const calculateTime = (time) => {
        var h = Math.floor(time / 3600);
        var m = Math.floor(time % 3600 / 60);
        var s = Math.floor(time % 3600 % 60);
        var hDisplay = h > 0 ? h + (h == 1 ? " H, " : " Hs, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " M, " : " Ms, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " S" : " Ss") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const sentimentScoreCheck = (score) => {
        if (score > 0) {
            return "Positive";
        } else if (score < 0) {
            return "Negative";
        } else {
            return "Neutral";
        }
    }

    let resultData = [];
    if (report?.data) {
        resultData = report.data.result || [];
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Analysis</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/adverbs-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto"><button onClick={loadAnalysis} className="btn theme-btn4 fs-12px">Refresh</button></span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer mt-0">
                    {resultData.length != 0 && resultData?.accuracy && 
                        <CardAnalysisProgressBarRange2 text={`Accuracy Score`} value={resultData.accuracy} start={60} end={80} percent={''} />
                    }
                    {resultData.length != 0 && resultData?.correctness && 
                        <CardAnalysisProgressBarRange2 text={`Correctness Score`} value={resultData.correctness} start={60} end={80} percent={''} />
                    }
                    {resultData.length != 0 && resultData?.clarity && 
                        <CardAnalysisProgressBarRange2 text={`Clarity`} value={resultData.clarity} start={60} end={80} percent={''} />
                    }
                    {!loader && resultData.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {errMsg.hasOwnProperty("message") && (
                        <Card2 title={errMsg.title} description={errMsg.message}></Card2>
                    )}
                    {rpLoader && !errMsg.hasOwnProperty("message") && (
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    )}
                    {resultData.length != 0 && resultData.sentimentAnalysis && 
                        <CardAnalysis text={'Sentiment Analysis'} value={sentimentScoreCheck(Number(resultData.sentimentAnalysis))} />
                    }
                    {resultData.length != 0 && resultData?.readingTime && 
                        <CardAnalysis text={'Reading Time'} value={calculateTime(resultData.readingTime / 1000)} />
                    }
                    {resultData.length != 0 && resultData?.readingTime && 
                        <CardAnalysis text={'Speaking Time'} value={calculateTime(resultData.readingTime * 2 / 1000)} />
                    }
                    {resultData.length != 0 && resultData.averageSentenceLength && 
                        <CardAnalysisProgressBarRange text={`Average Sentence Length`} value={resultData.averageSentenceLength} start={11} end={18} percent={''} />
                    }
                    {resultData.length != 0 && resultData.textStandard && 
                        <CardAnalysis text={'Text Standard'} value={resultData.textStandard} />
                    }
                    {resultData.length != 0 && resultData.gradeLevel && 
                        <CardAnalysis text={'Grade Level'} value={resultData.gradeLevel} />
                    }
                    {resultData.length != 0 && resultData.fleschKincaidScore && 
                        <CardAnalysis text={'Flesch Kincaid Score'} value={resultData.fleschKincaidScore} />
                    }
                    {resultData.length != 0 && resultData.fleschScore && 
                        <CardAnalysis text={'Flesch Score'} value={resultData.fleschScore} />
                    }
                    {resultData.length != 0 && resultData.gunningFogScore && 
                        <CardAnalysis text={'Gunning Fog Score'} value={resultData.gunningFogScore} />
                    }
                    {resultData.length != 0 && resultData.colemanLiauScore && 
                        <CardAnalysis text={'Coleman Liau Score'} value={resultData.colemanLiauScore} />
                    }
                    {resultData.length != 0 && resultData.daleChallScore && 
                        <CardAnalysis text={'Dale Chall Score'} value={resultData.daleChallScore} />
                    }
                    {resultData.length != 0 && resultData.sentenceCount && 
                        <CardAnalysis text={'Sentence Count'} value={resultData.sentenceCount} />
                    }
                    {resultData.length != 0 && resultData.wordCount && 
                        <CardAnalysis text={'Word Count'} value={resultData.wordCount} />
                    }
                    {resultData.length != 0 && resultData.longSentencesPer && 
                        <CardAnalysisProgressBar text={`Lengthy Sentences`} value={resultData.longSentencesPer} validator={3} />
                    }
                    {resultData.length != 0 && resultData.conjunctionStarterPer && 
                        <CardAnalysisProgressBar text={`Conjunction Starter`} value={resultData.conjunctionStarterPer} validator={30} />
                    }
                    {resultData.length != 0 && resultData.ingStarterPer && 
                        <CardAnalysisProgressBar text={`Ing Starter`} value={resultData.ingStarterPer} validator={2} />
                    }
                    {resultData.length != 0 && resultData.glueIndexScore && 
                        <CardAnalysisProgressBar text={`Average Glue Index`} value={resultData.glueIndexScore} validator={40} />
                    }
                </div>
            </div>
        </div>
    )
}