import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import Spinner from "./../../assets/Spinner-2.gif"
import { verifyAccount } from "../../services/authService";
import { handleHttpError } from "../../services/httpErrorService";
import { generateNotification } from "../../services/notificationService";;

const Verify = (props) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
      email: "",
      verificationCode: "",
      globalMessage: "",
  });

  useEffect(() => {
     const params = new URLSearchParams(window.location.search)
      var userId = params.get('tid');
      var verificationCode = params.get('vid');
      
      userId = userId.replace('MANUSCRIPTSAI', '');
      if (userId != undefined && userId !== "") {
      } else if (verificationCode != undefined && verificationCode !== "") {
          // setVerificationCode(verificationCode);
      } else {
          generateNotification({ title: "Account Verification Failed", text: "You are accessing invalid URL, Please access the link shared through your registered email address.", icon: "error" });
          navigate("/login" );
      }

      if (userId && verificationCode) {
          verifyAccount({ userId, verificationCode }).then((httpResponse) => {
              if (httpResponse.status == 200) {
                  generateNotification({ title: "Account Verified Successfully", text: "Please Proceed with Login.", icon: "success" });
                  setErrors({ email: "", verificationCode: "", globalMessage: "" });
                  setTimeout(() => {
                    navigate("/login");
                  });
              }
          }).catch((httpResponse) => {
              let error = httpResponse.response;
              if (error.status == 400 || error.status == 500) {
                  let { email, globalMessage, verificationCode } = error.data.error || {};
                  setErrors({
                      email: email != undefined ? email : "",
                      verificationCode: verificationCode != undefined ? verificationCode : "",
                      globalMessage: globalMessage != undefined ? globalMessage : "",
                  });

                  var errorStr = email != undefined ? email : "";
                  errorStr = verificationCode != undefined ? verificationCode : "";
                  errorStr = globalMessage != undefined ? globalMessage : "";

                  generateNotification({ title: "Error!", text: errorStr+' You are accessing invalid URL, Please access the link shared through your registered email address.', icon: "error" });
                  navigate("/login");
              }
              handleHttpError(error);
          })
      } else {
          generateNotification({ title: "Account Verification Failed", text: "You are accessing invalid URL, Please access the link shared through your registered email address.", icon: "error" });
          navigate("/login");
      }
  }, []);
  return (
    <div className="loaderContainer2">
      <div id="sandyloader2">
        <img src={Spinner} alt="" />
        <p className="text-center">
          <small>Account Verification Check</small>
        </p>
      </div>
    </div>
  );
};
export default Verify;