export default function Shortcuts({ userData, displayShortcuts }) {
    return (
        <div className="modal show">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Shortcuts</h5>
                        <button onClick={(e) => displayShortcuts(false)} type="button" className="btn-close fs-10px" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item py-3">
                                <div className="card border-0">
                                    <div className="row">
                                        <div className="col-lg-8 my-auto">
                                            <p className="fs-12px fw-medium mb-1 text-start">Continue Writing</p>
                                            <p className="fs-10px fw-regular mb-1 text-start">Explore power of AI and Let AI write for you.</p>
                                        </div>
                                        <div className="col-lg-4 my-auto text-end">
                                            <span className="badge badge-default text-body fw-medium border">CTRL + ENTER</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {userData?.user?.user_mode == 'Student' && 
                                <li className="list-group-item py-3">
                                    <div className="card border-0">
                                        <div className="row">
                                            <div className="col-lg-8 my-auto">
                                                <p className="fs-12px fw-medium mb-1 text-start">Add Citation</p>
                                                <p className="fs-10px fw-regular mb-1 text-start">Search for citation using your topic or content.</p>
                                            </div>
                                            <div className="col-lg-4 my-auto text-end">
                                                <span className="badge badge-default text-body fw-medium border">@</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }
                            <li className="list-group-item py-3">
                                <div className="card border-0">
                                    <div className="row">
                                        <div className="col-lg-8 my-auto">
                                            <p className="fs-12px fw-medium mb-1 text-start">Generate Heading</p>
                                            <p className="fs-10px fw-regular mb-1 text-start">Remove your trouble of kick starting your chapter heading and let AI deliver your heading.</p>
                                        </div>
                                        <div className="col-lg-4 my-auto text-end">
                                            <span className="badge badge-default text-body fw-medium border">CTRL + H</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item py-3">
                                <div className="card border-0">
                                    <div className="row">
                                        <div className="col-lg-8 my-auto">
                                            <p className="fs-12px fw-medium mb-1 text-start">Generate Outline</p>
                                            <p className="fs-10px fw-regular mb-1 text-start">Let AI help you with the outline for your document.</p>
                                        </div>
                                        <div className="col-lg-4 my-auto text-end">
                                            <span className="badge badge-default text-body fw-medium border">CTRL + O</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {userData?.user?.user_mode == 'Student' && 
                                <li className="list-group-item py-3">
                                    <div className="card border-0">
                                        <div className="row">
                                            <div className="col-lg-8 my-auto">
                                                <p className="fs-12px fw-medium mb-1 text-start">AI Commands</p>
                                                <p className="fs-10px fw-regular mb-1 text-start">Get quick access to AI commands which can continue writing your document further.</p>
                                            </div>
                                            <div className="col-lg-4 my-auto text-end">
                                                <span className="badge badge-default text-body fw-medium border">CTRL + /</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }
                            {userData?.user?.user_mode == 'Author' && 
                                <li className="list-group-item py-3">
                                    <div className="card border-0">
                                        <div className="row">
                                            <div className="col-lg-8 my-auto">
                                                <p className="fs-12px fw-medium mb-1 text-start">AI Commands</p>
                                                <p className="fs-10px fw-regular mb-1 text-start">Get quick access to Generate Scene & Chapter.</p>
                                            </div>
                                            <div className="col-lg-4 my-auto text-end">
                                                <span className="badge badge-default text-body fw-medium border">CTRL + /</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}