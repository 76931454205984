import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
  useHref,
} from "react-router-dom";

export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let children = useOutlet();
    let routeLd = useHref();

    return (
      <Component
        {...props}
        router={{ location, navigate, params, children, routeLd }}
      />
    );
  }

  return ComponentWithRouterProp;
}
