import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../components/common/TopBar";
import SideNav from "../components/common/SideNav";

class DashboardLayout extends Component {
  state = {
    sidebarToggle: false,
    loader: true,
  };

  handleMenuToggle = () => {
    this.setState({ sidebarToggle: !this.state.sidebarToggle });
  };

  handleLoaderOff = (loader) => {
    this.setState({ loader: loader });
  };

  render() {
    return (
      <>
        <TopBar 
            page="dashboard"
            availableTokens={this.state.availableTokens}
            doc={this.state.currentDoc}
        >
        </TopBar>
        <div className="container-fluid" style={{ marginTop: '70px' }}>
            <div className="row flex-nowrap">
                <SideNav page="dashboard"></SideNav>
                <div className={`col py-3`}>
                  <Outlet context={[this.handleLoaderOff]} />
                </div>
            </div>
        </div>
    </>
    );
  }
}

export default DashboardLayout;
