import React, { useState, useEffect } from "react";
import { getData } from "../../services/dataService";
import { getAllPackages } from "../../services/documentService";
import { handleHttpError } from "../../services/httpErrorService";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getProfile } from "../../services/userService";

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const [handleLoaderOff] = useOutletContext();
//   const [packages, setPackages] = useState([]);
  const [userPackage, setUserPackage] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = getData("token");
    // const user = getData("user");
    if (token != undefined || token != null) {
        getAllPackages()
        .then((httpResponse) => handleListPackageSuccess(httpResponse))
        .catch((httpResponse) => handleHttpError(httpResponse.response));
    } else {
      navigate(`/login`);
    }
  }, []);

  const handleListPackageSuccess = (httpResponse) => {
    getProfile().then((response) => {
        setUser(response.data.result.user || null)
        setUserPackage(response.data.result.packages[0] || null)
        handleLoaderOff(false);
    })
  };

  return (
    <div className="container-fluid">
        <div className="col-md-12 text-center">
            <h2 className="py-4 text-uppercase fw-normal" style={{ color: "#ef5d14" }}>Subscription</h2>
        </div>
        {userPackage && 
            <div className="row">
                <div className={`col-lg-4 mb-4 ${(user.user_mode == 'Author' && userPackage.amount > 2000) || (user.user_mode == 'Student' && userPackage.amount > 1000) ? 'mx-auto' : 'ms-auto'}`}>
                    <div className="card p-3 border-light h-100 theme-background" style={{ boxShadow: '0 .35rem .25rem rgba(0,0,0,.075)' }}>
                        <div className="card-body text-start">
                            <h4 className="card-title text-white mb-3">
                                {userPackage.display_name}
                                <span className="ms-2 badge theme-btn5 fs-8px align-middle rounded-1 fw-medium">Active Plan</span>
                            </h4>
                            {user.user_mode == 'Author' && 
                                <ul className="list-unstyled text-start text-white d-flex flex-wrap mb-0">
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Unlimited Documents</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 36+ Reports</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> AI Writing & Editing</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> {userPackage.amount > 100 ? '200,000' : '10,000'} tokens</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Upto 200k words</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Autosave</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 30+ AI Templates</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Access to lore book</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 8 AI Model Options</li>
                                </ul>
                            }
                            {user.user_mode == 'Student' && 
                                <ul className="list-unstyled text-start text-white d-flex flex-wrap mb-0">
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Unlimited Documents</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Citations</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> AI Writing & Editing</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> {userPackage.amount > 100 ? '200,000' : '10,000'} tokens / month</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Upto 200k words</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Autosave</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 30+ AI Templates</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 1 AI Model Option</li>
                                </ul>
                            }
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                {user.user_mode == 'Author' && userPackage.amount <= 100 && 
                    <div className="col-lg-4 me-auto mb-4">
                        <div className="card p-3 border-light h-100 bg-light" style={{ boxShadow: '0 .35rem .25rem rgba(0,0,0,.075)' }}>
                            <div className="card-body">
                                <h4 className="card-title text-start mb-3 color-orange">Professional Plan</h4>
                                <h4 className="card-title text-start mb-3">$20 / <span className="fs-12px fw-semibold">month</span></h4>
                                <ul className="list-unstyled text-start d-flex flex-wrap mb-0">
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Unlimited Documents</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 36+ Reports</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> AI Writing & Editing</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 200,000 tokens</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Upto 200k words</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Autosave</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 30+ AI Templates</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Access to lore book</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 8 AI Model Options</li>
                                </ul>
                            </div>
                            <div className="card-footer text-center py-4 bg-light">
                                <a className="btn theme-btn11 fs-14px py-2 px-4" target="_blank" href={`https://manuscripts.thrivecart.com/professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`}>Upgrade Now</a>
                            </div>
                        </div>
                    </div>
                }
                {user.user_mode == 'Student' && userPackage.amount <= 100 && 
                    <div className="col-lg-4 me-auto mb-4">
                        <div className="card p-3 border-light h-100 bg-light" style={{ boxShadow: '0 .35rem .25rem rgba(0,0,0,.075)' }}>
                            <div className="card-body">
                                <h4 className="card-title text-start mb-3 color-orange">Professional Plan</h4>
                                <h4 className="card-title text-start mb-3">$10 / <span className="fs-12px fw-semibold">month</span></h4>
                                <ul className="list-unstyled text-start d-flex flex-wrap mb-0">
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Unlimited Documents</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Citations</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> AI Writing & Editing</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 200,000 tokens / month</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Upto 200k words</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Autosave</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 30+ AI Templates</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 1 AI Model Option</li>
                                </ul>
                            </div>
                            <div className="card-footer text-center py-4 bg-light">
                                <a className="btn theme-btn11 fs-14px py-2 px-4" target="_blank" href={`https://manuscripts.thrivecart.com/students-professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`}>Upgrade Now</a>
                            </div>
                        </div>
                    </div>
                }
                {user.user_mode == 'Author' && userPackage.amount <= 2900 && 
                    <div className="col-lg-4 me-auto mb-4">
                        <div className="card p-3 border-light h-100 bg-light" style={{ boxShadow: '0 .35rem .25rem rgba(0,0,0,.075)' }}>
                            <div className="card-body">
                                <h4 className="card-title text-start mb-3 color-orange">Professional Annual Plan</h4>
                                <h4 className="card-title text-start mb-3">$130 / <span className="fs-12px fw-semibold">year</span></h4>
                                <ul className="list-unstyled text-start d-flex flex-wrap mb-0">
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Unlimited Documents</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 36+ Reports</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> AI Writing & Editing</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 200,000 tokens</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Upto 200k words</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Autosave</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 30+ AI Templates</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> Access to lore book</li>
                                    <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 8 AI Model Options</li>
                                </ul>
                            </div>
                            <div className="card-footer text-center py-4 bg-light">
                                <a className="btn theme-btn11 fs-14px py-2 px-4" target="_blank" href={`https://manuscripts.thrivecart.com/professional-plan-annual/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`}>Upgrade Now</a>
                            </div>
                        </div>
                    </div>
                }
                {user.user_mode == 'Student' && userPackage.amount <= 1500 && 
                    <div className="col-lg-4 me-auto mb-4">
                        <div className="card p-3 border-light h-100 bg-light" style={{ boxShadow: '0 .35rem .25rem rgba(0,0,0,.075)' }}>
                            <div className="card-body">
                                <h4 className="card-title text-start mb-3 color-orange">Professional Annual Plan</h4>
                                <h4 className="card-title text-start mb-3">$100 / <span className="fs-12px fw-semibold">year</span></h4>
                                <ul className="list-unstyled text-start d-flex flex-wrap mb-0">
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Unlimited Documents</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Citations</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> AI Writing & Editing</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 200,000 tokens / month</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Upto 200k words</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> Autosave</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 30+ AI Templates</li>
                                    <li className="fs-14px"><span className="mdi mdi-check fs-3 me-2 text-success align-middle"></span> 1 AI Model Option</li>
                                </ul>
                            </div>
                            <div className="card-footer text-center py-4 bg-light">
                                <a className="btn theme-btn11 fs-14px py-2 px-4" target="_blank" href={`https://manuscripts.thrivecart.com/students-professional-annual-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`}>Upgrade Now</a>
                            </div>
                        </div>
                    </div>
                }
                <div className="col-lg-4 me-auto mb-4">
                    <div className="card p-3 border-light h-100 bg-light" style={{ boxShadow: '0 .35rem .25rem rgba(0,0,0,.075)' }}>
                        <div className="card-body">
                            <h4 className="card-title text-start mb-3 color-orange">Additional Tokens</h4>
                            <h4 className="card-title text-start mb-3">$10 / <span className="fs-12px fw-semibold">200k tokens</span></h4>
                            <p className="fs-12px fw-medium ps-2 pe-3 mb-2 text-start lh-lg">200,000 extra tokens with the add on plan only existing members with active membership will be able to purchase.</p>
                            <ul className="list-unstyled text-start d-flex flex-wrap mb-0">
                                <li className="fs-12px fw-medium ps-2 pe-3 mb-2"><span className="mdi mdi-check fs-4 me-2 text-success align-middle"></span> 200,000 tokens</li>
                            </ul>
                        </div>
                        <div className="card-footer text-center py-4 bg-light">
                            <a className="btn theme-btn11 fs-14px py-2 px-4" target="_blank" href={`https://manuscripts.thrivecart.com/purchase-tokens/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`}>Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
        }

        {user && !userPackage && 
            <div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Card title</h5>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a target="_blank" href="#" className="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
        }
    </div>
  );
};

export default SubscriptionDetails;