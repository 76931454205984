import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash';

export default function SentenceDialogueGraph({ sentenceReport }) {
    let sentences = [], dialogue = [];
    sentenceReport.forEach(element => {
        if (element.type == 'dialogue') {
            sentences.push(0)
            dialogue.push(1)
        } else {
            sentences.push(1)
            dialogue.push(0)
        }
    });
        
    const seriesData = [{ name: 'Narrative', data: sentences, color: '#eecda380' }, { name: 'Dialogue', data: dialogue, color: '#ef629f80' }];
    const options = {
        colors: ['#dcaa2080'],
        chart: { type: 'column', heigth: 50 },
        title: { text: null },
        subtitle: { text: null },
        xAxis: { categories: [''], title: { text: 'No. of Sentences in order' } },
        yAxis: { visible: false },
        tooltip: { 
            formatter: function () {
                var para = sentenceReport[this.point.x].sentence;
                var text = `<span><b>${this.series.name}: </b>${para.substring(0, 40)}...</span><br /><span><b>Number of Sentence: </b>${this.point.x}`
                return text;
            },
            pointFormat: 'Number of sentence: {point.x}'
        },
        credits: { enabled: false },
        series: seriesData,
    };

    return (
        <HighchartsReact 
            highcharts={ Highcharts } 
            options={ options } 
        />
    );
}