import languages from '../../utils/languages';

export default function QuillToolBar ({ user, aISubPanel, setAISubPanel, generateContent, searchCitation, validateImage, chartLoader, docId, posStart, posEnd, setCheckPage, redirectTo }) {

    const showAIPanel = (e) => {
        e.preventDefault()
        const list = document.querySelector("#ai_tools_list")

        if (list) {
            list.classList.add('show');
        }
    }

    const loadPlagrism = (uri) => {
        setCheckPage('Plagiarism')
        redirectTo(uri)
    }

    return (
        <>
            <div id="toolbar" className="d-flex flex-wrap gap-2 align-items-center justify-content-start py-1">
                <ul id="ai_tools_list" className="dropdown-menu position-absolute py-1">
                    <li onMouseEnter={(e) => setAISubPanel('paraphrase')}>
                        <span className="fs-12px dropdown-item">Paraphrase<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'paraphrase' ? 'show' : ''}`} style={{ left: '200px' }}>
                            <li key={`paraphraseAcademically`} onClick={(e) => generateContent(e, 'Paraphrase', 'Academically')}><span className="fs-12px dropdown-item">Academically</span></li>
                            <li key={`paraphraseBoldly`} onClick={(e) => generateContent(e, 'Paraphrase', 'Boldly')}><span className="fs-12px dropdown-item">Boldly</span></li>
                            <li key={`paraphraseCasually`} onClick={(e) => generateContent(e, 'Paraphrase', 'Casually')}><span className="fs-12px dropdown-item">Casually</span></li>
                            <li key={`paraphraseCreatively`} onClick={(e) => generateContent(e, 'Paraphrase', 'Creatively')}><span className="fs-12px dropdown-item">Creatively</span></li>
                            <li key={`paraphraseFluency`} onClick={(e) => generateContent(e, 'Paraphrase', 'Fluency')}><span className="fs-12px dropdown-item">Fluency</span></li>
                            <li key={`paraphraseFormaly`} onClick={(e) => generateContent(e, 'Paraphrase', 'Formaly')}><span className="fs-12px dropdown-item">Formaly</span></li>
                            <li key={`paraphraseFriendly`} onClick={(e) => generateContent(e, 'Paraphrase', 'Friendly')}><span className="fs-12px dropdown-item">Friendly</span></li>
                            <li key={`paraphrasePersuasively`} onClick={(e) => generateContent(e, 'Paraphrase', 'Persuasively')}><span className="fs-12px dropdown-item">Persuasively</span></li>
                            <li key={`paraphraseShort`} onClick={(e) => generateContent(e, 'Paraphrase', 'Short')}><span className="fs-12px dropdown-item">Short</span></li>
                            <li key={`paraphraseSimply`} onClick={(e) => generateContent(e, 'Paraphrase', 'Simply')}><span className="fs-12px dropdown-item">Simply</span></li>
                        </ul>
                    </li>
                    <li onMouseEnter={(e) => setAISubPanel('ai_edit')}>
                        <span className="fs-12px dropdown-item">AI Edit<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'ai_edit' ? 'show' : ''}`} style={{ left: '200px' }}>
                            <li key={`editStructural`} onClick={(e) => generateContent(e, 'AI Edit', 'Structural')}><span className="fs-12px dropdown-item">Structural Edit</span></li>
                            <li key={`editLine`} onClick={(e) => generateContent(e, 'AI Edit', 'Line')}><span className="fs-12px dropdown-item">Line Edit</span></li>
                            <li key={`editDev`} onClick={(e) => generateContent(e, 'AI Edit', 'Dev')}><span className="fs-12px dropdown-item">Developmental Edit</span></li>
                            <li key={`editCopy`} onClick={(e) => generateContent(e, 'AI Edit', 'Copy')}><span className="fs-12px dropdown-item">Copy Edit</span></li>
                        </ul>
                    </li>
                    {user?.user?.user_mode == 'Author' && 
                        <>
                            <li onMouseEnter={(e) => setAISubPanel('perspective')}>
                                <span className="fs-12px dropdown-item">Perspective<i className="mdi mdi-chevron-right float-end"></i></span>
                                <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'perspective' ? 'show' : ''}`} style={{ left: '200px' }}>
                                    <li key={`perspectiveFirst`} onClick={(e) => generateContent(e, 'Perspective', 'First')}><span className="fs-12px dropdown-item">First</span></li>
                                    <li key={`perspectiveSecond`} onClick={(e) => generateContent(e, 'Perspective', 'Second')}><span className="fs-12px dropdown-item">Second</span></li>
                                    <li key={`perspectiveThird`} onClick={(e) => generateContent(e, 'Perspective', 'Third')}><span className="fs-12px dropdown-item">Third</span></li>
                                </ul>
                            </li>
                            <li onMouseEnter={(e) => setAISubPanel('voice')}>
                                <span className="fs-12px dropdown-item">Voice Update<i className="mdi mdi-chevron-right float-end"></i></span>
                                <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'voice' ? 'show' : ''}`} style={{ left: '200px' }}>
                                    <li key={`voiceActive`} onClick={(e) => generateContent(e, 'Voice Update', 'Active')}><span className="fs-12px dropdown-item">Active</span></li>
                                    <li key={`voicePassive`} onClick={(e) => generateContent(e, 'Voice Update', 'Passive')}><span className="fs-12px dropdown-item">Passive</span></li>
                                </ul>
                            </li>
                        </>
                    }
                    <li onMouseEnter={(e) => setAISubPanel('ai_rewrite')}>
                        <span className="fs-12px dropdown-item">AI Rewrite<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'ai_rewrite' ? 'show' : ''}`} style={{ left: '200px' }}>
                            <li key={`rewriteDefault`} onClick={(e) => generateContent(e, 'AI Rewrite', 'Default')}><span className="fs-12px dropdown-item">Rewrite</span></li>
                            <li key={`rewriteShow`} onClick={(e) => generateContent(e, 'AI Rewrite', 'Show')}><span className="fs-12px dropdown-item">Show, Don't Tell</span></li>
                            <li key={`rewriteDescriptive`} onClick={(e) => generateContent(e, 'AI Rewrite', 'Descriptive')}><span className="fs-12px dropdown-item">More Descriptive</span></li>
                            <li key={`rewriteIntensity`} onClick={(e) => generateContent(e, 'AI Rewrite', 'Intensity')}><span className="fs-12px dropdown-item">Add Intensity</span></li>
                            <li key={`rewriteTonality`} onClick={(e) => generateContent(e, 'AI Rewrite', 'Tonality')}><span className="fs-12px dropdown-item">Same Tonality And Intent</span></li>
                        </ul>
                    </li>
                    <li onClick={(e) => generateContent(e, 'Expand Content with AI', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Expand Content</span></li>
                    <li onClick={(e) => generateContent(e, 'Complete Story with AI', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Complete Story</span></li>
                    <li onMouseEnter={(e) => setAISubPanel('translate')}>
                        <span className="fs-12px dropdown-item">Translate<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`overflow-scroll top-0 position-absolute dropdown-menu ${aISubPanel == 'translate' ? 'show' : ''}`} style={{ left: '200px', height: '30vh' }}>
                            {languages && languages.map(language => (
                                <li key={`translate${language}`} onClick={(e) => generateContent(e, 'Translate', language)}><span className="fs-12px dropdown-item">{language}</span></li>
                            ))}
                        </ul>
                    </li>
                    {user?.user?.user_mode == "Student" &&
                        <>
                            <li onClick={(e) => generateContent(e, 'Opposing Arguments', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Opposing Arguments</span></li>
                        </>
                    }
                    <li onClick={(e) => generateContent(e, 'Content Improver', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Content Improver</span></li>
                    <li onClick={(e) => generateContent(e, 'Summary Generator', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Summary Generator</span></li>
                    <li onClick={(e) => generateContent(e, 'Improve Structure', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Improve Structure</span></li>
                    {/* <li onClick={(e) => generateContent(e, 'Humanizer', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Humanizer</span></li> */}
                    <li onMouseEnter={(e) => setAISubPanel('humanizer')}>
                        <span className="fs-12px dropdown-item">Humanizer<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`overflow-scroll top-0 position-absolute dropdown-menu ${aISubPanel == 'humanizer' ? 'show' : ''}`} style={{ left: '200px', height: '30vh' }}>
                            <li key={'humanizerStandard'} onClick={(e) => generateContent(e, 'Humanizer', 'Standard')}><span className="fs-12px dropdown-item">Standard</span></li>
                            <li key={'humanizerAcademic'} onClick={(e) => generateContent(e, 'Humanizer', 'Academic')}><span className="fs-12px dropdown-item">Academic</span></li>
                            <li key={'humanizerSimple'} onClick={(e) => generateContent(e, 'Humanizer', 'Simple')}><span className="fs-12px dropdown-item">Simple</span></li>
                            <li key={'humanizerFlowing'} onClick={(e) => generateContent(e, 'Humanizer', 'Flowing')}><span className="fs-12px dropdown-item">Flowing</span></li>
                            <li key={'humanizerFormal'} onClick={(e) => generateContent(e, 'Humanizer', 'Formal')}><span className="fs-12px dropdown-item">Formal</span></li>
                            <li key={'humanizerInformal'} onClick={(e) => generateContent(e, 'Humanizer', 'Informal')}><span className="fs-12px dropdown-item">Informal</span></li>
                        </ul>
                    </li>
                    <li onClick={(e) => loadPlagrism(`/editor/plagiarism-citation?doc=${docId}&s=${posStart}&l=${posEnd}&src=selection`)} onMouseEnter={(e) => setAISubPanel('')}>
                        <span className="fs-12px dropdown-item">Plagiarism Checker</span>
                    </li>
                    {/* <li onClick={(e) => setCheckPage('plagiarism')} onMouseEnter={(e) => setAISubPanel('')}>
                        <span className="fs-12px dropdown-item">Plagiarism</span>
                    </li> */}
                    {/* <li>
                        <Link className="ql-disabled ql-close fs-12px dropdown-item" to={`/editor/plagiarism-citation?doc=${docId}&s=${posStart}&l=${posEnd}&src=selection`}>
                            Plagiarism Checker
                        </Link>
                    </li> */}
                    {/* <Link className="ql-disabled ql-close fs-12px dropdown-item" to={`/editor/plagiarism-citation?doc=${docId}&s=${posStart}&l=${posEnd}&src=selection`}>
                        Plagiarism Checker
                    </Link> */}
                    {/* <li onClick={(e) => generateContent(e, 'Plagiarism Checker', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Plagiarism</span></li> */}
                </ul>
                <button onClick={(e) => showAIPanel(e)} className="ql-aibtn">
                    <i className="mdi mdi-chip me-2"></i> AI Commands
                </button>
                {user?.user?.user_mode == "Student" &&
                    <button onClick={(e) => searchCitation(e)} className="ql-citebtn">
                        @ Cite Text
                    </button>
                }
                <select className="ql-size">
                    <option value="small"></option>
                    <option defaultValue></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-link"></button>
                <button className="ql-script" value="sub"></button>
                <button className="ql-script" value="super"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-clean"></button>
                {/* <button onClick={(e) => createTable(e)} className="ql-table">
                    <span className="mdi mdi-table"></span>
                </button> */}
                {user?.user?.user_mode == 'Student' && 
                    <button title-right="Generate Graph" onClick={(e) => validateImage(e)}>
                        {chartLoader && 
                            <div className="spinner-border mai-spinner spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        {!chartLoader && 
                            <span className="mdi mdi-file-chart-outline"></span>
                        }
                    </button>
                }
            </div>
        </>
    )
}