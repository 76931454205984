import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import _ from "lodash";

import { generateNotification } from "../../services/notificationService";
import { getWordsReportData } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, highlightTextBlast } from "../../services/highlightContent";
import Card2 from "../common/Cards/Card2";

export default function Cliche() {

    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    const [genre, setGenre] = useState(23);

    useEffect(() => {
        setReport({});
        setCheckPage('cliches');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadCliche();
        }
    }, [propsRouter]);

    const loadCliche = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
               
                if (userData.packages[0] && userData.user) {
                    const quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let response = await getWordsReportData(quillContent, 'Cliche', userData.user, genre);

                    let wordCount = quillContent.split(" ").length;

                    let results = response.data.data;
                    let paragraphData = response.data.paragraphIndexs;

                    response = _.map(_.groupBy(response.data.data, "word"), (dataArr, word) => { return { word: word, wordsCount: dataArr.length } });
                    response = _.orderBy(response, ["wordsCount"], ["desc"]);

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            keywordsData: response,
                            wordCount: wordCount,
                            paragraphData: paragraphData,
                            results: results,
                        },
                    });
                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        });
    }

    let keywordsData = [], wordCount = 0, paragraphData = [], results = [], score = 0.0;
    if (report?.data) {
        keywordsData = report.data.keywordsData || [];
        wordCount = report.data.wordCount || 0;
        paragraphData = report.data.paragraphData || [];
        results = report.data.results || [];
        score = Number(results.length*100/wordCount).toFixed(1);

    }
    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Cliche</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/platitude-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadCliche} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="">
                    <div id="paraLoadInfo">
                            {score > 5 &&
                                <>
                                    <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-14px pe-2 fw-medium">
                                                    Overall Score: {score}%<br />
                                                    <span className="fs-12px fw-normal">Recommended overall score for this report is less than or equal to 5.</span>
                                                    </div>
                                                <div className="ms-auto border p-2 rounded">
                                                    <span className="count">
                                                        <i className="mdi mdi-thumb-down align-self-center text-danger"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {score <= 5 &&
                                <>
                                    <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-14px pe-2 fw-medium">
                                                    Overall Score: {score}%<br />
                                                    <span className="fs-12px fw-normal">Recommended overall score for this report is less than or equal to 5.</span>
                                                    </div>
                                                <div className="ms-auto border p-2 rounded">
                                                    <span className="count">
                                                        <i className="mdi mdi-thumb-up align-self-center text-success"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                    </div>
                </div>
                <hr />
                <div className="reportContainer mt-0">
                    {!loader && keywordsData.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {loader && keywordsData.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    {keywordsData && keywordsData.map((v, i) => (
                        <div className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} key={i}>
                            <div className="card-body">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.word}</div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.wordsCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}