import React, { useState } from "react";
import Button2 from "../common/Buttons/Button2";
import Button1 from "../common/Buttons/Button1";
import { deleteDocument } from "../../services/documentService";
import { generateNotification } from "../../services/notificationService";

export default function DeleteDocModal({
  delModalToggle,
  setOpenDelModal,
  setDltData,
}) {

  const confirmDeleteBtn = async () => {
    setTimeout(async () => {
      if (setDltData) {
        generateNotification({
          icon: "success",
          title: "File Action",
          text: "Deleting file permanently! Page will refresh once the process is completed.",
        });
        await deleteDocument(
          setDltData.id,
          setDltData.title,
          setDltData.description,
          setDltData.content
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        generateNotification({
          icon: "alert",
          title: "Details Missing: ",
          text: "Delete data not found!",
        });
      }
    }, 500);
  };

  return (
    <div
      className={delModalToggle ? "modal fade show" : "modal fade"}
      style={{ display: +delModalToggle ? "block" : "none" }}
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h5 className="modal-title">Delete Document</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setOpenDelModal(false);
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="col-12">
              <p>
                This action need your permission! This action will remove your
                document permanently. Please confirm to delete.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button1 onClick={(e) => confirmDeleteBtn()}>Confirm</Button1>
            <Button2 onClick={() => { setOpenDelModal(false) }} >Close</Button2>
          </div>
        </div>
      </div>
    </div>
  );
}
