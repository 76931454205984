import React, { useEffect } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex } from "../../services/highlightContent";
import { generateNotification } from "../../services/notificationService";
import { getParagraphVariation } from "../../services/reportServices";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function ParagraphVariation() {
    
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('paragraph variation');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
        loadParagraphVariation();
        }
    }, [propsRouter]);

    const loadParagraphVariation = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }

                    let response = await getParagraphVariation(quillContent);
                    response = response.data.response;
    
                    const paraLessThen25 = response.filter(res => (res.wordsCount < 25 && res.wordsCount != 0));
                    const para25to49 = response.filter(res => (res.wordsCount >= 25 && res.wordsCount <= 49));
                    const para50to74 = response.filter(res => (res.wordsCount >= 50 && res.wordsCount <= 74));
                    const para75to99 = response.filter(res => (res.wordsCount >= 75 && res.wordsCount <= 99));
                    const paraGreaterThen100 = response.filter(res => (res.wordsCount >= 100));
                    
                    var svd = { 'paraLessThen25': paraLessThen25, 'para25to49': para25to49, 'para50to74': para50to74, 'para75to99': para75to99, 'paraGreaterThen100': paraGreaterThen100 };
    
                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            response: response,
                            paraLessThen25: paraLessThen25,
                            para25to49: para25to49,
                            para50to74: para50to74,
                            para75to99: para75to99,
                            paraGreaterThen100: paraGreaterThen100,
                        },
                    });
    
                    const keys = Object.keys(svd);
                    const values = Object.values(svd);
    
                    values.map((res, index) => {
                        quillContentInsertBlotByContentIndex(quill, 0, res, "sentenceStartIndex", "sentenceEndIndex", 
                            [{
                                blotName: "span",
                                id: `paragraph-variation-{index}`,
                                classes: [`${keys[index]}`, `paragraph-variation-{wordsCount}`],
                                classDynamicKeys: [ "", { replaceBy: "{wordsCount}", replaceToKey: "wordsCount" } ],
                            }]
                        );
                    })

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        }, 1000);
    }

    const addBgClass = (className) => {
        window.$(`#${className}_cb`).click();
        if (window.$(`#${className}_cb`).prop('checked')) {
            window.$(`.${className}`).addClass('active');
        } else {
            window.$(`.${className}`).removeClass('active');
        }
    }

        let paraLessThen25, para25to49, para50to74, para75to99, paraGreaterThen100, response = [];
        if (report?.data) {
            paraLessThen25 = report.data.paraLessThen25 || [];
            para25to49 = report.data.para25to49 || [];
            para50to74 = report.data.para50to74 || [];
            para75to99 = report.data.para75to99 || [];
            paraGreaterThen100 = report.data.paraGreaterThen100 || [];
            response = report.data.response || [];
        }
        return (
            <div className="result_cover">
                <div className="top_nav">
                    <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                        <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                        <span>Paragraph Variation</span>
                        <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/paragraph-count-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                        <span className="ms-auto">
                            <button onClick={loadParagraphVariation} className="btn theme-btn4 fs-12px">Refresh</button>
                        </span>
                    </div>
                </div>
                <div className="result_card_cover text-start">
                    <div id="paraLoadInfo">
                        <div className="position-relative">
                            {!loader && response.length == 0 && 
                                <Card2 title="Report Warning" description="Unable to fetch content or Provide out for this content, Please Refresh the report."></Card2>
                            }
                            {loader && response.length == 0 && 
                                <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                            }
                            {paraLessThen25 && 
                                <div className="less-then-10 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 paraLessThen25" onClick={(e) => addBgClass('paraLessThen25')}>
                                    <div className="card-body">
                                        <input className="form-check-input" id="paraLessThen25_cb" type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>Less Than 25: </div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{paraLessThen25.length}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {para25to49 && 
                                <div className="less-then-10 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 para25to49" onClick={(e) => addBgClass('para25to49')}>
                                    <div className="card-body">
                                        <input className="form-check-input" id="para25to49_cb" type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>Between 25 to 49: </div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{para25to49.length}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {para50to74 && 
                                <div className="less-then-10 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 para50to74" onClick={(e) => addBgClass('para50to74')}>
                                    <div className="card-body">
                                        <input className="form-check-input" id="para50to74_cb" type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>Between 50 to 74: </div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{para50to74.length}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {para75to99 && 
                                <div className="less-then-10 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 para75to99" onClick={(e) => addBgClass('para75to99')}>
                                    <div className="card-body">
                                        <input className="form-check-input" id="para75to99_cb" type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>Between 75 to 99: </div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{para75to99.length}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {paraGreaterThen100 && 
                                <div className="less-then-10 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 paraGreaterThen100" onClick={(e) => addBgClass('paraGreaterThen100')}>
                                    <div className="card-body">
                                        <input className="form-check-input" id="paraGreaterThen100_cb" type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>More Than 100: </div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{paraGreaterThen100.length}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            // <div className="inner_cover">
            //     <h2>Paragraph Variation</h2>
            //     <a className="badge shadow-md" href={`https://manuscripts.ai/how-to/paragraph-count-report/`} target="_blank"> <i className="mdi mdi-information"></i>&nbsp;&nbsp;HOW TO</a>
            //     <div className="pt-4">
            //         {paraLessThen25 && 
            //             <div className="reportcard-1 shadow-sm paraLessThen25" onClick={(e) => addBgClass('paraLessThen25')}>
            //                 <input className="form-check-input" id="paraLessThen25_cb" type="checkbox" />
            //                 Less Than 25: <span className="count">{paraLessThen25.length}</span>
            //             </div>
            //         }
            //         {para25to49 && 
            //             <div className="reportcard-1 shadow-sm para25to49" onClick={(e) => addBgClass('para25to49')}>
            //                 <input className="form-check-input" id="para25to49_cb" type="checkbox" />
            //                 Between 25 to 49 : <span className="count">{para25to49.length}</span>
            //             </div>
            //         }
            //         {para50to74 && 
            //             <div className="reportcard-1 shadow-sm para50to74" onClick={(e) => addBgClass('para50to74')}>
            //                 <input className="form-check-input" id="para50to74_cb" type="checkbox" />
            //                 Between 50 to 74 : <span className="count">{para50to74.length}</span>
            //             </div>
            //         }
            //         {para75to99 && 
            //             <div className="reportcard-1 shadow-sm para75to99" onClick={(e) => addBgClass('para75to99')}>
            //                 <input className="form-check-input" id="para75to99_cb" type="checkbox" />
            //                 Between 75 to 99 : <span className="count">{para75to99.length}</span>
            //             </div>
            //         }
            //         {paraGreaterThen100 && 
            //             <div className="reportcard-1 shadow-sm paraGreaterThen100" onClick={(e) => addBgClass('paraGreaterThen100')}>
            //                 <input className="form-check-input" id="paraGreaterThen100_cb" type="checkbox" />
            //                 More Than 100: <span className="count">{paraGreaterThen100.length}</span>
            //             </div>
            //         }
            //     </div>
            // </div>
        )
}