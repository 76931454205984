import axios from "axios";

import { apiRouts } from "./../utils/routes";
import { getData } from "./dataService";

const userApiOptions = {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'authorization': `${getData("token_type")} ${getData("token")}`
  }
};

export const getUserSettings = (temperature, no_of_cards, size, prompt, genre) => {
  return axios.post(
    apiRouts.get_user_setting,
    { temperature, no_of_cards, size, prompt, genre },
    userApiOptions
  );
};

export const getUserLanguage = (language) => {
  return axios.post(
    apiRouts.get_user_language,
    { language },
    userApiOptions
  );
};

export const getProfile = () => {
  return axios.get(apiRouts.profile, userApiOptions);
};

export const updateProfile = (request) => {
  if (!(request["password"] && request["password"] !== "")) {
    delete request["password"];
  }
  return axios.post(apiRouts.profile, request, userApiOptions);
};

export const updateTour = (request) => {
  return axios.post(apiRouts.updateTour, request, userApiOptions);
};

export const removePackage = (email, order_id, cancellation_reason) => {
  return axios.post(apiRouts.removePackage, { email, order_id, cancellation_reason }, userApiOptions);
};

export const createStripeSubscription = (name, email, priceId) => {
  return axios.post(apiRouts.create_checkout_session, { name: name, email: email, priceId: priceId  }, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', } });
};

export const getStripeSecret = () => {
  return axios.post(apiRouts.create_payment_intent, {  }, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', } });
};
