export const languages = [
    "Afrikaans", 
    "Arabic", 
    "Armenian", 
    "Azerbaijani", 
    "Belarusian", 
    "Bosnian", 
    "Bulgarian", 
    "Catalan", 
    "Chinese", 
    "Croatian", 
    "Czech", 
    "Danish", 
    "Dutch", 
    "English", 
    "Estonian", 
    "Finnish", 
    "French", 
    "Galician", 
    "German", 
    "Greek", 
    "Hebrew", 
    "Hungarian", 
    "Icelandic", 
    "Indonesian", 
    "Italian", 
    "Japanese", 
    "Kazakh", 
    "Korean", 
    "Latvian", 
    "Lithuanian", 
    "Macedonian", 
    "Malay", 
    "Maori", 
    "Nepali", 
    "Norwegian", 
    "Persian", 
    "Polish", 
    "Portuguese", 
    "Romanian", 
    "Russian", 
    "Serbian", 
    "Slovak", 
    "Slovenian", 
    "Spanish", 
    "Swahili", 
    "Swedish", 
    "Tagalog", 
    "Thai", 
    "Turkish", 
    "Ukrainian", 
    "Urdu", 
    "Vietnamese", 
    "Welsh"
]

export default languages;