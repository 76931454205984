import winkNLP from 'wink-nlp';
import model from 'wink-eng-lite-web-model';
import its from 'wink-nlp/src/its';

const nlp = winkNLP(model);
export default class NLPService {
    htmlText = "";
    plainText = "";
    htmlDoc = {};
    plainDoc = {};

    constructor(text) {
        this.htmlText = text;
        this.htmlDoc = nlp.readDoc(this.htmlText);

        this.plainText = text.replace(/<[^>]*>?/gm, '');
        this.plainDoc = nlp.readDoc(this.plainText);
    }

    setContent(text) {
        this.htmlText = text;
        this.htmlDoc = nlp.readDoc(this.htmlText);
        this.plainText = text.replace(/ +/g, " ");

        if (this.plainText.split(String.fromCharCode(8201)).length > 1)
            this.plainText = this.plainText.replace(new RegExp(`${String.fromCharCode(8201)}`, 'g'), "");

        if (this.plainText[this.plainText.length - 1] === '\n') {
            this.plainText = this.plainText.trimRight();
        }
        this.plainDoc = nlp.readDoc(this.plainText);
    }

    getSentaces() {
        let sentencesList = [];
        let sentenceStartIndex = 0;
        this.plainDoc.sentences().each((sentence => {
            let currentSentence = sentence.out();
            sentencesList.push({
                sentence: currentSentence,
                sentenceStartIndex: sentenceStartIndex,
                stringEndIndex: currentSentence.length
            });

            sentenceStartIndex += currentSentence.length + 1;
        }))
        return sentencesList;
    }

    getTagReport = () => {
        return new Promise((resolve, reject) => {
            try {
                let tagWiseCount = [];
                this.plainDoc.tokens().each((token) => {
                    let entity = token.parentEntity();
                    let currentWord = token.out();
                    tagWiseCount.push({
                        wordLength: currentWord.length,
                        wordStartIndex: token.index() === 0 ? 0 : tagWiseCount[token.index() - 1].wordEndIndex,
                        wordEndIndex: currentWord.length + ((token.index() === 0) ? 0 : tagWiseCount[token.index() - 1].wordEndIndex),
                        wordTag : entity == undefined ? token.out(its.pos) : entity.out(its.type),
                        word: currentWord
                    });

                    let tempSpaceCount = this.plainText
                            .substring(token.index() === 0 ? 0 : tagWiseCount[token.index() - 1].wordEndIndex, this.plainText.length)
                            .indexOf(currentWord);

                    tagWiseCount[token.index() === 0 ? 0 : token.index()].wordStartIndex += tempSpaceCount;
                    tagWiseCount[token.index() === 0 ? 0 : token.index()].wordEndIndex += tempSpaceCount;
                });
                resolve(tagWiseCount);
            } catch (error) {
                reject(error);
            }
        });
    }    
}