import React, { useState, useEffect } from "react";
import { generateNotification } from "../../services/notificationService";
import { createDocument } from "../../services/documentService";

export default function NewDocumentModal({ modalToggle, setOpenCNDModal, userData }) {
  
  const [loader, setLoader] = useState(false);
  const [articleId, setArticleId] = useState("");
  const [title, setTitle] = useState("");
  const [genre, setGenre] = useState("");
  const [genreType, setGenreType] = useState("");
  const [activityType, setActivityType] = useState("write");
  const [description, setDescription] = useState("");

  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    genre: "",
    genreType: "",
    generic: "",
  });

  useEffect(() => {
    setLoader(false)
    // if (userData?.user_mode == 'Student') {
    //   setStep(2)
    // } else {
    //   setStep(1)
    // }
  }, [modalToggle])

  const handleSaveDocSuccess = (response) => {
    if (response.status == 200) {
      if (response.data.message) {
        setFormErrors({ generic: response.data.message || "" });
        setLoader(false);
      } else {
        setOpenCNDModal(false);
        let docId = response.data.result.uuid;
        generateNotification({
          title: "Document created successfully..",
          text: "Engage your readers with great writing. Stand out by delivering a captivating experience for your readers. Start writting your book and don't forget to try our exclusive AI tools which can help you write your book.",
          icon: "success",
        });
        setLoader(false);
        window.location.href = `/editor?doc=${docId}&activity=${activityType}`;
      }
    } else {
      setLoader(false);
      setFormErrors({ generic: response.data.message || "" });
    }
  };

  const handleSaveDocError = (error) => {
    if (error && error.status == 400) {
      if (typeof error.data.error != "undefined") {
        let { title, description } = error.data.error;
        setFormErrors({
          title: title ? title : "",
          description: description ? description : "",
          generic: "",
        });
      }
      return;
    }
    setLoader(false);
  };

  const saveDocument = (e) => {
    e.preventDefault();
    setLoader(true);
    setFormErrors({});
    let titleMsg, descriptionMsg = '', genreMsg = '', genreTypeMsg = '';

    if (!title) {
      setLoader(false);
      titleMsg = 'Title Field is not allowed to be empty';
    } else if (!description || description.length < 50) {
      setLoader(false);
      descriptionMsg = 'Description Length must be at least 50 characters long';
    } else if (!genreType && userData?.user_mode == 'Author') {
      setLoader(false);
      genreTypeMsg = 'Make selection for your book genre type (Fiction / Non-Fiction)';
    } else if (!genre && userData?.user_mode == 'Author') {
      setLoader(false);
      genreMsg = 'Genre is not allowed to be empty';
    } else {
      createDocument({ title: title, description: description, content: "", genre_type: genreType, genre: genre })
        .then((httpResponse) => handleSaveDocSuccess(httpResponse))
        .catch((httpResponse) => handleSaveDocError(httpResponse.response));
    }

    setFormErrors({
      title: titleMsg ? titleMsg : "",
      description: descriptionMsg ? descriptionMsg : "",
      genre: genreMsg ? genreMsg : "",
      genreType: genreTypeMsg ? genreTypeMsg : "",
    });
  };

  const updateGenreType = (type) => {
    setGenreType(type);
    setFormErrors({
      title: "",
      description: "",
      genre: "",
      genreType: "",
      generic: "",
    });
  }

  return (
    <div className={modalToggle ? "modal fade show" : "modal fade"} style={{ display: +modalToggle ? "block" : "none", background: "rgb(0 0 0 / 59%)", backdropFilter: "blur(10px)" }} tabIndex="-1">
      <div className="modal-dialog modal-md">
        <div className="modal-content px-3">
          <div className="modal-header">
            <h6 className="modal-title">
              {userData?.user_mode == 'Author' ? 'Create Your Book' : 'Create New Document'}
            </h6>
            <button type="button" className="btn-close fs-12px" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setOpenCNDModal(false); }}></button>
          </div>
          <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 my-2">
                    <div className="text-start position-relative border rounded mb-1">
                      {/* <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100">{`Document Title ${formErrors?.title && formErrors?.title}`}</label> */}
                      <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder={`Enter Document Title Here`} className={`form-control fs-14px border-0 px-3 py-2 lh-base ${(formErrors?.title && formErrors?.title != "") ? 'is-invalid' : ''}`} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="text-start position-relative border rounded mb-1">
                        {/* <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100">{`Description ${formErrors?.description && formErrors?.description}`}</label> */}
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder={`Describe your document here / Provide Synopsis of your story / Provide information about your research paper`} className={`form-control fs-14px border-0 px-3 py-2 lh-base ${(formErrors?.description && formErrors?.description != "") ? 'is-invalid' : ''}`} style={{ height: "150px" }}></textarea>
                        <p className="btn fs-10px fw-semibold py-1 px-2 position-absolute end-0 top-0 m-1 mb-0 bg-light">{description.length} Characters</p>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="form-check card text-start border-0">
                        <div className="card-body py-2">
                            <input className={`form-check-input ${(formErrors?.genreType && formErrors?.genreType != "") ? 'is-invalid' : ''}`} type="radio" name="activityOption" value="Standard" onChange={(e) => setActivityType("write")} checked={activityType == 'write'} />
                            <label className="form-check-label ms-2 fs-14px fw-medium" htmlFor="activityOption1">
                                Want to write?
                            </label>
                        </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="form-check card text-start border-0">
                        <div className="card-body py-2">
                            <input className={`form-check-input ${(formErrors?.genreType && formErrors?.genreType != "") ? 'is-invalid' : ''}`} type="radio" name="activityOption" value="AI" onChange={(e) => setActivityType("edit")} checked={activityType == 'edit'} />
                            <label className="form-check-label ms-2 fs-14px fw-medium" htmlFor="activityOption2">
                            Want to edit?
                            </label>
                        </div>
                    </div>
                  </div>
                {userData?.user_mode == 'Author' && 
                  <>
                    <div className="col-12 mb-2 text-start">
                      <label className="fs-12px fw-medium lh-base ms-3">{formErrors.genreType ? formErrors.genreType : `What type of book you are ${activityType == 'write' ? 'writing' : 'editing'} today?`}</label>
                    </div>
                    <div className="col-6 my-2">
                      <div className="form-check card mb-2 text-start border-0">
                          <div className="card-body py-1">
                              <input className={`form-check-input ${(formErrors?.genreType && formErrors?.genreType != "") ? 'is-invalid' : ''}`} type="radio" name="flexRadioDefault" value="Standard" onChange={(e) => updateGenreType("Fiction")} checked={genreType == 'Fiction'} />
                              <label className="form-check-label ms-2 fs-14px fw-medium" htmlFor="flexRadioDefault1">
                                  Fiction
                                  <p className="fs-10px mb-0 fw-light">Romance, Thriller, Sci-fi etc.</p>
                              </label>
                          </div>
                      </div>
                    </div>
                    <div className="col-6 my-2">
                      <div className="form-check card text-start border-0">
                          <div className="card-body py-1">
                              <input className={`form-check-input ${(formErrors?.genreType && formErrors?.genreType != "") ? 'is-invalid' : ''}`} type="radio" name="flexRadioDefault" value="AI" onChange={(e) => updateGenreType("Non Fiction")} checked={genreType == 'Non Fiction'} />
                              <label className="form-check-label ms-2 fs-14px fw-medium" htmlFor="flexRadioDefault2">
                                  Non Fiction
                                  <p className="fs-10px mb-0 fw-light">Health, Self-Help etc.</p>
                              </label>
                          </div>
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="text-start position-relative border rounded mb-1">
                          <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100">{`Genre ${formErrors?.genre && formErrors?.genre}`}</label>
                          <input value={genre} onChange={(e) => setGenre(e.target.value)} type="text" placeholder={`${genreType == "Fiction" ? 'Drama, Horror' : 'Business, Self Help'} etc...`} className={`form-control fs-14px border-0 px-3 py-2 lh-base ${(formErrors?.genre && formErrors?.genre != "") ? 'is-invalid' : ''}`} />
                      </div>
                    </div>
                </>
              }
            </div>
            <>
              <input id="docId" type="hidden" value={articleId} onChange={(e) => { setArticleId({ articleId: e.target.value }); }} />
              <p className="fs-10px fw-medium text-danger">
                {formErrors?.title && formErrors?.title}
                {formErrors?.description && formErrors?.description}
                {formErrors?.generic && formErrors?.generic}
              </p>
              <button className="btn theme-btn6 input-group-text m-3 px-4 fs-14px fw-medium" onClick={(e) => saveDocument(e)}>
                {loader && 
                  <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                }
                {userData?.user_mode == 'Author' ? 'Create Book' : 'Create Document'}
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
