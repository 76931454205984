import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash';

export default function VariationGraph ({ variationReport, title, color, type }) {

    const seriesData = [];

    variationReport.forEach(element => {
        seriesData.push({
            name: element.variant,
            data: [element.value.length]
        })
    });

    const options = {
        colors: [color],
        chart: { type: 'column' },
        title: { text: title },
        subtitle: { text: null },
        xAxis: {
            categories: [''],
            title: { text: null }
        },
        legend: {
            enabled: true
        },
        yAxis: {
            visible: false,
            min: 0,
            title: { text: '', align: 'high' },
            labels: { overflow: 'justify' }
        },
        tooltip: { valueSuffix: ' '+type },
        credits: { enabled: false },
        series: seriesData,
    };

    return (
        <HighchartsReact 
            highcharts={ Highcharts } 
            options={ options } 
        />
    );
}
