import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { getDominantWords } from "../../services/reportServices";
import {
  sanitizeResetQuillAndGetContent,
  highlightTextBlast,
} from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";

export default function DominantWords() {
  const [
    docId,
    report,
    setReport,
    quill,
    setQuill,
    userData,
    setUserData,
    propsRouter,
    setAvailableTokens,
    loader,
    handleLoaderOff,
    checkPage,
    setCheckPage, 
    setAiResultOutput, 
    setShowAIResultPopup
  ] = useOutletContext();

  useEffect(() => {
    setReport({});
    setCheckPage('dominant words');
    setAiResultOutput([]);
    setShowAIResultPopup(false);
    if (quill && quill.getLength() > 3) {
      loadDominantWords();
    }
  }, [propsRouter]);

  const loadDominantWords = async () => {
    handleLoaderOff(true);
    setTimeout(async () => {
      try {
        if (userData.packages[0] && userData.user) {
          const quillContent = await sanitizeResetQuillAndGetContent(quill);

          let newUserData = { id: userData.user.id, email: userData.user.email };
          let response = await getDominantWords(quillContent, newUserData);

          let powerWordData = [];
          let powerWordReport = [];

          powerWordData["safety"] = response[0];
          powerWordData["anger"] = response[1];
          powerWordData["encourage"] = response[2];
          powerWordData["energetic"] = response[3];
          powerWordData["fear"] = response[4];
          powerWordData["forbidden"] = response[5];
          powerWordData["general"] = response[6];
          powerWordData["greed"] = response[7];
          powerWordData["lust"] = response[8];

          let categories = ["anger", "encourage", "energetic", "fear", "forbidden", "general", "greed", "lust", "safety"];

          categories.forEach((category) => {
            powerWordReport[category] = _.map(
              _.groupBy(powerWordData[category].data.data, "word"),
              (adverbArr, word) => {
                return {
                  word: word,
                  type: category,
                  wordsCount: adverbArr.length,
                  dataArr: adverbArr,
                };
              }
            );
          });

          setReport({
            lastReportIndexes: {
              start: 0,
              end: quill.getLength(),
            },
            data: {
              response: response,
              powerWordReport: powerWordReport,
              angerWordReport: powerWordReport["anger"],
              encourageWordReport: powerWordReport["encourage"],
              energeticWordReport: powerWordReport["energetic"],
              fearWordReport: powerWordReport["fear"],
              forbiddenWordReport: powerWordReport["forbidden"],
              generalWordReport: powerWordReport["general"],
              greedWordReport: powerWordReport["greed"],
              lustWordReport: powerWordReport["lust"],
              safetyWordReport: powerWordReport["safety"],
            },
          });
          handleLoaderOff(false);
        } else {
          handleLoaderOff(false);
          generateNotification({
              title: "Account Expired!",
              text: "Please check your account for the payment, This report is currently not available.",
              icon: "error",
          });
        }
      } catch (error) {
        handleLoaderOff(false);
        generateNotification({
            title: "Network Error!",
            text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
            icon: "error",
        });
      }
    });
  };

  let angerWordReport = [];
  let encourageWordReport = [];
  let energeticWordReport = [];
  let fearWordReport = [];
  let forbiddenWordReport = [];
  let generalWordReport = [];
  let greedWordReport = [];
  let lustWordReport = [];
  let safetyWordReport = [];
  let response = [];

  if (report?.data) {
    angerWordReport = report.data.angerWordReport || [];
    encourageWordReport = report.data.encourageWordReport || [];
    energeticWordReport = report.data.energeticWordReport || [];
    fearWordReport = report.data.fearWordReport || [];
    forbiddenWordReport = report.data.forbiddenWordReport || [];
    generalWordReport = report.data.generalWordReport || [];
    greedWordReport = report.data.greedWordReport || [];
    lustWordReport = report.data.lustWordReport || [];
    safetyWordReport = report.data.safetyWordReport || [];
    response = report.data.response || [];
  }
  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
          <span>Dominant Words</span>
          <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/dominant-words-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
          <span className="ms-auto">
              <button onClick={loadDominantWords} className="btn theme-btn4 fs-12px">Refresh</button>
          </span>
        </div>
      </div>
      <div className="result_card_cover text-start">
        <div className="reportContainer">
          {!loader && response.length == 0 && 
              <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
          }
          {loader && response.length == 0 && 
              <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
          }
          {angerWordReport && angerWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)}>
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />

                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>Anger</small>
                    </div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {encourageWordReport && encourageWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>encourage</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {energeticWordReport && energeticWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>energetic</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {fearWordReport && fearWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>fear</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {forbiddenWordReport && forbiddenWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>forbidden</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {generalWordReport && generalWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>general</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {greedWordReport && greedWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>greed</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {lustWordReport && lustWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>lust</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {safetyWordReport && safetyWordReport.map((v, i) => (
              <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                <div className="card-body">
                  <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                  <div className="d-flex align-items-center">
                    <div>{v.word}<br /><small>safety</small></div>
                    <div className="ms-auto border p-2 rounded">
                      <span className="count">{v.wordsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
