import { useEffect } from "react";
import { useState } from "react"
import { deleteCitation, updateCitation } from "../../services/reportServices";

export default function CitationPreview({ citationData, setCitationData }) {

    const [recordId, setRecordId] = useState('');
    const [title, setTitle] = useState('');
    const [keywords, setKeywords] = useState('');
    const [link, setLink] = useState('');
    const [publicationDate, setpublicationDate] = useState('');
    const [publicationYear, setpublicationYear] = useState('');
    const [citationId, setCitationId] = useState('');
    const [citedByCount, setCitedByCount] = useState('');
    const [sourceOrganizationName, setSourceOrganizationName] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [volumeNo, setVolumeNo] = useState('');
    const [issueNo, setIssueNo] = useState('');
    const [firstPage, setFirstPage] = useState('');
    const [lastPage, setLastPage] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        let citaion = citationData ? JSON.parse(citationData.data_json) : [];
        setRecordId(citationData?.id);
        setAuthors(citaion.authorships);
        setCitationId(citationData?.citation_id);
        setTitle(citationData?.title);
        setKeywords(citationData?.keywords);
        setLink(citationData?.doi_link);
        setpublicationDate(citationData?.publication_date);
        setpublicationYear(citationData?.publication_year);
        setCitedByCount(citationData?.cited_by_count);
        setSourceOrganizationName(citationData?.source_organization_name);
        setSourceName(citationData?.source_name);
        setSourceType(citationData?.source_type);
        setVolumeNo(citationData?.volume_no);
        setIssueNo(citationData?.issue_no);
        setFirstPage(citationData?.first_page);
        setLastPage(citationData?.last_page);
    }, []);

    const clearCitationData = () => {
        setCitationData({});
        setErrMsg('');
    }

    const updateCitationData = () => {
        setErrMsg('');
        let citation_data = {
            id: recordId,
            citation_id: citationId,
            keywords: keywords,
            publication_date: publicationDate,
            publication_year: publicationYear,
            cited_by_count: citedByCount,
            cited_by_count: citedByCount, 
            source_organization_name: sourceOrganizationName,
            source_name: sourceName,
            source_type: sourceType,
            volume_no: volumeNo,
            issue_no: issueNo,
            first_page: firstPage,
            last_page: lastPage,
        }

        updateCitation(citation_data).then((response) => {
            setErrMsg('Citation details updated successfully!!!');
        }).catch((error) => {
            setErrMsg('Something went wrong, Please try again later or contact support with the citation id incase of repetitive error.');
        })
    }

    const deleteCitationData = () => {
        deleteCitation(recordId).then((res) => {
            let element = document.getElementById(citationId);
            if (element) element.remove();
            setCitationData({});
            setErrMsg('Citation removed successfully!!!');
        }).catch((error) => {
            setErrMsg('Some error occured while processing your request, Please try again later.');
        })
    }

    return (
        <div id="citaiton-preview" className="py-5 mt-5 text-start">
            <div className="row">
                <div className="col-lg-12 mb-2">
                    <h5>
                        Manage Citation
                        <button onClick={(e) => clearCitationData()} className="btn theme-btn4 fs-10px py-1 px-2 float-end">Close <i className="mdi mdi-close"></i></button>
                    </h5>
                    <h5 className="text-muted text-uppercase"><span id="citaiton_id">{citationId}</span></h5>
                </div>
                {errMsg && <span className="err_msg fs-10px mb-2 text-danger">{errMsg}</span>}
                <hr />
                <label htmlFor="biblio" className="fw-medium mb-2 fs-12px">
                    Citation Details
                    <button onClick={(e) => deleteCitationData()} className="btn theme-btn8 fs-12px py-1 border-0 px-2 float-end">Delete <i className="mdi mdi-delete-circle"></i></button>
                    <button onClick={(e) => updateCitationData()} className="btn theme-btn6 fs-12px py-1 px-2 float-end me-2">Save <i className="mdi mdi-content-save-move-outline"></i></button>
                </label>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="title" className="fs-12px">Article Title</label>
                    <input type="hidden" value={recordId} />
                    <textarea className="form-control fs-12px" placeholder="Citation header" readOnly={true} value={title} onChange={(e) => setTitle(e.target.value)} cols="30" rows="5"></textarea>
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="keywords" className="fs-12px">Keywords</label>
                    <input className="form-control fs-12px" placeholder="Keywords" type="text" value={keywords} onChange={(e) => setKeywords(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="doi_link" className="fs-12px">DOI Link</label>
                    <input readOnly={true} className="form-control fs-12px" placeholder="Citation DOI Link" type="text" value={link} onChange={(e) => setLink(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="publication_date" className="fs-12px">Publication Date</label>
                    <input className="form-control fs-12px" placeholder="Publication Date" type="date" readOnly={true} value={publicationDate} onChange={(e) => setpublicationDate(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="publication_year" className="fs-12px">Publication Year</label>
                    <input className="form-control fs-12px" placeholder="Publication Year" type="text" readOnly={true} value={publicationYear} onChange={(e) => setpublicationYear(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="cited_by_count" className="fs-12px">Cited by Count</label>
                    <input className="form-control fs-12px" placeholder="100" type="text" value={citedByCount} onChange={(e) => setCitedByCount(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="organization_name" className="fs-12px">Source Organization Name</label>
                    <input className="form-control fs-12px" placeholder="Source Organization Name" type="text" value={sourceOrganizationName} onChange={(e) => setSourceOrganizationName(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="source_name" className="fs-12px">Source Name</label>
                    <input className="form-control fs-12px" placeholder="Source Name" type="text" value={sourceName} onChange={(e) => setSourceName(e.target.value)}  />
                </div>
                <div className="col-lg-12 mb-3">
                    <label htmlFor="source_type" className="fs-12px">Source Type</label>
                    <input className="form-control fs-12px" placeholder="Source Type" type="text" value={sourceType} onChange={(e) => setSourceType(e.target.value)}  />
                </div>
                <label htmlFor="biblio" className="fw-medium mb-2 fs-12px">Biblio</label>
                <div className="col-lg-6 mb-3">
                    <label htmlFor="volume_no" className="fs-12px">Volume No.</label>
                    <input className="form-control fs-12px" placeholder="Volume No." type="number" value={volumeNo} onChange={(e) => setVolumeNo(e.target.value)}  />
                </div>
                <div className="col-lg-6 mb-3">
                    <label htmlFor="issue_no" className="fs-12px">Issue No.</label>
                    <input className="form-control fs-12px" placeholder="Issue No." type="number" value={issueNo} onChange={(e) => setIssueNo(e.target.value)}  />
                </div>
                <div className="col-lg-6 mb-3">
                    <label htmlFor="first_page" className="fs-12px">First Page</label>
                    <input className="form-control fs-12px" placeholder="First Page" type="number" value={firstPage} onChange={(e) => setFirstPage(e.target.value)}  />
                </div>
                <div className="col-lg-6 mb-3">
                    <label htmlFor="last_page" className="fs-12px">Last Page</label>
                    <input className="form-control fs-12px" placeholder="Page End" type="number" value={lastPage} onChange={(e) => setLastPage(e.target.value)}  />
                </div>
                {authors && authors.length > 0 && 
                <>
                    <label htmlFor="biblio" className="fw-medium mb-2 fs-12px">Authors Details</label>
                    {authors.map((author, index) => (
                        <div key={index} className="col-lg-12 mb-3">
                            <div className="card mb-2 p-2">
                                <label htmlFor={`author_name${index}`} className="fs-12px mb-1">Name - <span className="text-capitalize">{author.author_position}</span></label>
                                <input className="form-control mb-2 fs-12px" placeholder="Name" readOnly={true} type="text" value={author.raw_author_name}  />
                                <label htmlFor={`author_affiliation${index}`} className="fs-12px mb-1">Affiliation</label>
                                <input className="form-control mb-2 fs-12px" placeholder="Affiliation" readOnly={true} type="text" value={author.raw_affiliation_string}  />
                            </div>
                        </div>
                    ))}
                </>
                }
            </div>
        </div>
    )
}