import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { getPassiveVoice } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, highlightTextBlast } from "../../services/highlightContent";
import NLPService from "../../services/nlpService";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";

export default function WeakVerbs() {
  const [
    docId,
    report,
    setReport,
    quill,
    setQuill,
    userData,
    setUserData,
    propsRouter,
    setAvailableTokens,
    loader,
    handleLoaderOff,
    checkPage,
    setCheckPage, 
    setAiResultOutput, 
    setShowAIResultPopup
  ] = useOutletContext();

  useEffect(() => {
    setReport({});
    setCheckPage('weak verb');
    setAiResultOutput([]);
    setShowAIResultPopup(false);
    if (quill && quill.getLength() > 3) {
      loadWeakVerbs();
    }
  }, [propsRouter]);

  const loadWeakVerbs = async () => {
    handleLoaderOff(true);
    setTimeout(async () => {
      try {
        if (userData.packages[0] && userData.user) {
            const quillContent = await sanitizeResetQuillAndGetContent(quill);
            let nlpService = new NLPService("");
            nlpService.setContent(quillContent);
            let responseVerbs = await nlpService.getTagReport();
            responseVerbs = responseVerbs.filter((res) => (res.wordTag == 'VERB' && ((res.word.indexOf('ed') > 0) && ((res.word.indexOf('ed') + 2) == res.word.length) || (res.word.indexOf('d') > 0) && ((res.word.indexOf('d') + 1) == res.word.length) || (res.word.indexOf('t') > 0) && ((res.word.indexOf('t') + 1) == res.word.length))));

            responseVerbs = _.map(_.groupBy(responseVerbs, "word"), (dataArr, word) => {
                return { word: word, wordsCount: dataArr.length, dataArr: dataArr };
            });

            setReport({
                lastReportIndexes: {
                    start: 0,
                    end: quill.getLength(),
                },
                data: {
                    keywordsData: responseVerbs,
                },
            });

          handleLoaderOff(false);
        } else {
          handleLoaderOff(false);
          generateNotification({
              title: "Account Expired!",
              text: "Please check your account for the payment, This report is currently not available.",
              icon: "error",
          });
        }
      } catch (error) {
        handleLoaderOff(false);
        generateNotification({
            title: "Network Error!",
            text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
            icon: "error",
        });
      }
    });
  };

  let keywordsData = [],
    unnecessary = [];
  if (report?.data) {
    keywordsData = report.data.keywordsData || [];
    unnecessary = report.data.unnecessary || [];
  }
  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
          <span>Weak Verbs</span>
          <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/weak-verbs/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
          <span className="ms-auto">
              <button onClick={loadWeakVerbs} className="btn theme-btn4 fs-12px">Refresh</button>
          </span>
        </div>
      </div>
      <div className="result_card_cover text-start">
        <div className="reportContainer mt-0">
          <div className="reportContainer">
            {!loader && keywordsData.length == 0 && 
                <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
            }
            {loader && keywordsData.length == 0 && 
                <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
            }
            {keywordsData && keywordsData.map((v, i) => (
                <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} >
                    <div className="card-body py-2">
                        <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                        <div className="d-flex align-items-center">
                        <div>{v.word}</div>
                            <div className="ms-auto border p-2 rounded"><span className="count">{v.wordsCount}</span></div>
                        </div>
                    </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
