import React from "react";
// import buttonStyle from "./Button.module.css";

export default function Button1({ children, onClick, className }) {
  return (
    <button className={"btn theme-btn3 fs-12px " + className} onClick={onClick}>
      {children}
    </button>
  );
}
