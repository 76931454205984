import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { getPOVVerbs } from "../../services/reportServices";
import {
  sanitizeResetQuillAndGetContent,
  highlightTextBlast,
} from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";

export default function PovVerbs() {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('pov verb');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadPovVerbs();
        }
    }, [propsRouter]);

    const loadPovVerbs = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    const quillContent = await sanitizeResetQuillAndGetContent(quill);

                    let newUserData = { id: userData.user.id, email: userData.user.email };
                    let response = await getPOVVerbs(quillContent, newUserData);

                    let povWordData = [];
                    let povWordReport = [];
                    povWordData["FirstPerson"] = response[0].data.data;
                    povWordData["SecondPerson"] = response[1].data.data;
                    povWordData["ThirdPerson"] = response[2].data.data;

                    var total = povWordData["FirstPerson"].length + povWordData["SecondPerson"].length + povWordData["ThirdPerson"].length;
                    var first = parseInt((povWordData["FirstPerson"].length * 100)/total);
                    var second = parseInt((povWordData["SecondPerson"].length * 100)/total);
                    var third = parseInt((povWordData["ThirdPerson"].length * 100)/total);

                    let categories = ["FirstPerson", "SecondPerson", "ThirdPerson"];

                    categories.forEach((category) => {
                        povWordReport[category] = _.map(_.groupBy(povWordData[category], "word"), (adverbArr, word) => {
                            return { word: word, type: category, wordsCount: adverbArr.length, dataArr: adverbArr };
                        });
                    });

                    setReport({
                        lastReportIndexes: {
                            start: 0,
                            end: quill.getLength(),
                        },
                        data: {
                            firstPersonReport: povWordReport["FirstPerson"],
                            secondPersonReport: povWordReport["SecondPerson"],
                            thirdPersonReport: povWordReport["ThirdPerson"],
                            firstCount: first,
                            secondCount: second,
                            thirdCount: third,
                        },
                    });

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        });
    };

    let firstPersonReport = [];
    let secondPersonReport = [];
    let thirdPersonReport = [];
    let firstCount = [];
    let secondCount = [];
    let thirdCount = [];

    if (report?.data) {
        firstPersonReport = report.data.firstPersonReport || [];
        secondPersonReport = report.data.secondPersonReport || [];
        thirdPersonReport = report.data.thirdPersonReport || [];
        firstCount = report.data.firstCount || 0;
        secondCount = report.data.secondCount || 0;
        thirdCount = report.data.thirdCount || 0;
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>POV Verbs</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/pov-consistency-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadPovVerbs} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer">
                    {!loader && firstPersonReport.length == 0 && secondPersonReport.length == 0 && thirdPersonReport.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {loader && firstPersonReport.length == 0 && secondPersonReport.length == 0 && thirdPersonReport.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    {firstCount && secondCount && thirdCount && 
                        <div className="card mb-3 custom-card1">
                            <div className="card-body">
                                <label className="fs-12px fw-medium" htmlFor="firstCount">First Person<span className="ms-2">{firstCount}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar info-bar" role="progressbar" style={{ width: `${firstCount}px` }} aria-valuenow={`${firstCount}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <label className="fs-12px fw-medium" htmlFor="secondCount">Second Person<span className="ms-2">{secondCount}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar info-bar" role="progressbar" style={{ width: `${secondCount}px` }} aria-valuenow={`${secondCount}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <label className="fs-12px fw-medium" htmlFor="thirdCount">Third Person<span className="ms-2">{thirdCount}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar info-bar" role="progressbar" style={{ width: `${thirdCount}px` }} aria-valuenow={`${thirdCount}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    }
                    {firstPersonReport && firstPersonReport.map((v, i) => (
                        <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)}>
                            <div className="card-body">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.word}<br /><small>First Person</small></div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.wordsCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {secondPersonReport && secondPersonReport.map((v, i) => (
                        <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)}>
                            <div className="card-body">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.word}<br /><small>Second Person</small></div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.wordsCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {thirdPersonReport && thirdPersonReport.map((v, i) => (
                        <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)}>
                            <div className="card-body">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.word}<br /><small>Third Person</small></div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.wordsCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
