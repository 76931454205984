import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import SocialAuth from "./SocialAuth";
import logo from "./../../assets/logo.svg";
import supportImg from "./../../assets/manWithPc.png";
import Button3 from "../common/Buttons/Button3";
import { getData } from "../../services/dataService";
import { userRegister } from "../../services/authService";
import { generateNotification } from "../../services/notificationService";

const SignUp = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [accountType, setAccountType] = useState("");
  const [destination, setDestination] = useState("");
  const [loader, setLoader] = useState(false);

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    name: "",
    mobileNo: "",
    password: "",
    accountType: "",
  });

  useEffect(() => {
    const token = getData("token");
    setLoader(false);
    if (token != undefined || token != null) {
      navigate("/");
    } else {
      const search = new URLSearchParams(window.location.search);
      const destination = search.get("destination");
      if (destination) {
        setDestination(destination);
      } else {
        var emailGet = search.get("email") || '';
        var nameGet = search.get("name") || '';
        var passwordGet = search.get("password") || '';
        var typeGet = search.get("type") || '';
        setName(nameGet);
        setEmail(emailGet);
        setPassword(passwordGet);
        setCPassword(passwordGet);
        setAccountType(typeGet)
      }
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    const errors = validateCredentials(email, name, mobileNo, password, cpassword, accountType);
    if (errors) return setErrors(errors);
    setErrors({ username: "", email: "", name: "", mobileNo: "", password: "", cpassword: "", accountType: "" });
    userRegister({email, name, mobileNo, password, cpassword, accountType}).then((response) => {
      generateNotification({ title: "Activation Link Shared", text: "Account activation link has been sent to your email id, Please verify your account to continue.", icon: "success" });
      setLoader(false);
      navigate("/login" );
    }).catch((error) => {
      setLoader(false);
      setErrors(error.response.data.error);
    });
  };

  const validateCredentials = (
    email,
    name,
    mobileNo,
    password,
    cpassword,
    accountType
  ) => {
    const schema = Joi.object({
      email: Joi.string()
        .email({
          minDomainSegments: 2,
          // tlds: { allow: ["com", "net", "in", "ca", "ai"] },
          tlds: { allow: false },
        })
        .min(3)
        .required(),
      name: Joi.string().min(3).max(30).required().label("Full Name"),
      mobileNo: Joi.string().min(3).max(30).required().label("Mobile No"),
      password: Joi.string()
        .min(3)
        .max(30)
        .regex(new RegExp(`[^\x00-\x7F]`), { invert: true })
        .required(),
      cpassword: Joi.string().required().valid(Joi.ref("password")),
      accountType: Joi.string().min(3).max(30).required().label("Occupation"),
    });
    const { error } = schema.validate(
      { email, name, mobileNo, password, cpassword, accountType },
      { abortEarly: false }
    );
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  return (
    <div className="row" style={{ minHeight: "500px", height: "100vh" }}>
      <div className="col-lg-6 p-5 text-start position-relative">
        <Link to="/login">
          <img src={logo} className="img-fluid" alt="" />
        </Link>

        <h1 className="login_title mt-5 fs-2 ">Sign Up</h1>
        <p>Engage your readers with great writing.</p>
        <img
          className="d-lg-block d-none position-absolute bottom-0 start-0 end-0 mx-auto mb-4"
          src={supportImg}
          alt="manuscripts.ai boy with laptop"
          style={{ maxWidth: "80%" }}
        />
      </div>
      <div className="col-lg-6 p-5 form-container">
        <div className="form-container-inner">
          <form className="" onSubmit={handleSubmit}>
            <div className="row text-start">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fs-12px fw-medium mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Name"
                    id="name"
                    aria-describedby="nameHelp"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {errors?.name && (
                    <div
                      id="nameHelp"
                      className="form-text text-danger fs-12px"
                    >
                      {errors?.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fs-12px fw-medium mb-1">
                    Email Id
                  </label>
                  <input
                    type="email"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Email Id"
                    id="email"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors?.email && (
                    <div
                      id="emailHelp"
                      className="form-text text-danger d-block"
                    >
                      {errors?.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fs-12px fw-medium mb-1">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Mobile Number"
                    id="mobileNo"
                    aria-describedby="mobileNoHelp"
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                  {errors?.mobileNo && (
                    <div
                      id="mobileNoHelp"
                      className="form-text text-danger fs-12px"
                    >
                      {errors?.mobileNo}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fs-12px fw-medium mb-1">
                    Select Occupation
                  </label>
                  <select
                    className="form-control rounded-0 fs-12px mb-0"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option value="0">Select Occupation</option>
                    <option value="Author">Author </option>
                    <option value="Student">Student</option>
                  </select>
                  {errors?.accountType && (
                    <div
                      id="accountTypeHelp"
                      className="form-text text-danger fs-12px"
                    >
                      {errors?.accountType}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fs-12px fw-medium mb-1">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Password"
                    id="password"
                    value={password}
                    aria-describedby="passwordHelp"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors?.password && (
                    <div
                      id="passwordHelp"
                      className="form-text text-danger fs-12px"
                    >
                      {errors?.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fs-12px fw-medium mb-1">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Confirm Password"
                    id="cpassword"
                    aria-describedby="cpasswordHelp"
                    value={cpassword}
                    onChange={(e) => setCPassword(e.target.value)}
                  />

                  {errors?.cpassword && (
                    <div
                      id="cpasswordHelp"
                      className="form-text text-danger fs-12px"
                    >
                      {errors?.cpassword}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Button3 className="fs-14px">
              {loader && 
                <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
              }
              <span className="me-1">Sign Up</span>
            </Button3>
          </form>
          <p className="my-4 fs-12px fw-medium">
            Already have an account you can{" "}
            <Link to="/login" className="theme-link fs-14px">
              Log In
            </Link>
          </p>
          <p className="my-3 fs-12px fw-medium">or continue with</p>
          <SocialAuth page="Signup" destination={destination} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
