export default function Select({ error, parameter, value, onChangeFunc, dataList, label, placeholder }) {
    return (
        <div className="form-floating mb-3">
            <select className={`form-select fs-12px fw-normal lh-base ${(error[parameter] && error[parameter] != "") ? 'is-invalid' : ''}`} value={value} onChange={(e)=> onChangeFunc(e.target.value)}>
                <option value="">{placeholder}</option>
                {dataList && dataList.map((item, index) => (
                    <option key={index} value={item.key}>{item.value}</option>
                ))}
            </select>
            <label className="fs-12px">{label}</label>
        </div>
    )
}