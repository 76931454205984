import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getShowTellData, getStylometry } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent } from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";
import { getBooks, getBookTags, getGenres } from "../../services/wordsServices";
import { contractionFlag } from "wink-nlp/src/its";

export default function Stylometry() {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('stylometry');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        handleLoaderOff(false);
        getGenres()
        .then(httpResponse => {
            if(httpResponse.status === 200) {
                setGenres(httpResponse.data.result.genres);
            }
        }).catch(error => {
            // 
        })
        if (quill && quill.getLength() > 3) {
            // 
        }
    }, [propsRouter]);

    const [books, setBooks] = useState([]);
    const [genres, setGenres] = useState([]);
    const [book, setBook] = useState(0);
    const [genre, setGenre] = useState(0);
    const [bookTags, setBookTags] = useState([]);

    const getBooksList = (genreId) => {
        setGenre(genreId);
        setBook(0);
        getBooks({ genreId })
        .then(httpResponse => {
            if(httpResponse.status === 200) {
                setBooks(httpResponse.data.result.books);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const getBookData = (e) => {
        e.preventDefault();
        handleLoaderOff(true);
        if (book > 0 && genre > 0) {
            getBookTags(book).then(httpSuccess => {
                if(httpSuccess.status === 200) {
                    let bookTags = httpSuccess.data.result.bookTags;
                    bookTags = bookTags.find((bt) => bt.tag_name === "Stylomentry");
                    bookTags = JSON.parse(bookTags["tag_value"]).metrics;
                    setBookTags(bookTags);
                    loadStylometry();
                }
            }).catch(error => {
                handleLoaderOff(false);
                generateNotification({
                    title: "Process Exit!",
                    text: "Process could not complete due technical reason, Unable to fetch book details.",
                    icon: "error",
                });
            })
        } else {
            handleLoaderOff(false);
            generateNotification({
                title: "Details Missing!",
                text: "Please provide selection for Genre and Book you are willing to compare.",
                icon: "error",
            });
        }
    }

    const loadStylometry = async () => {
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    const quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let response = await getStylometry(quillContent);

                    response = response.data.response.metrics;

                    setReport({
                        lastReportIndexes: {
                            start: 0,
                            end: quill.getLength(),
                        },
                        data: {
                            keywordsData: response,
                        },
                    });

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Unable to process content, Please try again later.",
                    icon: "error",
                });
            }
        });
    };

    let keywordsData = [];
    let categories = [
        { 'id': 'average_nps', 'name': 'average nps' }, 
        { 'id': 'avg_dependency_distance', 'name': 'avg dependency distance' }, 
        { 'id': 'avg_sentence_length_chars', 'name': 'avg sentence length chars' }, 
        { 'id': 'avg_sentence_length_word', 'name': 'avg sentence length word' }, 
        { 'id': 'avg_syllables_per_word', 'name': 'avg syllables per word' }, 
        { 'id': 'function_words', 'name': 'function words' }, 
        { 'id': 'hapax_legomenon', 'name': 'hapax legomenon' }, 
        { 'id': 'noun_to_adj', 'name': 'noun to adj' }, 
        { 'id': 'noun_to_verb', 'name': 'noun to verb' }, 
        { 'id': 'punctuation_sentence', 'name': 'punctuation sentence' }, 
        { 'id': 'shannon_entropy', 'name': 'shannon entropy' }, 
        { 'id': 'simpsons_d', 'name': 'simpsons d' }, 
        { 'id': 'sttr', 'name': 'sttr' }, 
        { 'id': 'verb_to_adv', 'name': 'verb to adv' }, 
        { 'id': 'yules_k', 'name': 'yules k' }, 
    ];

    if (report?.data) {
        keywordsData = report.data.keywordsData || [];
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Stylometry</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/stylomentry-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={getBookData} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer">
                    {!loader && keywordsData.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {loader && keywordsData.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    {genres && genres.length > 0 && 
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="mb-2">
                                    <label className="form-label fs-12px" htmlFor="genre">Comparison Genre Option</label>
                                    <select className="form-control fs-12px" value={genre} onChange={(e) => getBooksList(e.target.value)}>
                                        <option value="0">Select Genre</option>
                                        {genres && genres.map((res) => (
                                            <option key={res.id} value={res.id}>{res.genre}</option>
                                        ))}
                                    </select>
                                </div>
                                {books && books.length > 0 && 
                                    <>
                                        <div className="mb-3">
                                            <label className="form-label fs-12px" htmlFor="genre">Comparison Book Option</label>
                                            <select className="form-control fs-12px" value={book} onChange={(e) => setBook(e.target.value)}>
                                                <option value="0">Select Book</option>
                                                {books && books.map((res) => (
                                                    <option key={res.book_id} value={res.book_id}>{res.book_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <span className="btn theme-btn3 fs-10px" onClick={(e) => getBookData(e)}>Start Comparison</span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {keywordsData.length != 0 && categories.map((category, index) => (
                        <div className="card mb-3 custom-card1" key={index}>
                            {}
                            <div className="card-body">
                                <p className="fs-14px fw-medium mb-1 text-uppercase" htmlFor="showTellRation">{category.name}</p>
                                <label className="fs-12px" htmlFor="showTellRation">Book Content<span className="ms-2">{(bookTags[category.id]).toFixed(2)}</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar success-bar" role="progressbar" style={{ width: `${_.round(bookTags[category.id] * 100 / (bookTags[category.id] + keywordsData[category.id]))}%` }} aria-valuenow={`${_.round(bookTags[category.id] * 100 / (bookTags[category.id] + keywordsData[category.id]))}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <label className="fs-12px" htmlFor="showTellRation">Your Content<span className="ms-2">{(keywordsData[category.id]).toFixed(2)}</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar warning-bar" role="progressbar" style={{ width: `${_.round(keywordsData[category.id] * 100 / (bookTags[category.id] + keywordsData[category.id]))}%` }} aria-valuenow={`${_.round(keywordsData[category.id] * 100 / (bookTags[category.id] + keywordsData[category.id]))}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* {showing.length >= 0 && telling.length >= 0 && 
                        <div className="card mb-3 custom-card1">
                            <div className="card-body">
                                <p className="fs-14px fw-medium mb-1" htmlFor="showTellRation">Show vs Tell Ratio</p>
                                <label className="fs-12px" htmlFor="showTellRation">Show<span className="ms-2">{showingPer}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar success-bar" role="progressbar" style={{ width: `${showingPer}%` }} aria-valuenow={`${showingPer}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <label className="fs-12px" htmlFor="showTellRation">Tell<span className="ms-2">{tellingPer}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar warning-bar" role="progressbar" style={{ width: `${tellingPer}%` }} aria-valuenow={`${tellingPer}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    }
                    {keywordsData && keywordsData.map((v, i) => (
                        <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 sentence-${_.kebabCase(v.sentence)}`} onClick={(e) => highlightTextByIndex(e, `sentence-${_.kebabCase(v.sentence)}`)}>
                            <div className="card-body">
                                <input className="form-check-input" id={`sentence-${_.kebabCase(v.sentence)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.sentence && _.truncate(v.sentence, { lenght: 100 })}</div>
                                </div>
                            </div>
                        </div>
                    ))} */}
                </div>
            </div>
        </div>
    )
}
