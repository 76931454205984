import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Card2 from "../common/Cards/Card2";
import Card3 from "../common/Cards/Card3";
import {
  getAddedTokens,
  getReportUsage,
  getTokenCount,
  updateTokenUsages,
} from "../../services/reportServices";
import { apiRouts } from "../../utils/routes";
import { getData } from "../../services/dataService";
import {
  quillContentInsertBlotByContentIndex,
  sanitizeResetQuillAndGetContentByRange,
  sanitizeResetQuillAndGetContent,
} from "../../services/highlightContent";
import _ from "lodash";
import Button1 from "../common/Buttons/Button1";
import { SSE } from "sse.js";

export default function WriteAnything() {
  const [ docId, report, setReport, quill, setQuill, userData, setUserData, propsRouter, setAvailableTokens, loader, handleLoaderOff, checkPage, setCheckPage ] = useOutletContext();
  const [errMsg, setErrMsg] = useState({});
  const [rpLoader, setRpLoader] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState([]);
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionLength, setSelectionLength] = useState(0);
  const [reportOption, setReportOption] = useState('');
  const [reportPrompt, setReportPrompt] = useState('');

  let book_id = 0;
  
  useEffect(() => {
      setReport({});
      setErrMsg({});
      setCheckPage('analysis');
      if (quill && quill.getLength() > 3) {
        let queryParams = new URLSearchParams(propsRouter.location.search);
        let source = new URLSearchParams(propsRouter.location.search).get("src");
        book_id = new URLSearchParams(propsRouter.location.search).get("doc");

        if (source == "selection") {
          let start = parseInt(queryParams.get("s"));
          let length = parseInt(queryParams.get("l"));
          let type = queryParams.get("type");

          if (start >= 0 && length >= 0 && quill) {
            // loadWriteAnything(start, length, type);
            setSelectionStart(start);
            setSelectionLength(length);
          } else {
            setErrMsg({ type: "warning", title: "Missing selection!", message: "Please write content to proceed with this report. Provide atleast 100 words to continue." });
          }
        } else {
          setErrMsg({ type: "warning", title: "Invalid Access", message: "Selection is not provided please select content and Re-run the report." });
        }
      } else {
        setErrMsg({ type: "warning", title: "Missing selection!", message: "Please write content to proceed with this report. Provide atleast 100 words to continue." });
      }
  }, [propsRouter]);

  const loadWriteAnything = async (start, length, type) => {
    setRpLoader(true);
    await sanitizeResetQuillAndGetContent(quill);
    setTimeout(async () => {
      try {
        let usage = await getReportUsage();
        if (usage[0].data.result.statusCode == 200) {
          var usageLimit = usage[0].data.result.result;
          if (userData.packages[0] && userData.user) {
            if (start < 5000) {
              start = 0;
              length = 5000;
            } else {
              start = start - 5000;
              length = 5000;
            }

            const quillContent = await sanitizeResetQuillAndGetContentByRange(quill, start, length);

            if (quillContent.length > 4) {
              let input_token_data = await getTokenCount(quillContent);
              let input_token_count = input_token_data.data.result.length;
  
              if (input_token_count > 5000) {
                setRpLoader(false);
                setErrMsg({ type: "warning", title: "Limit exceed!", message: "Character limit exceeded. You can not select more than 5000 characters." });
              } else {
                let addOnTokens = await getAddedTokens(userData.user.id);
                addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 10000 + addOnTokens : 200000 + addOnTokens;
  
                let today = moment().format("YYYY-MM-DD H:mm:ss");
                const date_limit = moment(userData.packages[0].package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");

                if (date_limit < today && parseInt(userData.packages[0].amount) <= 100) {
                  setRpLoader(false);
                  setErrMsg({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens! <a target='_blank' href='https://manuscripts.thrivecart.com/purchase-tokens/'>Purchase Additional Tokens</a>" });
                } else if (parseInt(usageLimit) >= (10000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                    setRpLoader(false);
                    setErrMsg({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan limit of 10000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens! <a target='_blank' href='https://manuscripts.thrivecart.com/purchase-tokens/'>Purchase Additional Tokens</a>" });
                } else if (usageLimit < totalLimit) {
                  if (Number(input_token_count) + Number(usageLimit) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                      await handleSseWriteAnything(start, length, type, quillContent, input_token_count, usageLimit, totalLimit);
                  } else {
                    setRpLoader(false);
                    setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your Write Anything Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan." });
                  }
                } else {
                  setRpLoader(false);
                  setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your Write Anything Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                }
              }
            } else {
              setRpLoader(false);
              setErrMsg({ type: "warning", title: "Missing selection!", message: "Please write content to proceed with this report. Provide atleast 100 words to continue." });
            }
          } else {
            setRpLoader(false);
            setErrMsg({ type: "warning", title: "Session missing!", message: "You can login and try this report again!" });
          }
        } else {
          setRpLoader(false);
          setErrMsg({ type: "warning", title: "Permission denied!", message: "Write Anything service is not available for this account." });
        }
      } catch (error) {
        setRpLoader(false);
        setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
      }
    }, 1000);

  };

  const handleSseWriteAnything = async ( start, length, type, quillContent, charCount, usageLimit, totalLimit) => {
    try {
      if (!quillContent) {
        setRpLoader(false);
        setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
        return;
      }

      let aiOutput = [];
      let aiData = [];
      let nth = 0;

      const urlWithData = new URL(apiRouts.stream_write_anything_openai_v2);
      let input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        type: reportOption,
        n: userData.user.no_of_cards,
        temperature: userData.user.temperature,
        maxToken: userData.user.size,
        genre: userData.user.genre,
        prompt: reportPrompt,
        content: quillContent,
      }

      const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json" },
        payload: JSON.stringify(input_data),
      });

      source.onreadystatechange = async function (event) {
        if (event.readyState === source.OPEN) {
          setRpLoader(true);
        }
    
        if (event.readyState === source.CLOSED) {
          setRpLoader(false);
          source.close();
          aiData[nth] = {
            start: start,
            end: start + length,
            input: quillContent,
            type: type,
            output: aiOutput[nth],
          };

          let highlightData = [{ start: start, end: start + length, input: quillContent }];

          quillContentInsertBlotByContentIndex(quill, 0, highlightData, "start", "end", [{
            blotName: "span",
            id: `paraphrase-${start}-${start + length}`,
            classes: [`paraphrase`, `paraphrase-{input}`],
            classDynamicKeys: [ "", { replaceBy: "{input}", replaceToKey: "input" }],
          }]);

          {/* changes here for ai highlight and replace code */}
          setHighlightIndex({ 'start': start, 'lenght': length });

          let scrollTop = document.getElementById(`paraphrase-${start}-${start + length}`).offsetTop;
          document.querySelector("html").scrollTop = scrollTop - 100;

          let all_output = "", total_used_limit = 0;

          for (const data of aiData) {
            if (data.output && data.output != undefined) {
              all_output += data.output + "";
              let charCount = await updateTokenUsages("text-completion", book_id, "Rewrite: "+data.input, data.output);
              charCount = charCount ? charCount : 0;
              usageLimit = totalLimit - (Number(charCount) + Number(usageLimit)) > 0 ? totalLimit - (Number(charCount) + Number(usageLimit)) : 0;
              total_used_limit += charCount;
              setAvailableTokens(usageLimit);
            }
          }

          if (all_output.length < 3) {
            setErrMsg({ type: "warning", title: "Content Processed", message: "Could not generate content for the given selection or Looks like it is already valid content, Please try with some different Content." });
          }
        }
      };
    
      source.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data?.content) {
          {/* changes here for ai highlight and replace code */}
          if (nth != data?.nth) aiOutput[nth] = "";
          nth = data?.nth;
          aiOutput[nth] = ((aiOutput[nth] || '') + data?.content);

          aiData[nth] = {
            start: start,
            end: start + length,
            input: quillContent,
            type: type,
            output: aiOutput[nth],
          };

          setReport({
            lastReportIndexes: { start: start, end: start + length },
            data: { paraPhrasingReport: aiData },
          });
        }

        if (data?.partialUpdate) {
          console.log("sse partialUpdate", data?.partialUpdate);
        }
      };
    
      source.onerror = function (event) {
        source.close();
        setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please check the details provided for the report query are valid and try again." });
        setRpLoader(false);
      };
    } catch (error) {
      setRpLoader(false);
      setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
    }
  };

  let handleTextRewrite = async (replaceString, end, className) => {
    let get = document.getElementsByName('update_content');
    for(var i=0; i < get.length; i++) {
      if (get[i].id != `${className}-cb`) {
          get[i].checked = false
      }
    }

    if (!document.getElementById(`${className}-cb`).checked) {
      window.$(`#${className}-cb`).click();

      var string =  '\n\n' + replaceString.trim() + '\n\n';
      await quill.insertText(end, string, 'user');
      var start = Number(end);
      end = Number(end) + Number(string.length);
      let highlightData = [{ start: start, end: end, input: string }];
      quillContentInsertBlotByContentIndex(quill, 0, highlightData, "start", "end", [{
        blotName: "span",
        id: `paraphrase-${start}-${end}`,
        classes: [`ai_highlight`, `paraphrase-{input}`],
        classDynamicKeys: [ "", { replaceBy: "{input}", replaceToKey: "input" }],
      }]);
    }
  };

  const checkData = async (e) => {
    e.preventDefault();
    setReport({});
    setErrMsg({});
    await loadWriteAnything(selectionStart, selectionLength, reportOption);
  }

  let paraPhrasingReport = [];
  if (report?.data) {
    paraPhrasingReport = report.data.paraPhrasingReport || [];
  }

  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
          <span>Write Anything</span>
          <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/write-anything-selection-tools"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
        </div>
      </div>
      <div className="result_card_cover text-start">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-12 text-start mb-3">
                <p className="mb-2 fs-12px fw-medium">Custom Prompt</p>
                <textarea placeholder="add more intensity" cols="30" rows="3" className="form-control fs-12px" value={reportPrompt} onChange={(e) => setReportPrompt(e.target.value)}></textarea>
              </div>
              <div className="col-12 text-end mb-1">
                <button onClick={(e) => checkData(e)} className="btn theme-btn3 fs-12px">
                  {rpLoader && 
                    <div className="spinner-border mai-spinner" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }
                  Generate Content
                </button>
              </div>
            </div>
          </div>
        </div>
        {errMsg.hasOwnProperty("message") && (
          <Card2 title={errMsg.title} description={errMsg.message}></Card2>
        )}
        {loader && !errMsg.hasOwnProperty("message") && (
          <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate generate complete report."></Card2>
        )}
        {paraPhrasingReport && (
          <div className="reportContainer">
            {paraPhrasingReport.map((v, i) => (
              <div key={i} className={`reportcard-1 shadow-sm paraphrase-${_.kebabCase(v.input)}`}>
                <input className="form-check-input" name="update_content" id={`paraphrase-${_.kebabCase(v.input)}-${i}-cb`} type="checkbox" />
                <Card3 title={v.type} description={v.output} index={i}>
                  <Button1 onClick={(e) => handleTextRewrite(`${v.output}`, `${v.end}`, `paraphrase-${_.kebabCase(v.input)}-${i}`)}>
                    <span className="mdi mdi-chevron-left me-2"></span>Insert Text
                  </Button1>
                </Card3>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
