import { Link } from "react-router-dom";

import TextReadabilityIcon from "../../assets/addi-report/text-readability.png"
import DialogueTagIcon from "../../assets/addi-report/dialogue-tag.png"
import SentenceIcon from "../../assets/addi-report/sentence.png"
import ShowTellIcon from "../../assets/addi-report/show-tell.png"
import StickyIcon from "../../assets/addi-report/sticky.png"
import RedundanciesIcon from "../../assets/addi-report/redundancies.png"
import SentimentIcon from "../../assets/addi-report/sentiment.png"
import PacingIcon from "../../assets/addi-report/reading-pace.png"

export default function AdditionalReportsPanel({ docId, report, summaryReportPopup, toggleSummaryReport }) {

    return (
        <div className="bg-white shadow-sm px-2 py-2" style={{ height: 'calc(100vh - 70px)', overflowY: 'scroll' }}>
            <ul className="d-flex flex-column list-unstyled right_add_menu">
                <li className={`border-bottom py-2 ${report}`}>
                    <span onClick={(e) => toggleSummaryReport(!summaryReportPopup)} className="text-decoration-none color-theme cursor-pointer">
                        <img className="img-fluid w-75 px-2 pb-1" src={SentimentIcon} alt="Summary Icon" />
                        <p className="fs-10px mb-0">Summary Report</p>
                    </span>
                </li>
                <li className={`border-bottom py-2 ${report == "text readability" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/text-readability?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={TextReadabilityIcon} alt="Text Readability Icon" />
                        <p className="fs-10px mb-0">Text Readability</p>
                    </Link>
                </li>
                <li className={`border-bottom py-2 ${report == "dialogue tags" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/dialogue-tag?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={DialogueTagIcon} alt="Dialogue Tag Icon" />
                        <p className="fs-10px mb-0">Dialogue Tags</p>
                    </Link>
                </li>
                <li className={`border-bottom py-2 ${report == "sentence variation" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/sentence-variation?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={SentenceIcon} alt="Sentence Variation Icon" />
                        <p className="fs-10px mb-0">Sentence Variation</p>
                    </Link>
                </li>
                <li className={`border-bottom py-2 ${report == "show and tell" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/showing-telling?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={ShowTellIcon} alt="Show Tell Icon" />
                        <p className="fs-10px mb-0">Show, Don't Tell</p>
                    </Link>
                </li>
                <li className={`border-bottom py-2 ${report == "sticky sentence" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/sticky-sentences?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={StickyIcon} alt="Sticky Icon" />
                        <p className="fs-10px mb-0">Sticky Sentence</p>
                    </Link>
                </li>
                <li className={`border-bottom py-2 ${report == "redundency" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/redundancies?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={RedundanciesIcon} alt="Redundancies Icon" />
                        <p className="fs-10px mb-0">Redundant Words</p>
                    </Link>
                </li>
                <li className={`py-2 ${report == "reading pace" ? 'active' : ''}`}>
                    <Link className="text-decoration-none color-theme" to={`/editor/reading-pace?doc=${docId}`}>
                        <img className="img-fluid w-75 px-2 pb-1" src={PacingIcon} alt="Pacing Icon" />
                        <p className="fs-10px mb-0">Pacing</p>
                    </Link>
                </li>
            </ul>
        </div>
    )
}