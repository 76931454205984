import axios from "axios";
import { apiRouts } from '../utils/routes';
import { getData } from "./dataService";

const options = {
    headers: { 
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Authorization': `${getData("token_type")} ${getData("token")}`,
    }
};

export const getWords = (type = "personal_word") => {
    return axios.get(apiRouts.user_words.replace("{type}", type), options);
}

export const getGenres = () => {
    return axios.get(apiRouts.genres, options);
}

export const getBooks = (filter) => {
    let url = new URL(apiRouts.books);
    if(filter?.genreId) {
        url.searchParams.append("genreId", filter?.genreId);
    }

    return axios.get(url.href, options);
}

export const getBookTags = (bookId) => {
    return axios.get(apiRouts.bookTags.replace("{bookId}", bookId), options);
}

export const getBookTagsByGenre = (genreId) => {
    return axios.get(apiRouts.bookTagsByGenre.replace("{genreId}", genreId), options);
}

export const deleteWord = (id) => {
    return axios.delete(apiRouts.user_delete_word.replace("{wordId}", id), options);
}

export const createWord = (word, type) => {
    return axios.post(apiRouts.user_create_word, { word, type }, options);
}