import axios from "axios";

import { apiRouts } from "./../utils/routes";
import { getData, resetData } from "./dataService";

const options = {
    headers: { 
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'authorization': `${getData("token_type")} ${getData("access_token")}`
    }
};

export const checkEmail = ({ email }) => {
    return axios.post(apiRouts.check_email, { email: email }, options);
    // return axios.post(apiRouts.check_email, { email: email }, {
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'Cache-Control': 'no-cache',
    //         'Pragma': 'no-cache',
    //         'Expires': '0',
    //         'authorization': `null null`
    //     }
    // });
  }

export const userLogin = ({ username, password }) => {
    return axios.post(apiRouts.user_login, { email: username, password: password }, options);
}

export const verifyEmail = ({ email, otp }) => {
    return axios.post(apiRouts.user_verifyEmail, { "email": email, "otp": otp }, options)
}

export const verifyAccount = ({ userId, verificationCode }) => {
    return axios.post(apiRouts.user_verifyAccount, { "userid": userId, "verification_code": verificationCode }, options)
}

export const userRegister = ({ email, name, mobileNo, password, cpassword, accountType }) => {
    return axios.post(apiRouts.user_register, { email: email, name: name, mobile: mobileNo, password: password, username: email, user_mode: accountType, user_type: accountType }, options);
}

export const forgotPassword = ({ email }) => {
    return axios.post(apiRouts.forgot_password, { email: email }, options);
}

export const updatePasswordChange = ({ email, otp, password }) => {
    return axios.post(apiRouts.update_password, { email: email, password: password, otp: otp }, options);
}

export const userRegisterCode = ({ email, name, mobile, password, username, product, amount, order_id }) => {
    return axios.post(apiRouts.user_register_code, { email: email, name: name, mobile: mobile, password: password, username: username, product: product, amount: amount, order_id: order_id }, options);
}

export const userRegisterGoogle = ({ email, name, username, img, social_id, account_type, user_type }) => {
    return axios.post(apiRouts.user_register_google, { email: email, name: name, username: username, img: img, social_id: social_id, account_type: account_type, user_mode: user_type, user_type: user_type }, options);
}

export const checkCode = ({ code }) => {
    return axios.post(apiRouts.check_code, {code: code});
}

export const logout = () => {
    resetData();
    window.localStorage.clear();
    window.location.href = "/login";
}

export const isLoggedIn = () => {
    if(getData("token_type") && getData("access_token")) return true;
    return false;
}