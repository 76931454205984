import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from "lodash";

export default function PowerWordsGraph({ graphData }) {

    var anger = graphData.anger.length ? graphData.anger.length : 0;
    var encourage = graphData.encourage.length ? graphData.encourage.length : 0;
    var energetic = graphData.energetic.length ? graphData.energetic.length : 0;
    var fear = graphData.fear.length ? graphData.fear.length : 0;
    var forbidden = graphData.forbidden.length ? graphData.forbidden.length : 0;
    var general = graphData.general.length ? graphData.general.length : 0;
    var greed = graphData.greed.length ? graphData.greed.length : 0;
    var lust = graphData.lust.length ? graphData.lust.length : 0;
    var safety = graphData.safety.length ? graphData.safety.length : 0;

    const options = {
        colors: ['#ee525380', '#10ac8480', '#ff9f4380', '#222f3e80', '#01a3a480', '#8395a780', '#feca5780', '#f368e080', '#2e86de80'],
        chart: { type: 'column' },
        title: { text: null },
        subtitle: { text: null },
        xAxis: {
            categories: [''],
            title: { text: null }
        },
        yAxis: {
            min: 0,
            title: { text: null, align: 'high' },
            labels: { overflow: 'justify', },
            visible: false,
        },
        tooltip: { valueSuffix: ' words' },
        credits: { enabled: false },
        series: [{
            name: 'Anger',
            data: [anger]
        },
        {
            name: 'Encourage',
            data: [encourage]
        },
        {
            name: 'Energetic',
            data: [energetic]
        },
        {
            name: 'Fear',
            data: [fear]
        },
        {
            name: 'Forbidden',
            data: [forbidden]
        },
        {
            name: 'General',
            data: [general]
        },
        {
            name: 'Greed',
            data: [greed]
        },
        {
            name: 'Lust',
            data: [lust]
        },
        {
            name: 'Safety',
            data: [safety]
        }]
    };

    return (
        <HighchartsReact
            highcharts={ Highcharts }
            options={ options }
        />
    );
}