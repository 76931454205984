import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SubscriptionSuccess = () => {
    const navigate = useNavigate();
	  useEffect(() => {
        setTimeout(() => {
            navigate(`/login`);
        }, 1000);
    }, []);

    return (
        <div className={`loaderContainer`}>
          <div id="sandyloader">
            <span className="loader"></span>
          </div>
        </div>
    );
}
 
export default SubscriptionSuccess;