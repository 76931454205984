export const conjunctionWords = [
    "for",
    "and",
    "nor",
    "but",
    "or",
    "yet",
    "so",
    "Both",
    "Either",
    "Neither",
    "nor",
    "No sooner",
    "than",
    "Not only",
    "but also",
    "Whether",
    "After",
    "As long as",
    "As soon as",
    "As though",
    "Before",
    "Even if",
    "If when",
    "Inasmuch",
    "Just as",
    "Now",
    "Now that",
    "Once",
    "Provided that",
    "Since",
    "Supposing",
    "That",
    "Though",
    "Until",
    "Whenever",
    "Whereas",
    "Wherever",
    "Which",
    "Who",
    "Although",
    "As if",
    "As much as",
    "Because",
    "Even though",
    "If only",
    "If then",
    "In order that",
    "Lest",
    "Now since",
    "Now when",
    "Provided",
    "Rather than",
    "So that",
    "As a result",
    "Consequently",
    "Finally",
    "For example",
    "Furthermore",
    "Hence",
    "However",
    "In addition",
    "In fact",
    "Incidentally",
    "Indeed",
    "Instead",
    "Likewise",
    "Meanwhile",
];