import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  getProfile,
  updateProfile,
  removePackage,
} from "../../services/userService";
import { handleHttpError } from "../../services/httpErrorService";
import { generateNotification } from "../../services/notificationService";
import { getGenres, getBooks } from "../../services/wordsServices";
import { logout } from "../../services/authService";
import Button1 from "../common/Buttons/Button1";

const Profile = () => {
  const [handleLoaderOff] = useOutletContext();

  const [userData, setUserData] = useState("");
  const [myPackage, setMyPackage] = useState([]);
  const [errors, setErrors] = useState({ name: "", password: "", phone: "" });
  const [img, setImg] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");

  const [genres, setGenres] = useState([]);
  const [books, setBooks] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedBook, setSelectedBook] = useState("");

  const [disableForm, setDisableForm] = useState(false);
  const [errMsgComment, setErrMsgComment] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    getProfile()
      .then((httpResponse) => {
        if (httpResponse.data.result.user) {
          setUserData(httpResponse.data.result.user);
          setMyPackage(httpResponse.data.result.packages);
          if (httpResponse.data.result.user) {
            setName(httpResponse.data.result.user.name);
            setMobile(httpResponse.data.result.user.mobile);
            setImg(httpResponse.data.result.user.img);

            if (
              httpResponse.data.result.user.genreId !== "" ||
              httpResponse.data.result.user.genreId != null
            ) {
              populateBooks(httpResponse.data.result.user.genreId);
            }
            if (
              httpResponse.data.result.user.bookId !== "" ||
              httpResponse.data.result.user.bookId != null
            ) {
              setSelectedBook(httpResponse.data.result.user.bookId);
            }
          }

          handleLoaderOff(false);
        }
      })
      .catch((httpResponse) => handleHttpError(httpResponse.response));

    getGenres().then((httpResponse) => {
      if (httpResponse.status === 200) {
        let genres = httpResponse.data.result.genres;
        setGenres(genres);
      }
    });
  }, []);

  const disablePackage = () => {
    if (!comment || comment.length < 5) {
      setErrMsgComment("Mandatory Field!");
      return;
    }

    removePackage(userData.email, myPackage[0].order_id, comment).then((res) => { 
      logout();
    });
  };

  const populateBooks = (genreId) => {
    setSelectedGenre(genreId);
    getBooks({
      genreId,
    }).then((httpResponse) => {
      if (httpResponse.status === 200) {
        let books = httpResponse.data.result.books;
        setBooks(books);
      }
    }).catch((error) => {
      console.log(error);
    })
  };

  const saveProfile = () => {
    updateProfile({
      name,
      password,
      mobile,
      genreId: selectedGenre,
      bookId: selectedBook,
    })
      .then((httpResponse) => {
        setErrors({
          name: "",
          password: "",
          mobile: "",
          bookId: "",
          genreId: "",
        });
        generateNotification({
          title: "Profile Updated",
          text: "",
          icon: "success",
        });
      })
      .catch((httpResponse) => handleSaveProfileError(httpResponse.response));
  };

  const handleSaveProfileError = (error) => {
    if (error && error.status === 400) {
      let { name, mobile, password, bookId, genreId } = error.data.error;
      setErrors({
        name: name != undefined ? name : "",
        password: password != undefined ? password : "",
        mobile: mobile != undefined ? mobile : "",
        bookId: bookId != undefined ? bookId : "",
        genreId: genreId != undefined ? genreId : "",
      });
    } else {
      handleHttpError(error);
    }
  };

  return (
    <div className="container-fluid profile_cover">
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col-md-12 text-center">
          <h2
            className="py-4 text-uppercase fw-normal"
            style={{ color: "#ef5d14" }}
          >
            Profile
          </h2>
        </div>
        {userData && (
          <div className="col-md-10 mx-auto px-4">
            <div className="row mb-3">
              <div className="col-lg-6 mx-auto text-center">
                <div className="card p-4 border-0 bg-none">
                  <div className="d-flex text-center m-auto align-items-center">
                    <div className="pro_pic">
                      {img && ( <img alt={`manuscripts.ai profile picture - ${name}`} src={img} className="rounded-circle w-100 h-100" /> )}
                      {!img && ( <img alt={`manuscripts.ai profile picture - ${name}`} src={`https://ui-avatars.com/api/?background=ef5d14&color=ffffff&name=${name}`} className="rounded-circle w-100 h-100" /> )}
                    </div>
                    <div className="ps-3 text-start">
                      <h5 className="card-title">{name}</h5>
                      <p className="card-text">
                        <small className="text-muted">
                          {myPackage && myPackage[0] && myPackage[myPackage.length - 1][ "display_name" ]} {" "}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-12 my-4">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="text-start fs-12px">
                      <label className="form-label">Email</label>
                      <input readOnly value={userData.email} placeholder="Email" type="email" className="form-control rounded-0 " id="email" aria-describedby="emailHelp" />
                    </div>
                    {errors?.email && (
                      <div
                        id="emailHelp"
                        className="form-text text-danger d-block"
                      >{errors?.email}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="text-start fs-12px">
                      <label className="form-label">Name</label>
                      <input value={name} placeholder="Name" type="text" className="form-control rounded-0 " id="name" aria-describedby="nameHelp" onChange={(e) => setName(e.target.value)} />
                    </div>
                    {errors?.name && (
                      <div id="nameHelp" className="form-text text-danger">{errors?.name}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="text-start fs-12px">
                      <label className="form-label">Mobile</label>
                      <input value={mobile} placeholder="Mobile Number" type="text" className="form-control rounded-0 " id="mobileNo" aria-describedby="mobileNoHelp" onChange={(e) => setMobile(e.target.value)} />
                    </div>
                    {errors?.mobileNo && (
                      <div id="mobileNoHelp" className="form-text text-danger">{errors?.mobileNo}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="text-start fs-12px">
                      <label className="form-label">Password</label>
                      <input placeholder="Password" type="password" className="form-control rounded-0" id="password" aria-describedby="passwordHelp" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <small className="mt-2 text-start" style={{ display: "inherit", fontSize: "11px" }}>
                      Keep Password Empty incase you dont want to change password.
                    </small>
                    {errors?.password && (
                      <div id="passwordHelp" className="form-text text-danger">{errors?.password}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="text-start fs-12px">
                      <label className="form-label">Select Genre</label>
                      <select value={selectedGenre} onChange={(e) => populateBooks(e.target.value)} className="form-control mb-0 rounded-0" >
                        <option value="">Select Genre</option>
                        {genres.map((genre) => {
                          return ( <option value={genre.id} key={genre.id}>{" "}{genre.genre}{" "}</option> );
                        })}
                      </select>
                    </div>
                    {errors?.genreId && (
                      <div id="genreIdHelp" className="form-text text-danger">{errors?.genreId}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="text-start fs-12px">
                      <label className="form-label">Select Book</label>
                      <select value={selectedBook} onChange={(e) => setSelectedBook(e.target.value)} className="form-control mb-0 rounded-0">
                        <option>Select Book</option>
                        {books.map((book) => {
                          return ( <option value={book.book_id} key={book.book_id}>{" "}{book.book_name}{" "}</option> );
                        })}
                      </select>
                    </div>
                    {errors?.bookId && (
                      <div id="bookIdHelp" className="form-text text-danger">{errors?.bookId}</div>
                    )}
                  </div>
                  <div className="col-md-12 mb-2 text-center me-0">
                    <Button1 onClick={(e) => saveProfile()}>Submit</Button1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-start">
                  <div className="card-body row">
                    <div className="col-md-6">
                      <h5 className="card-title fs-14px">Cancel Subscription</h5>
                      <p className="fs-10px">You can cancel your subscription here. Once cancel your subscription your account will be deactivated, You can further re-purchase plan to continue.</p>
                    </div>
                    <div className="col-md-6 text-end my-auto">
                      {disableForm &&
                        <>
                          <div className="form-floating mb-3">
                            <textarea className={`form-control fs-12px ${errMsgComment ? "is-invalid" : ""}`} placeholder="Leave a comment here" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                            <label className="fs-10px">Please provide your reasons for cancellation. {errMsgComment ? `- ${errMsgComment}` : ''}</label>
                          </div>
                          <button onClick={(e) => disablePackage()} className="btn theme-btn8 fs-10px fw-medium me-2">Cancel & Deactivate My Account</button>
                          <button onClick={(e) => setDisableForm(false)} className="btn theme-btn2 fs-10px fw-medium">Cancel</button>
                        </>
                      }
                      {!disableForm && 
                        <button onClick={(e) => setDisableForm(true)} className="btn theme-btn8 fs-10px fw-medium">Deactivate</button>
                      }
                    </div>
                    <div className="col-12">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
