export default function Button5 ({ children, text, onClick, className, loader }) {
    return (
        <button onClick={onClick} className={"btn fs-14px " + (className ? className : '')}>
            <span className="me-3">{text}</span>
            {!loader && children }
            {loader && 
                <div className="ms-2 spinner-border" style={{ width: "0.8rem", height: "0.8rem", borderWidth: "0.15em" }}>
                    <span className="sr-only">Loading...</span>
                </div>
            }
        </button>
    )
}