import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import logo from "./../../assets/logo.svg";
import supportImg from "./../../assets/manWithPc.png";
import Button3 from "../common/Buttons/Button3";
import { forgotPassword } from "../../services/authService";
import { handleHttpError } from "../../services/httpErrorService";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    otp: "",
    globalMessage: "",
  });

  const generateOtp = (e) => {
    e.preventDefault();
    const errors = validateCredentials(email);
    if (errors) return setErrors(errors);
    setErrors({ email: "", password: "" });
    forgotPassword({ email })
      .then((httpResponse) => {
        if (httpResponse.status == 200)
          navigate(`/update-password?email=${email}`);
      })
      .catch((httpResponse) => {
        let error = httpResponse.response;
        if (error.status == 400 || error.status == 500) {
          let { email, globalMessage } = error.data.error || {};
          setErrors({
            email: email != undefined ? email : "",
            globalMessage: globalMessage != undefined ? globalMessage : "",
          });
        }
        handleHttpError(error);
      });
  };

  const validateCredentials = (email) => {
    const schema = Joi.object({
      email: Joi.string().min(3).max(30).required(),
    });
    const { error } = schema.validate({ email: email }, { abortEarly: false });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  return (
    <div className="row" style={{ minHeight: "500px", height: "100vh" }}>
      <div className="col-md-6 p-5 text-start position-relative">
        <Link to="/">
          <img src={logo} className="img-fluid" alt="" />
        </Link>

        <h1 className="login_title mt-5 fs-2 ">Forgot Password</h1>
        <p>
          You can change your password for security reasons or reset it if you
          forget it.
        </p>
        <img
          className="d-md-block d-none position-absolute bottom-0 start-0 end-0 mx-auto mb-4"
          src={supportImg}
          alt="manuscripts.ai boy with laptop"
          style={{ maxWidth: "80%" }}
        />
      </div>
      <div className="col-md-6 p-5 form-container">
        <div className="form-container-inner">
          <form action="">
            <div className="row">
              <div className="col-12">
                <div className="mb-3 text-start">
                  <label className="form-label fs-12px mb-1 fw-medium">
                    Please Enter your Email Id
                  </label>
                  <input
                    type="email"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Email Id"
                    id="email"
                    aria-describedby="emailHelp"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors?.email && (
                    <div id="emailHelp" className="form-text text-danger">
                      {errors?.email}
                    </div>
                  )}
                </div>
                <Button3 onClick={(e) => generateOtp(e)} className="">
                  <span className="mdi mdi-email-outline me-2"></span>
                  Generate OTP
                </Button3>
              </div>
              <div className="col-12">
                <p className="my-4 fs-14px">
                  Already have an account you can
                  <Link to="/login" className="theme-link fs-14px">
                    &nbsp;Log In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
