import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { getTenseVerbs } from "../../services/reportServices";
import {
  sanitizeResetQuillAndGetContent,
  highlightTextBlast,
  quillContentInsertBlotByContentIndex,
  highlightTextByIndex,
} from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function TenseReport() {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('tense');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadTenseReport();
        }
    }, [propsRouter]);

    const loadTenseReport = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }

                    let newUserData = { id: userData.user.id, email: userData.user.email };
                    let response = await getTenseVerbs(quillContent, newUserData);

                    let pastTenseData = response[0].data.data;
                    let ingTenseData = response[1].data.data;
                    let tensePresentData = response[2].data.data;
                    let tensePastData = response[3].data.data;
                    let tenseFutureData = response[4];

                    setReport({
                        lastReportIndexes: {
                            start: 0,
                            end: quill.getLength(),
                        },
                        data: {
                            pastTenseData: _.map(_.groupBy(pastTenseData, "word"), (adverbArr, word) => { return {word: word, wordsCount: adverbArr.length, dataArr: adverbArr}; }),
                            ingTenseData: _.map(_.groupBy(ingTenseData, "word"), (adverbArr, word) => { return {word: word, wordsCount: adverbArr.length, dataArr: adverbArr}; }),
                            tensePresentData: _.map(_.groupBy(tensePresentData, "word"), (adverbArr, word) => { return {word: word, wordsCount: adverbArr.length, dataArr: adverbArr}; }),
                            tensePastData: _.map(_.groupBy(tensePastData, "word"), (adverbArr, word) => { return {word: word, wordsCount: adverbArr.length, dataArr: adverbArr}; }),
                            tenseFutureData: _.map(_.groupBy(tenseFutureData, "word"), (adverbArr, word) => { return {word: word, wordsCount: adverbArr.length, dataArr: adverbArr}; }),
                        },
                    });

                    tensePresentData = _.sortBy(tensePresentData, "stringEndIndex");
                    quillContentInsertBlotByContentIndex(quill, 0, tensePresentData, "stringStartIndex", "stringEndIndex", [{
                        blotName: "span", id: `tense-present-{index}`, classes: [`tense-present`, `tense-present-{word}`],
                        classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
                    }]);

                    tensePastData = _.sortBy(tensePastData, "stringEndIndex");
                    quillContentInsertBlotByContentIndex(quill, 0, tensePastData, "stringStartIndex", "stringEndIndex", [{
                        blotName: "span", id: `tense-past-{index}`, classes: [`tense-past`, `tense-past-{word}`],
                        classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
                    }]);

                    tenseFutureData = _.sortBy(tenseFutureData, "stringEndIndex", "desc");
                    quillContentInsertBlotByContentIndex(quill, 0, tenseFutureData, "stringStartIndex", "stringEndIndex", [{
                        blotName: "span", id: `tense-future-{index}`, classes: [`tense-future`, `tense-future-{word}`],
                        classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
                    }]);

                    ingTenseData = _.sortBy(ingTenseData, "stringEndIndex");
                    quillContentInsertBlotByContentIndex(quill, 0, ingTenseData, "stringStartIndex", "stringEndIndex", [{
                        blotName: "span", id: `tense-ingverb-{index}`, classes: [`tense-ingverb`, `tense-ingverb-{word}`],
                        classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
                    }]);

                    pastTenseData = _.sortBy(pastTenseData, "stringEndIndex");
                    quillContentInsertBlotByContentIndex(quill, 0, pastTenseData, "stringStartIndex", "stringEndIndex", [{
                        blotName: "span", id: `tense-pastverb-{index}`, classes: [`tense-pastverb`, `tense-pastverb-{word}`],
                        classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
                    }]);

                    window.$(".tense-pastverb, .tense-ingverb, .tense-present, .tense-past, .tense-future").removeClass('active');
                    window.$(".tense-present, .tense-past, .tense-future, .tense-pastverb, .tense-ingverb").addClass('active');

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        });
    };

    const loadPanel = async (e, panelId) => {
        e.preventDefault();
        handleLoaderOff(true);
        window.$(`.reportTabs li`).removeClass('show');
        window.$(`.reportTabsContainer > div`).addClass('hide');
        window.$(`.reportTabs li`).removeClass('show');
        window.$(`#${panelId}`).removeClass('hide');
        window.$(`#${panelId}-tab`).addClass('show');
        handleLoaderOff(false);
    }

    const toggelTask = async (e, clasName) => {
        if (window.$('#tense-'+clasName).prop('checked')) {
            e.target.innerText = 'Disable Highlight';
            window.$(".tense-"+clasName).addClass('active');
            window.$(".card-"+clasName).addClass('active');
        } else {
            e.target.innerText = 'Enable Highlight';
            window.$(".tense-"+clasName).removeClass('active');
            window.$(".card-"+clasName).removeClass('active');
        }
    }

    let pastTenseData = [];
    let ingTenseData = [];
    let tensePastData = [];
    let tensePresentData = [];
    let tenseFutureData = [];

    if (report?.data) {
        pastTenseData = report.data.pastTenseData || [];
        ingTenseData = report.data.ingTenseData || [];
        tensePastData = report.data.tensePastData || [];
        tensePresentData = report.data.tensePresentData || [];
        tenseFutureData = report.data.tenseFutureData || [];
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Tense Report</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/tense-consistency-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadTenseReport} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <ul className="reportTabs">
                    <li id="IngVerbs-tab" onClick={(e) => loadPanel(e, `IngVerbs`)}>
                        <small>Ing Verbs</small>
                    </li>
                    <li id="PastVerbs-tab" onClick={(e) => loadPanel(e, `PastVerbs`)}>
                        <small>Past Verbs</small>
                    </li>
                    <li id="TenseVerbs-tab" onClick={(e) => loadPanel(e, `TenseVerbs`)} className="show">
                        <small>Past / Present / Future Tense</small>
                    </li>
                </ul>
                <div className="reportContainer">
                    {!loader && pastTenseData.length == 0 && ingTenseData.length == 0 && tensePresentData.length == 0 && tensePastData.length == 0 && tenseFutureData.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {loader && pastTenseData.length == 0 && ingTenseData.length == 0 && tensePresentData.length == 0 && tensePastData.length == 0 && tenseFutureData.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    <div className="reportTabsContainer">
                        <div id="IngVerbs" className="hide">
                            <div>
                                <div className="form-check form-switch">
                                    <input defaultChecked={true} onChange={(e) => toggelTask(e, 'ingverb')} className="form-check-input" type="checkbox" id="tense-ingverb" />
                                    <label className="form-check-label fs-14px mb-2" htmlFor="ingCheckBox">Ing Verbs Highlight</label>
                                </div>
                            </div>
                            {ingTenseData && ingTenseData.map((v, i) => (
                                <div className={`active card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 card-ingverb tense-ingverb-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `tense-ingverb-${_.kebabCase(v.word)}`)} key={i} >
                                    <div className="card-body py-2">
                                        <input defaultChecked={true} className="form-check-input" id={`tense-ingverb-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>{v.word}</div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{v.wordsCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div id="PastVerbs" className="hide">
                            <div className="form-check form-switch">
                                <input defaultChecked={true} onChange={(e) => toggelTask(e, 'pastverb')} className="form-check-input" type="checkbox" id="tense-pastverb" />
                                <label className="form-check-label fs-14px mb-2" htmlFor="pastCheckBox">Past Verbs Highlight</label>
                            </div>
                            {pastTenseData && pastTenseData.map((v, i) => (
                                <div className={`active card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 card-pastverb tense-pastverb-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `tense-pastverb-${_.kebabCase(v.word)}`)} key={i} >
                                    <div className="card-body py-2">
                                        <input defaultChecked={true} className="form-check-input" id={`tense-pastverb-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>{v.word}</div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{v.wordsCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div id="TenseVerbs" className="show">
                            <div className="form-check form-switch">
                                <input defaultChecked={true} onChange={(e) => toggelTask(e, 'past')} className="form-check-input" type="checkbox" id="tense-past" />
                                <label className="form-check-label fs-14px mb-2" htmlFor="pastTenseCheckBox">Past Tense Highlight</label>
                            </div>
                            {tensePastData && tensePastData.map((v, i) => (
                                <div className={`active card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 card-past tense-past-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `tense-past-${_.kebabCase(v.word)}`)} key={i} >
                                    <div className="card-body py-2">
                                        <input defaultChecked={true} className="form-check-input" id={`tense-past-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>{v.word}</div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{v.wordsCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <hr />
                            <div className="form-check form-switch">
                                <input defaultChecked={true} onChange={(e) => toggelTask(e, 'present')} className="form-check-input" type="checkbox" id="tense-present" />
                                <label className="form-check-label fs-14px mb-2" htmlFor="presentTenseCheckBox">Present Tense Highlight</label>
                            </div>
                            {tensePresentData && tensePresentData.map((v, i) => (
                                <div className={`active card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 card-present tense-present-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `tense-present-${_.kebabCase(v.word)}`)} key={i} >
                                    <div className="card-body py-2">
                                        <input defaultChecked={true} className="form-check-input" id={`tense-present-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>{v.word}</div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{v.wordsCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <hr />
                            <div className="form-check form-switch">
                                <input defaultChecked={true} onChange={(e) => toggelTask(e, 'future')} className="form-check-input" type="checkbox" id="tense-future" />
                                <label className="form-check-label fs-14px mb-2" htmlFor="futureTenseCheckBox">Future Tense Highlight</label>
                            </div>
                            {tenseFutureData && tenseFutureData.map((v, i) => (
                                <div className={`active card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 card-future tense-future-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `tense-future-${_.kebabCase(v.word)}`)} key={i} >
                                    <div className="card-body py-2">
                                        <input defaultChecked={true} className="form-check-input" id={`tense-future-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                        <div className="d-flex align-items-center">
                                            <div>{v.word}</div>
                                            <div className="ms-auto border p-2 rounded">
                                                <span className="count">{v.wordsCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
