import _ from "lodash";
import Button1 from "./Buttons/Button1";

export default function CardAnalysisProgressBarRange2({ text, value, start, end, percent }) {
    const calculateDegLeft = (value) => {
        if (value > 0) {
            if (value <= 50) {
                return 0;
            } else {
                return ((value - 50) / 100) * 360;
            }
        }
    }

    const calculateDegRight = (value) => {
        if (value > 0) {
            if (value <= 50) {
                return value;
            } else {
                return 180;
            }
        }
    }

    return (
        <div className={`card fs-12px text-start custom-card1 mb-3 p-0 reportcard-1`}>
            <div className="card-body">
                <div className="d-flex">
                    <div className="fs-14px text-capitalize fw-normal">
                        <div className="d-flex flex-column justify-content-between h-100 pb-2 pt-1">
                            <div>
                                {text}        
                            </div>
                            <Button1 className="btn theme-btn3 fs-12px py-1 px-2">
                            <span className="mdi mdi-arrow-up me-2"></span>
                                Improve Score
                            </Button1>
                        </div>
                    </div>
                    <div className="ms-auto p-2 rounded">
                        <div className="circular_progressbar progress mx-auto" data-value={value}>
                            <span className="progress-left">
                                <span className={`progress-bar ${value >= end ? 'border-success' : (value < start ? 'border-danger' : 'border-warning' ) }`} style={{ transform: `rotate(${calculateDegLeft(value)}deg)` }}></span>
                            </span>
                            <span className="progress-right">
                                <span className={`progress-bar ${value >= end ? 'border-success' : (value < start ? 'border-danger' : 'border-warning' ) }`} style={{ transform: `rotate(${calculateDegRight(value)}deg)` }}></span>
                            </span>
                            <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                                <div className="h6 font-weight-bold mb-0">
                                    {value}
                                    {percent && <span className="small">%</span> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
