import React, { useEffect, useState } from "react";
import Button1 from "../Buttons/Button1";

export default function Card3({ title, description, index, children }) {
  const [cardHeight, setCardHeight] = useState(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  useEffect(() => {
    if (description.length > 150) {
      setCardHeight(true);
    }
  }, [description]);

  return (
    <div className={`card fs-12px custom-card3 p-3 pb-4 mb-2`}>
      <div className="card-body p-0">
        <div className="row">
          <div className="col-12">
            {title && (
              <p className="d-flex align-items-center">
                <span className="fw-medium fs-12px">{title}</span>
                <span className="ms-auto rounded text-center" style={{ width: "30px", height: "30px", lineHeight: "30px", border: "1px solid #E0E0E0" }}>{index + 1}</span>
              </p>
            )}
            <p className="fs-10px fw-medium">
              <span className="d-inline-block me-1 ai_highlight rounded" style={{ width: "10px", height: "10px" }}></span> AI Content
              <span className="d-inline-block me-1 paraphrase rounded ms-2" style={{ width: "10px", height: "10px" }}></span> Selected Content
            </p>
            <p className="white-space-collapse addMaxHeight fw-normal expand">{description}</p>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
