import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import { getWordsReportData } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, highlightTextBlast } from "../../services/highlightContent";
import Card2 from "../common/Cards/Card2";

export default function DialogueTags() {

    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup,
        handleBackActivity
    ] = useOutletContext();
    
    const [genre, setGenre] = useState(23);
    const [splashLoader, setSplashLoader] = useState(true);

    useEffect(() => {
        setReport({});
        setCheckPage('dialogue tags');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
          loadDialogueTags();
        }
    }, [propsRouter]);

    const loadDialogueTags = async () => {
        setSplashLoader(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    const quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let response = await getWordsReportData(quillContent, 'DialogueTag', userData.user, genre);

                    response = _.map(_.groupBy(response.data.data, "word"), (dataArr, word) => {
                        return { word: word, wordsCount: dataArr.length, dataArr: dataArr };
                    });

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            dialogueTags: response,
                        },
                    });
                    setSplashLoader(false);
                } else {
                    setSplashLoader(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                setSplashLoader(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        }, 1000);
    }

    let dialogueTags = [];
    if (report?.data) {
        dialogueTags = report.data.dialogueTags || [];
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <div className="fw-medium fs-14px">Dialogue Tag</div>
                    <div className="ms-auto">
                        <a data-title="How To?" className="text-dark fw-medium fs-5" target="_blank" href="https://manuscripts.ai/how-to/action-beat-report/"><span className="mdi mdi-information"></span></a>
                        <span data-title="Rerun Report" onClick={(e) => loadDialogueTags()} className="text-dark fw-medium fs-5 cursor-pointer"> <span className="mdi mdi-refresh-circle"></span> </span>
                        <Link data-title="Close Report" className="text-dark fw-medium fs-5" onClick={(e) => handleBackActivity(e)}><span className="mdi mdi-close-circle"></span></Link>
                    </div>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer me-2 mt-0">
                    {!splashLoader && dialogueTags.length == 0 && 
                        <Card2 title="Report Warning" description="Could not generate report for provided content. Please refresh the report or try with different content."></Card2>
                    }
                    {splashLoader && 
                        <>
                            <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                            <div className="card__animate mb-2"></div>
                            <div className="card__animate_mini mb-2"></div>
                            <div className="card__animate_mini mb-2"></div>
                        </>
                    }
                    {dialogueTags && dialogueTags.map((v, i) => (

                        <div className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 keyword-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextBlast(e, v.word, `keyword-${_.kebabCase(v.word)}`)} key={i} >
                            <div className="card-body py-2">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.word}</div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.wordsCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}