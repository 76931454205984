import _ from "lodash";
import blast from "blast-text";
import { getWordnetData } from "./reportServices";
import NLPService from "./nlpService";

const sanitizeHtml = require("sanitize-html");

export const mergeIndexConflicts = (
  quillStartIndex,
  contentIndexes,
  contentStartKeyName,
  contentEndKeyName
) => {
  let newIndexes = [];

  contentIndexes.forEach((contentIndex, key) => {
    if (
      key === 0 ||
      newIndexes[newIndexes.length - 1][contentEndKeyName] -
        newIndexes[newIndexes.length - 1][contentStartKeyName] <
        contentIndex[contentStartKeyName]
    )
      newIndexes.push({ ...contentIndex });
  });

  return newIndexes;
};

export const sanitizeText = (content) => {
  return new Promise((resolve, reject) => {
    try {
      let sanitizedText = sanitizeHtml(content, {
        allowedTags: [
          "a",
          "b",
          "img",
          "br",
          "i",
          "em",
          "strong",
          "a",
          "p",
          "h1",
          "h2",
          "h3",
          "div",
          "span",
          "blockquote",
          "u",
          "s",
          "ol",
          "li",
          "ul",
          "sub",
          "sup",
          "citationTag",
          "citationanchortag",
          "customtrtag",
        ],
        allowedAttributes: {
          a: ["href"],
          div: ["class"],
          span: ["class", "data"],
          table: ["class", "data"],
          tr: ["class", "data"],
          td: ["class", "data"],
          i: ["class", "data"],
          blockquote: ["class"],
          p: ["class"],
          strong: ["class"],
          h1: ["class"],
          h2: ["class"],
          img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
          citationTag: ["class", "data"],
          customtrtag: ["id", "class", "data"],
          citationanchortag: ["id", "class", "data"],
        },
        selfClosing: ['img'],
        allowedSchemesAppliedToAttributes: ['src'],
        allowedSchemes: [ 'data', 'http', 'https' ]
      });

      sanitizedText = sanitizedText.replace(/ +/gm, " ");
      sanitizedText = sanitizedText.replace(/\u034F/g, "");
      if (sanitizedText.split(String.fromCharCode(8201)).length > 1)
        sanitizedText = sanitizedText.replace(
          new RegExp(`${String.fromCharCode(8201)}`, "g"),
          ""
        );
      resolve(sanitizedText);
    } catch (err) {
      reject(err);
    }
  });
};

export const sanitizeResetQuillAndGetContentByRange = async (
  quill,
  start,
  length
) => {
  return new Promise((resolve, reject) => {
    try {
      let quillContent = quill.getText(start, length);
      quillContent = quillContent.replace(/&nbsp;/g, " ");
      quillContent = quillContent.replace(/<img[^>]*>/g, "");
      quillContent = quillContent.replace(/\r\n|\n/g, "\n");
      // quillContent = quillContent.replace(/\n/g, "\n");
      // quillContent = quillContent.replaceAll("<p><br></p>", "<br>");
      quillContent = quillContent.replaceAll("<br><br>", "<br>");
      quillContent = quillContent.replace(/<\/?span[^>]*>/g, "");
      // quillContent = quillContent.replaceAll("<", "");
      // quillContent = quillContent.replaceAll(">", "");

      sanitizeText(quillContent).then((data) => {
        resolve(data);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const sanitizeResetQuillAndGetContentByRangeToRewrite = async (
  quill,
  start,
  length
) => {
  return new Promise((resolve, reject) => {
    try {
      let quillContentArr = quill.getLines(start, length);
      let quillContent = "";
      for (const quillData of quillContentArr) {
        if (quillData.domNode.innerText.trim().length > 0) {
          quillContent += quillData.domNode.innerText;
        } else {
          quillContent += "\n";
        }
      }
      quillContent = quillContent.replace(/&nbsp;/g, " ");
      // quillContent = quillContent.replace(/<img[^>]*>/g, "");
      // quillContent = quillContent.replace(/\n/g, "\n");
      // quillContent = quillContent.replaceAll("<p><br></p>", "<br>");
      quillContent = quillContent.replaceAll("<br><br>", "<br>");
      quillContent = quillContent.replace(/<\/?span[^>]*>/g, "");
      // quillContent = quillContent.replaceAll("<", "");
      // quillContent = quillContent.replaceAll(">", "");

      sanitizeText(quillContent).then((data) => {
        resolve(data);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const sanitizeTextGetContent = async (text) => {
  return new Promise((resolve, reject) => {
    try {
      text = text.replace(/&nbsp;/g, " ");
      // text = text.replace(/<img[^>]*>/g, "");
      text = text.replace(/\n/g, "\n");
      text = text.replaceAll("<p><br></p>", "<br>");
      text = text.replaceAll("<br><br>", "<br>");
      // text = text.replaceAll("<", "");
      // text = text.replaceAll(">", "");

      resolve(text);
    } catch (error) {
      reject(error);
    }
  });
};

export const sanitizeQuillGetContent = async (quill) => {
  return new Promise((resolve, reject) => {
    try {
      let quillContent = document.querySelector(".ql-editor").innerHTML;
      quillContent = quillContent.replace(/&nbsp;/g, " ");
      // quillContent = quillContent.replace(/<img[^>]*>/g, "");
      quillContent = quillContent.replace(/\r\n|\n/g, "\n");
      // quillContent = quillContent.replace(/\n/g, "\n");
      // quillContent = quillContent.replaceAll("<p><br></p>", "<br>");
      quillContent = quillContent.replaceAll("<br><br>", "<br>");
      // quillContent = quillContent.replaceAll("<", "");
      // quillContent = quillContent.replaceAll(">", "");

      sanitizeText(quillContent).then((data) => {
        quillContent = data;
        // if (document.querySelector(".ql-editor")) {
        //     document.querySelector(".ql-editor").innerHTML = quillContent;
        // }

        quillContent = quill.getText();
        resolve(quillContent);
      });
    } catch (error) {
      reject(error);
    }
  });
};

function cleanString(input) {
  var output = "";
  for (var i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      output += input.charAt(i);
    }
  }
  return output;
}

export const sanitizeResetQuillAndGetContent = async (quill) => {
  return new Promise((resolve, reject) => {
    try {
      let quillContent = document.querySelector(".ql-editor").innerHTML;
      quillContent = quillContent.replace(/&nbsp;/g, " ");
      // quillContent = quillContent.replace(/<img[^>]*>/g, "");
      quillContent = quillContent.replace(/\r\n|\n/g, "\n");
      // quillContent = quillContent.replace(/\n/g, "\n");
      // quillContent = quillContent.replaceAll("<p><br></p>", "<br>");
      quillContent = quillContent.replaceAll("<br><br>", "<br>");
      quillContent = quillContent.replace(/<\/?span[^>]*>/g, "");

      sanitizeText(quillContent).then(async (data) => {
        quillContent = data;
        if (document.querySelector(".ql-editor")) {
          document.querySelector(".ql-editor").innerHTML = quillContent;
        }
        quillContent = quill.getText();
        resolve(quillContent);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const quillContentInsertBlotForSynonyms = (
  currentPointer,
  quillStartIndex,
  contentIndexes,
  contentStartKeyName,
  contentEndKeyName,
  attributeProps,
  meta = null
) => {
  return new Promise((resolve, reject) => {
    try {
      let i = 0,
        ops = [];

      contentIndexes = _.orderBy(
        contentIndexes,
        [contentStartKeyName, contentEndKeyName],
        ["asc", "desc"]
      );
      contentIndexes = mergeIndexConflicts(
        quillStartIndex,
        contentIndexes,
        contentStartKeyName,
        contentEndKeyName
      );
      contentIndexes.forEach((highLightIndex, index) => {
        let startIndex = quillStartIndex + highLightIndex[contentStartKeyName];
        let endIndex = quillStartIndex + highLightIndex[contentEndKeyName];

        if (i < startIndex && startIndex - i !== 0)
          ops.push({
            retain: startIndex - i,
          });

        if (endIndex - startIndex !== 0) {
          ops.push({
            retain: endIndex - startIndex,
            attributes: Object.assign(
              {},
              ...attributeProps.map((attribute) => {
                let resultData = {
                  identifier: attribute.id.replace(
                    "{index}",
                    startIndex + "-" + endIndex
                  ),
                };
                return {
                  [attribute.blotName]: {
                    ...attribute,
                    id: attribute.id.replace("{index}", index),
                    classes: attribute.classes.map((className, key) => {
                      const { replaceBy, replaceToKey } =
                        attribute.classDynamicKeys[key];
                      if (replaceBy && replaceToKey) {
                        let replaceKeys = replaceToKey.split(".");
                        if (replaceKeys.length === 2) {
                          return className.replace(
                            replaceBy,
                            _.kebabCase(
                              highLightIndex[replaceKeys[0]][replaceKeys[1]]
                            )
                          );
                        }

                        return className.replace(
                          replaceBy,
                          _.kebabCase(highLightIndex[replaceToKey])
                        );
                      } else return className;
                    }),
                    "data-reportData": JSON.stringify({
                      contentIndex: highLightIndex,
                      resultData: resultData,
                      contentStartKeyValue: highLightIndex[contentStartKeyName],
                    }),
                  },
                };
              })
            ),
          });
        }

        i = endIndex;
      });

      if (currentPointer.state.quill) {
        currentPointer.state.quill.updateContents({ ops });
      } else {
        currentPointer.props.quill.updateContents({ ops });
      }

      resolve(contentIndexes);
    } catch (error) {
      reject(error);
    }
  });
};

export const quillContentInsertBlotForGrammar = (
  currentPointer,
  quillStartIndex,
  contentIndexes,
  contentStartKeyName,
  contentEndKeyName,
  attributeProps,
  meta = null
) => {
  return new Promise((resolve, reject) => {
    try {
      let i = 0,
        ops = [];

      contentIndexes = _.orderBy(
        contentIndexes,
        [contentStartKeyName, contentEndKeyName],
        ["asc", "desc"]
      );
      contentIndexes = mergeIndexConflicts(
        quillStartIndex,
        contentIndexes,
        contentStartKeyName,
        contentEndKeyName
      );
      contentIndexes.forEach((highLightIndex, index) => {
        let startIndex = quillStartIndex + highLightIndex[contentStartKeyName];
        let endIndex = quillStartIndex + highLightIndex[contentEndKeyName];

        if (i < startIndex && startIndex - i !== 0)
          ops.push({
            retain: startIndex - i,
          });

        if (endIndex - startIndex !== 0) {
          ops.push({
            retain: endIndex - startIndex,
            attributes: Object.assign(
              {}, ...attributeProps.map((attribute) => {
                let resultData = {
                  identifier: attribute.id.replace(
                    "{index}",
                    startIndex + "-" + endIndex
                  ),
                };
                return {
                  [attribute.blotName]: {
                    ...attribute,
                    id: attribute.id.replace(
                      "{index}",
                      startIndex + "-" + endIndex
                    ),
                    classes: attribute.classes.map((className, key) => {
                      const { replaceBy, replaceToKey } =
                        attribute.classDynamicKeys[key];
                      if (replaceBy && replaceToKey) {
                        let replaceKeys = replaceToKey.split(".");
                        if (replaceKeys.length === 2) {
                          return className.replace(
                            replaceBy,
                            _.kebabCase(
                              highLightIndex[replaceKeys[0]][replaceKeys[1]]
                            )
                          );
                        }

                        return className.replace(
                          replaceBy,
                          _.kebabCase(highLightIndex[replaceToKey])
                        );
                      } else return className;
                    }),
                    "data-reportData": JSON.stringify({
                      contentIndex: highLightIndex,
                      resultData: resultData,
                      contentStartKeyValue: highLightIndex[contentStartKeyName],
                    }),
                  },
                };
              })
            ),
          });
        }

        i = endIndex;
      });

      if (currentPointer) {
        currentPointer.updateContents({ ops });
      } else {
        currentPointer.updateContents({ ops });
      }

      resolve(contentIndexes);
    } catch (error) {
      reject(error);
    }
  });
};

export const quillContentInsertBlotByContentIndex = (
  currentPointer,
  quillStartIndex,
  contentIndexes,
  contentStartKeyName,
  contentEndKeyName,
  attributeProps
) => {
  return new Promise((resolve, reject) => {
    try {
      let i = 0,
        ops = [];

      contentIndexes = _.orderBy(
        contentIndexes,
        [contentStartKeyName, contentEndKeyName],
        ["asc", "desc"]
      );
      contentIndexes = mergeIndexConflicts(
        quillStartIndex,
        contentIndexes,
        contentStartKeyName,
        contentEndKeyName
      );
      contentIndexes.forEach((highLightIndex, index) => {
        let startIndex = quillStartIndex + highLightIndex[contentStartKeyName];
        let endIndex = quillStartIndex + highLightIndex[contentEndKeyName];

        if (i < startIndex && startIndex - i !== 0)
          ops.push({
            retain: startIndex - i,
          });

        if (endIndex - startIndex !== 0) {
          ops.push({
            retain: endIndex - startIndex,
            attributes: Object.assign(
              {},
              ...attributeProps.map((attribute) => {
                let resultData = {
                  identifier: attribute.id.replace("{index}", index),
                };
                return {
                  [attribute.blotName]: {
                    ...attribute,
                    id: attribute.id.replace("{index}", index),
                    classes: attribute.classes.map((className, key) => {
                      const { replaceBy, replaceToKey } = attribute.classDynamicKeys[key];
                      if (replaceBy && replaceToKey) {
                        let replaceKeys = replaceToKey.split(".");
                        if (replaceKeys.length === 2) {
                          return className.replace(
                            replaceBy,
                            _.kebabCase(
                              highLightIndex[replaceKeys[0]][replaceKeys[1]]
                            )
                          );
                        }

                        return className.replace(
                          replaceBy,
                          _.kebabCase(highLightIndex[replaceToKey])
                        );
                      } else return className;
                    }),
                    "data-reportData": JSON.stringify({
                      contentIndex: highLightIndex,
                      resultData: resultData,
                      contentStartKeyValue: highLightIndex[contentStartKeyName],
                    }),
                  },
                };
              })
            ),
          });
        }

        i = endIndex;
      });

      if (currentPointer) {
        currentPointer.updateContents({ ops });
      } else {
        currentPointer.updateContents({ ops });
      }

      resolve(contentIndexes);
    } catch (error) {
      console.log(error)
      reject(error);
    }
  });
};

export const highlightTextByIndex = (e, className) => {
  e.preventDefault();
  window.$(`#${className}-cb`).click();
  if (window.$(`#${className}-cb`).prop("checked")) {
    window.$(`.${className}`).addClass("active");
    let elements = document.getElementsByClassName(className);
    if (elements.length > 0)
      document.querySelector("html").scrollTop = elements[0].offsetTop;
  } else {
    window.$(`.${className}`).removeClass("active");
    window.$(`.${className}`).removeClass("chClass");
  }
};

export const highlightTextParaphrasing = async (quill, replaceString, highlightIndex, setHighlightIndex, className, start, length) => {
  let get = document.getElementsByName('update_content');
  for(var i=0; i < get.length; i++) {
    if (get[i].id != `${className}-cb`) {
        get[i].checked = false
    }
  }

  if (!document.getElementById(`${className}-cb`).checked) {
    window.$(`#${className}-cb`).click();
    var string = replaceString.trim();

    await quill.deleteText(start, length, 'user');
    await quill.insertText(start, string, 'user');
    var end = start + string.length;
    await quill.setSelection(end);
  }

};

export const loadWordNet = () => {
  window.$(".blast").unbind("click");
  window.$(".blast").bind("click", async function () {
    let identifier = window.$(this)[0].id;
    var content = window.$(this)[0].innerText;
    window.$(".replaceGrammarClick").unbind("click");

    let result = await getWordnetData(content);
    result = result.data.results;
    window.$("#suggestionHover").html("");
    var html = "<div>";
    if (result.length > 0) {
      html +=
        "<p>You can use our recommended thesaurus options.</p>" +
        "<div className='replaceBtnContainer'>";
      for (const replacement of result) {
        html += `<p>${replacement.def}</p>`;
        for (const synonym of replacement.synonyms) {
          html += `<button className="replaceGrammarClick" data-id="${identifier}" data-replacement="${synonym.replaceAll( "_", " " )}">${synonym.replaceAll("_", " ")}</button>`;
        }
      }

      html += "</div>";
    } else {
      html +=
        "<p>No result found for this word.</p>" +
        "<p>You can use <strong>Paraphrase Text</strong> to rephrase your paragraph / sentence.</p>";
    }
    html += "</div>";
    window.$("#suggestionHover").html(html);

    window.$(".replaceGrammarClick").bind("click", async function () {
      const elemId = window.$(this).data("id");
      const elemReplacement = window.$(this).data("replacement");

      await replaceGrammarError(elemId, elemReplacement);
    });
    var temp_left = 0;
    var temp_top = 0;

    window
      .$("#suggestionHover")
      .show()
      .css({
        left: this.offsetLeft + this.offsetWidth / 2 - temp_left,
        top: this.offsetTop + this.offsetHeight + temp_top + 140,
      });
  });
};

export const replaceGrammarError = (elementId, replaceString, index) => {
  let element = document.getElementById(elementId);
  if (element == null) return;
  element.innerHTML = replaceString;
  element.classList.add("error-corrected");
  if (index == undefined)
    document.querySelector(".ql-editor").scrollTop =
      document.getElementById(`${elementId}`).offsetTop - 100;
};

export const highlightTextBlastTags = async (element, i) => {
  window.$(`.blast`).each(function (i) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });

  element.classList.add("highlight");
  var content = element.innerText;

  let nlpService = new NLPService("");
  nlpService.setContent(content);
  let response = await nlpService.getTagReport();

  let index = 0,
    newStr = "";

  response.map((res) => {
    let strToHighlight = content.substring(index, res.wordEndIndex);
    index = res.wordEndIndex;
    window.$("#editor-container").html(strToHighlight);
    window.$("#editor-container").blast({
      search: res.word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${res.wordTag} keyword-${res.wordTag}`,
    });
    newStr += window.$("#editor-container").html();
    window.$("#editor-container").html("");
  });

  element.innerHTML = newStr;

  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlast = (e, targetName, className) => {
  e.preventDefault();
  window.$(`#${className}-cb`).click();
  if (window.$(`#${className}-cb`).prop("checked")) {
    var content = document.querySelector(".ql-editor").innerHTML;
    window.$("#editor-container").html(content);
    window.$("#editor-container").blast({
      search: targetName,
      generateValueClass: true,
      generateIndexID: true,
      customClass: `active switch-${className} ${className}`,
      aria: false,
    });

    var updatedData = window.$("#editor-container").html();

    window.$(`.${className}`).addClass("active");
    document.querySelector(".ql-editor").innerHTML = updatedData;

    let elements = document.getElementsByClassName(className);
    if (elements.length > 0)
      document.querySelector("html").scrollTop = elements[0].offsetTop;
  } else {
    window.$(`.${className}`).removeClass("active");
    window.$(`.${className}`).removeClass("chClass");
    window.$(`.switch-${className}`).each(function (index) {
      var text = window.$(this).text();
      window.$(this).replaceWith(text);
    });
  }
};

export const highlightTextBlastCustomUncommon = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  let words = content.toLowerCase().split(" ");
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  words = words.filter((d) => d != undefined && d.length > 0);
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((keywords.length * 100) / words.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 50) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustomCommon = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  let words = content.toLowerCase().split(" ");
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  words = words.filter((d) => d != undefined && d.length > 0);
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((keywords.length * 100) / words.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount < 5) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustomPassiveIndicators = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  var characters = 0;
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    characters += word.length;
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((characters * 100) / content.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 0) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustomCliches = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  var characters = 0;
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    characters += word.length;
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((characters * 100) / content.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 5) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustomDescriptors = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  var characters = 0;
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    characters += word.length;
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((characters * 100) / content.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 5) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextById = (element, paragraph, keyword) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  // highlight code here
  element.classList.add("highlight");
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustomRedundency = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  var characters = 0;
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    characters += word.length;
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((characters * 100) / content.length) > 0 ? 100 : 0;
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 0) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustomUnnecessary = (
  element,
  paragraph,
  keywords
) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  let words = content.toLowerCase().split(" ");
  keywords = keywords.filter((d) => d.paragraph == paragraph);
  words = words.filter((d) => d != undefined && d.length > 0);
  window.$("#editor-container").html(element.innerHTML);
  _.map(_.groupBy(keywords, "word"), (wordArr, word) => {
    window.$("#editor-container").blast({
      search: word,
      generateValueClass: true,
      generateIndexID: true,
      aria: false,
      customClass: `active switch-keyword-${word} keyword-${word}`,
    });
  });

  var wordsCount = Number((keywords.length * 100) / words.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 0) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastCustom = (element) => {
  window.$(`#paraCount`).removeClass("d-none").addClass("d-block");
  window.$(`.reportcard-1`).removeClass("active");
  window.$(`.reportcard-1`).removeClass("chClass");
  window.$(`.blast`).each(function (index) {
    var text = window.$(this).text();
    window.$(this).replaceWith(text);
  });
  element.classList.add("highlight");
  var content = element.innerText;
  let words = content
    .toLowerCase()
    .replace(RegExp(/[^A-Za-z'\-]/, "g"), " ")
    .split(" ");
  window.$("#editor-container").html(element.innerHTML);
  let wordCount = 0;
  _.map(_.groupBy(words), (wordArr, word) => {
    var count = word.match(/[aeiou]/gi);
    if (count && count.length > 3) {
      wordCount += 1;
      window.$("#editor-container").blast({
        search: word,
        generateValueClass: true,
        generateIndexID: true,
        aria: false,
        customClass: `active switch-keyword-${word} keyword-${word}`,
      });
    }
  });

  var wordsCount = Number((wordCount * 100) / words.length);
  window
    .$("#scoreCount")
    .html(wordsCount.toFixed(1) + "<span>%  (Paragraphwise Score)</span>");
  window.$("#scoreSubCount").html(content.substring(0, 40) + " ...");
  window
    .$("#scoreCountIcon")
    .removeClass("mdi-thumb-down mdi-thumb-up red-color green-color");
  if (wordsCount > 5) {
    window.$("#scoreCountIcon").addClass("mdi-thumb-down red-color");
  } else {
    window.$("#scoreCountIcon").addClass("mdi-thumb-up green-color");
  }

  var updatedData = window.$("#editor-container").html();
  element.innerHTML = updatedData;
  setTimeout(() => {
    element.classList.remove("highlight");
  }, 1500);
};

export const highlightTextBlastAdverb = (
  e,
  targetName,
  className,
  addClass
) => {
  e.preventDefault();
  window.$(`#${className}-cb`).click();
  if (window.$(`#${className}-cb`).prop("checked")) {
    var content = document.querySelector(".ql-editor").innerHTML;
    window.$("#editor-container").html(content);
    window.$("#editor-container").blast({
      search: targetName,
      generateValueClass: true,
      generateIndexID: true,
      customClass: `active switch-${className} ${className} ${addClass}`,
      aria: false,
    });

    var updatedData = window.$("#editor-container").html();

    window.$(`.${className}`).addClass("active");
    document.querySelector(".ql-editor").innerHTML = updatedData;

    let elements = document.getElementsByClassName(className);
    if (elements.length > 0)
      document.querySelector("html").scrollTop = elements[0].offsetTop;
  } else {
    window.$(`.${className}`).removeClass("active");
    window.$(`.${className}`).removeClass("chClass");
    window.$(`.switch-${className}`).each(function (index) {
      var text = window.$(this).text();
      window.$(this).replaceWith(text);
    });
  }
};

export const getProximityContentAndIndex = (quill) => {
  let content = "",
    contentStartIndex = -1,
    contentEndIndex = -1;

  let currentPosition = quill.getSelection();
  if (currentPosition == null) return;

  let quillContent = quill.getText();

  contentEndIndex = quillContent.indexOf("\n", currentPosition.index);
  if (
    contentEndIndex >= 0 &&
    quillContent.indexOf("\n", contentEndIndex + 2) >= 0
  )
    contentEndIndex = quillContent.indexOf("\n", contentEndIndex + 2);

  contentStartIndex = quillContent
    .substr(0, currentPosition.index)
    .lastIndexOf("\n");
  if (
    contentStartIndex >= 0 &&
    quillContent.substr(0, contentStartIndex).lastIndexOf("\n") >= 0
  )
    contentStartIndex = quillContent
      .substr(0, contentStartIndex)
      .lastIndexOf("\n");
  else contentStartIndex = 0;

  if (contentStartIndex !== -1 && contentEndIndex !== -1)
    content = quill.getText(
      contentStartIndex,
      contentEndIndex - contentStartIndex
    );
  else content = quillContent;

  return {
    content,
    contentStartIndex,
  };
};

export const resetQuillTags = (startIndex, endIndex, tagArr, quill) => {
  let tagObj = {};
  tagArr.forEach((tag) => (tagObj[tag] = false));
  quill.formatText(startIndex, endIndex, tagObj);
};

export const getSynonyms = async (input_arr, start) => {
  let response = [];
  var index = start;
  for (const word of input_arr) {
    const res = await getWordnetData(word.replace(/[^a-zA-Z ]/g, ""));
    let synonyms = [];
    res.data.results.map((output) => {
      if (output) {
        synonyms.push({
          synonyms: output.synonyms,
          def: output.def,
        });
      }
    });
    response.push({
      stringStartIndex: Number(index),
      stringEndIndex: Number(index) + Number(word.length),
      word: word.replace(/[^a-zA-Z ]/g, ""),
      synonyms: synonyms,
    });
    index = Number(index) + Number(word.length) + 1;
  }
  return response;
};

// export const generateCitationFormat = (format, authors, year) => {
//   let author_str = [];
//   if (authors.length > 0) {
//     if (authors.length > 2) {
//     } else if (authors.length == 2) {
//     } else {
//     }
//   } else {
//     author_str += '...';
//   }
//   switch (format) {
//     case "Default":
//         // Do Nothing
//         break;
//     case "APA 7":
//         break;
//     case "MLA 9":
//         break;
//     case "Harvard":
//         break;
//     case "IEEE":
//         break;
//     case "Chicago":
//         break;
//     default:
//         break;
// }
// }