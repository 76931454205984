import _ from "lodash";

export default function CardAnalysis({ text, value }) {
  
  return (
    <div className={`card fs-12px text-start custom-card1 mb-3 p-0 reportcard-1`}>
        <div className="card-body">
            <div className="d-flex align-items-center">
                <div className="fs-14px text-capitalize fw-normal">{text}</div>
                <div className="ms-auto p-2 rounded">
                    <span className="text-capitalize h5 fw-medium">{value}</span>
                </div>
            </div>
        </div>
    </div>
  );
}
