import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import Button2 from "./Buttons/Button2";
import dashboardIcon from "../../assets/dashboard/dashboard.svg";
import priceIcon from "../../assets/dashboard/price-list.svg";
import profileIcon from "../../assets/dashboard/user.svg";
import aiEditorIcon from "../../assets/dashboard/ai-editor.svg";
import upgradePlanIcon from "../../assets/dashboard/upgrade-plan.svg";
import purchaseTokenIcon from "../../assets/dashboard/purchase-token.svg";
import supportIcon from "../../assets/dashboard/support.svg";
import termsIcon from "../../assets/dashboard/terms.svg";
import privacyIcon from "../../assets/dashboard/privacy-policy.svg";
import gdprcIcon from "../../assets/dashboard/gprc.svg";
import { getData, resetData } from "../../services/dataService";
import { logout } from "../../services/authService";

export default function Sidebar({ toggleSidebar, sidebarToggle }) {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  useEffect(() => {
    let userData = getData('user');
    userData = JSON.parse(userData);
    setUser(userData);
  }, []);

  const logoutUser = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className={"main_nav align-items-center " + (sidebarToggle ? "active" : "")}>
      <div className="sidebarLogo d-flex align-items-center">
        <img src={logo} alt="manuscripts logo" />
        <span className="mdi mdi-close close_sidebar ms-auto cursor-pointer" onClick={() => toggleSidebar()}></span>
      </div>
      <ul>
        <li>
          <a href="/">
            <span className="mdi">
              <img src={dashboardIcon} className="img-fluid" alt="" />
            </span>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a target="_blank" href="/pricing-plans">
            <span className="mdi">
              <img src={priceIcon} className="img-fluid" style={{ width: "12px" }} alt="" />
            </span>
            <span>Pricing Plans</span>
          </a>
        </li>
        <li>
          <a target="_blank" href="/profile">
            <span className="mdi">
              <img src={profileIcon} style={{ width: "10px" }} className="img-fluid" alt=""/>
            </span>
            <span>Profile</span>
          </a>
        </li>
        <li>
          <hr className="m-0 p-0" />
        </li>
        <li>
          <a target="_blank" href="https://manuscripts.ai/pricing-plans/">
            <span className="mdi">
              <img src={upgradePlanIcon} className="img-fluid" alt="" />
            </span>
            <span>Upgrade to Premium</span>
          </a>
        </li>
        <li>
          <a target="_blank" href={`https://manuscripts.thrivecart.com/purchase-tokens/?&passthrough[customer_email]=${user?.email}`}>
            <span className="mdi">
              <img src={purchaseTokenIcon} className="img-fluid" alt="" />
            </span>
            <span>Purchase Tokens</span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://manuscripts.ai/contact-us/">
            <span className="mdi">
              <img src={supportIcon} className="img-fluid" alt="" />
            </span>
            <span>Support</span>
          </a>
        </li>
        <li>
          <hr className="m-0 p-0" />
        </li>
        <li>
          <a target="_blank" href="https://manuscripts.ai/terms-and-conditions/">
            <span className="mdi">
              <img src={termsIcon} className="img-fluid" alt="" />
            </span>
            <span>Terms and Conditions</span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://manuscripts.ai/privacy-policy/">
            <span className="mdi">
              <img src={privacyIcon} className="img-fluid" alt="" />
            </span>
            <span>Privacy Policy</span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://manuscripts.ai/gdprccpa/">
            <span className="mdi">
              <img src={gdprcIcon} className="img-fluid" alt="" />
            </span>
            <span>GDPRCCPA</span>
          </a>
        </li>
        <li>
          <hr className="m-0 p-0" />
        </li>
      </ul>
      <div className="px-4 sticky-bottom">
        <a className="btn theme-btn3 mb-3 w-100 fs-12px" href={`/ai-editor`}>
            <span className="mdi mdi-tools me-2"></span> AI Editor Tools
        </a>
        <Button2 className="mt-auto m-auto w-100" onClick={() => logoutUser()}>
          <span className="mdi mdi-logout-variant me-2"></span>
          Logout
        </Button2>
      </div>
    </nav>
  );
}
