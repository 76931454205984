import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import { getData, setData } from "../../services/dataService";
import { handleHttpError } from "../../services/httpErrorService";
import { userLogin } from "../../services/authService";
import logo from "./../../assets/logo.svg";
import supportImg from "./../../assets/girl-with-laptop.svg";
import Button3 from "../common/Buttons/Button3";
import SocialAuth from "./SocialAuth";
import { createDocument, getDocuments } from "../../services/documentService";

const Login = (props) => {
  const navigate = useNavigate();
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [destination, setDestination] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "", login: "" });

  useEffect(() => {
    setLoader(false)
    const token = getData("token");
    if (token != undefined || token != null) {
      // fetchDocument();
      navigate("/");
    } else {
      const search = new URLSearchParams(window.location.search);
      const destination = search.get("destination");
      if (destination) {
        setDestination(destination);
      }
    }
  }, []);

  const handleSuccess = (response) => {
    if (response.status == 200) {
      let { token_type, token, user, type } = response.data.result;
      if (type == "new") {
        if (user.user_mode == "Student") {
          window.location.href = `https://manuscripts.thrivecart.com/students-professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`;
        } else {
          window.location.href = `https://manuscripts.thrivecart.com/professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`;
        }
        setLoader(false);
      } else {
        setData("token", token);
        setData("token_type", token_type);
        setData("user", user);
        setLoader(false);
        window.location.reload();
      }
    }
  };

  const fetchDocument = () => {
    getDocuments()
    .then((httpResponse) => {
      if (httpResponse.data.result.allDocuments.length > 0) {
        window.location.href = `/editor?doc=${httpResponse.data.result.allDocuments[0].uuid}`;
      } else {
        createDocument({ title: 'Untitled', description: "", content: "", genre_type: "Fiction" })
        .then((httpResponse) => window.location.href = `/editor?doc=${httpResponse.data.result.uuid}` )
        .catch((httpResponse) => console.log(httpResponse));
      }
    })
    .catch((httpResponse) => console.log(httpResponse));
  }

  const handleError = (error) => {
    if (error && (error.status == 500 || error.status == 400)) {
      let { login, email, password } = error.data.error || {};
      setLoader(false);
      setErrors({
        login: login != undefined ? login : "",
        username: email,
        password: password,
      });
    } else {
      setLoader(false);
      handleHttpError(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    const errors = validateCredentials(username, password);
    if (errors) return setErrors(errors);
    setErrors({ email: "", password: "" });
    userLogin({ username: username, password: password })
      .then((httpResponse) => handleSuccess(httpResponse))
      .catch((httpResponse) => handleError(httpResponse.response));
  };

  const validateCredentials = (email, password) => {
    const schema = Joi.object({
      username: Joi.string().min(3).required(),
      password: Joi.string().min(3).required(),
    });
    const { error } = schema.validate(
      { username: username, password: password },
      { abortEarly: false }
    );
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  return (
    <div className="row" style={{ minHeight: "500px", height: "100vh" }}>
      <div className="col-md-6 p-5 text-start position-relative">
        <Link to="/">
          <img src={logo} className="img-fluid" alt="" />
        </Link>
        <h1 className="login_title mt-5 fs-2 ">Log In To Get Started</h1>
        <p>
          If you don’t have an account you can{" "}
          <Link to="/signup/" className="theme-link fs-14px">
            Sign Up
          </Link>
        </p>
        <img
          className="d-md-block d-none position-absolute bottom-0 start-0 end-0 mx-auto mb-4"
          src={supportImg}
          alt="manuscripts.ai girl with laptop"
          style={{ maxHeight: "calc(100vh - 270px)" }}
        />
      </div>
      <div className="col-md-6 p-5 form-container">
        <div className="form-container-inner ">
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-start">
              <label className="form-label fs-12px mb-1 fw-medium">
                Email Id
              </label>
              <input
                type="email"
                className="form-control rounded-0 fs-12px"
                placeholder="Email Id"
                id="email"
                aria-describedby="emailHelp"
                onChange={(e) => setEmail(e.target.value)}
              />

              {errors?.username && (
                <div id="emailHelp" className="form-text text-danger mb-3">
                  {errors?.username}
                </div>
              )}
            </div>
            <div className="mb-3 text-start">
              <label className="form-label fs-12px mb-1 fw-medium">
                Password
              </label>
              <input
                type="password"
                className="form-control rounded-0 fs-12px"
                placeholder="Password"
                id="password"
                aria-describedby="passwordHelp"
                onChange={(e) => setPassword(e.target.value)}
              />

              {errors?.password && (
                <div id="passwordHelp" className="form-text text-danger mb-3">
                  {errors?.password}
                </div>
              )}
            </div>

            <Button3 className="fs-14px">
              {loader && 
                <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
              }
              <span className="me-1">Log In</span>
            </Button3>
          </form>
          <div className="col-md-12 my-3 text-center fs-12px">
            <a href={"/forgot-password"} className="orange-text theme-link">
              Forgot password ?
            </a>
          </div>
          {/* <p className="my-2"><a href="" className="theme-link fs-14px">Forgot password ?</a></p> */}
          <p className="my-3 fs-12px fw-medium">or continue with</p>
          <SocialAuth page="Login" destination={destination} />
          <p className="my-4 fs-12px fw-medium">
            If you don’t have an account you can{" "}
            <Link to="/signup/" className="theme-link fs-14px">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Login;
