import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import { getSentenceStarterReport } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex, highlightTextByIndex } from "../../services/highlightContent";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function SentenceStarter() {

    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('sentence opener');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
          loadSentenceStarter();
        }
    }, [propsRouter]);

    const loadSentenceStarter = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }
                    let response = await getSentenceStarterReport(quillContent);

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            sentenceStarter: _.map(_.groupBy(response, "word"), (dataArr, word) => {
                                return { word: word, wordsCount: dataArr.length, dataArr: dataArr };
                            }),
                        },
                    });

                    response = _.sortBy(response, "stringEndIndex");
                    quillContentInsertBlotByContentIndex(quill, 0, response, "stringStartIndex", "stringEndIndex", 
                        [{
                            blotName: "span",
                            id: `sentence-starter-{index}`,
                            classes: [`sentence-starter`, `sentence-starter-{word}`],
                            classDynamicKeys: [ "", { replaceBy: "{word}", replaceToKey: "word" } ],
                        }]
                    );

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        }, 1000);
    }

    let sentenceStarter = [];
    if (report?.data) {
        sentenceStarter = report.data.sentenceStarter || [];
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Sentence Starter</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/sentence-openers-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadSentenceStarter} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer mt-0">
                    {!loader && sentenceStarter.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content or report details could not be generated, Please Refresh the report"></Card2>
                    }
                    {loader && sentenceStarter.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    {sentenceStarter && sentenceStarter.map((v, i) => (
                        <div className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 sentence-starter-${_.kebabCase(v.word)}`} onClick={(e) => highlightTextByIndex(e, `sentence-starter-${_.kebabCase(v.word)}`)} key={i} >
                            <div className="card-body py-2">
                                <input className="form-check-input" id={`sentence-starter-${_.kebabCase(v.word)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.word}</div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.wordsCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}