import React, { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";
import logo from "../../assets/logo.webp";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/authService";

export default function TopBar ({ page, availableTokens, doc, enableSpellChecker, handleEnableSpellChecker, userData, libraryPanel, setLibraryPanel, historyPanel, setHistoryPanel }) {

    const [bookTitle, setBookTitle] = useState('Untitled');

    const navigate = useNavigate();

    useEffect(() => {
        setBookTitle(doc?.title || 'Untitled');
    }, [doc, enableSpellChecker])

    const logoutUser = () => {
        logout();
        navigate("/login");
    };

    return (
        <div className="container-fluid custom_top_bar">
            <nav className="navbar fixed-top navbar-expand-lg shadow-md bg-white border-bottom">
                <a className="navbar-brand mx-4" href="/"><img src={logo} className="img-fluid" style={{ minWidth: '120px', maxWidth: '180px' }} alt="" /></a>
                {page == "editor" && 
                    <>
                        <div className={`collapse navbar-collapse`}>
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item me-1 px-2 dropdown desk-width-max-content cursor-pointer" data-titlebottom={bookTitle}>
                                    <span className="nav-link">
                                        <p className="border-0 fs-6 fw-normal mb-0 ms-2 text-dark">{_.truncate(bookTitle, { length: 30 })}</p>
                                    </span>
                                </li>
                                <li className="d-lg-none d-block">
                                    <span className="fs-12px"> <span className="mdi mdi-history"></span> History </span>
                                </li>
                                <li className="d-lg-none d-block">
                                    <span className="nav-link color-theme fs-12px">Available tokens : <span className="fw-semibold">{availableTokens}</span></span>
                                </li>
                            </ul>
                            {/* <button data-title-bottom="Shortcuts" className="border-0 light_orange_btn fs-14px me-2 d-none d-lg-block px-3"><span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="History" className="mdi mdi-snowflake"></span></button> */}
                            {/* <button data-title-bottom="History" className="border-0 light_orange_btn fs-14px me-2 d-none d-lg-block px-3"><span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="History" className="mdi mdi-history"></span></button> */}

                            <div className="form-check form-switch light_gray_btn">
                                <input checked={enableSpellChecker} onChange={(e) => handleEnableSpellChecker(e)} className="form-check-input ms-0 me-2" type="checkbox" id="enableSpellCheck" />
                                <label className="form-check-label fs-12px">Grammar & Style</label>
                            </div>
                            <span className="navbar-text mx-2 p-0 d-none d-lg-block">
                                <span className="light_gray_btn fs-12px desk-width-max-content" style={{display:"block"}}>Available tokens : {availableTokens}</span>
                            </span>
                            {userData?.user?.user_mode == "Student" &&
                                <div data-title-bottom="Library" className={`light_orange_btn d-none d-xl-block fs-12px me-2 cursor-pointer`} onClick={(e) => setLibraryPanel(!libraryPanel)}>
                                    <span className="mdi mdi-bookmark-box-multiple fs-14px"></span> 
                                    {/* Library */}
                                </div>
                            }
                            <div data-title-bottom="History" className={`light_orange_btn d-none d-xl-block fs-12px me-3 cursor-pointer`} onClick={(e) => setHistoryPanel(!historyPanel)}>
                                <span className="mdi mdi-history fs-14px"></span> 
                            </div>
                            <div onClick={(e) => logoutUser()} data-title-bottom="Logout" className={`theme-btn8 rounded fs-12px me-3 cursor-pointer`}>
                                <span className="mdi mdi-logout fs-14px"></span> 
                            </div>
                        </div>
                        <div className="form-check form-switch d-block d-lg-none me-2 bg-light px-2 py-1 rounded">
                            <input checked={enableSpellChecker} onChange={(e) => handleEnableSpellChecker(e)} className="form-check-input ms-0 me-2" type="checkbox" id="enableSpellCheck" />
                            <label className="form-check-label fs-12px">Grammar</label>
                        </div>
                    </>
                }
            </nav>
        </div>
    )
}