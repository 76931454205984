import React, { useEffect, useState } from "react";
import { useOutletContext, Link, useNavigate } from "react-router-dom";
import Card2 from "../common/Cards/Card2";
import Card3 from "../common/Cards/Card3";
import { getReportUsage, getAddedTokens, updateTokenUsages, getTokenCount } from "../../services/reportServices";
import { apiRouts } from "../../utils/routes";
import { getData } from "../../services/dataService";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex, sanitizeResetQuillAndGetContentByRange, highlightTextParaphrasing } from "../../services/highlightContent";
import _ from "lodash";
import NLPService from "../../services/nlpService";
import Button1 from "../common/Buttons/Button1";
import { parseJson } from "../../services/handleSse/handleSseParaphrasing";
import { SSE } from "sse.js";

export default function Paraphrasing() {
  const [ docId, report, setReport, quill, setQuill, userData, setUserData, propsRouter, setAvailableTokens, loader, handleLoaderOff, checkPage, setCheckPage, setAiResultOutput, setShowAIResultPopup ] = useOutletContext();
  const [errMsg, setErrMsg] = useState({});
  const [rpLoader, setRpLoader] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState([]);
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionLength, setSelectionLength] = useState(0);
  const [reportOption, setReportOption] = useState('Simple');
  const [reportPrompt, setReportPrompt] = useState('');

  const navigate = useNavigate();

  let book_id = 0;

  useEffect(() => {
    setReport({});
    setErrMsg({});
    setCheckPage('paraphrasing');
    setAiResultOutput([]);
    setShowAIResultPopup(false);
    if (quill && quill.getLength() > 3) {
      let queryParams = new URLSearchParams(propsRouter.location.search);
      let source = new URLSearchParams(propsRouter.location.search).get("src");
      book_id = new URLSearchParams(propsRouter.location.search).get("doc");

      if (source == "selection") {
        let start = parseInt(queryParams.get("s"));
        let length = parseInt(queryParams.get("l"));
        let type = queryParams.get("type");

        if (start >= 0 && length >= 0 && quill) {
          // loadParaphrasing(start, length, type);
          setSelectionStart(start);
          setSelectionLength(length);
        } else {
          setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
          setSelectionStart(0);
          setSelectionLength(0);
        }
      } else {
        setErrMsg({ type: "warning", title: "Invalid Access", message: "Selection is not provided please select content and Re-run the report." });
      }
    } else {
      setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
    }
  }, [propsRouter]);

  const handleSseParaphrasing = async (
    sentences = [],
    start,
    length,
    type,
    quillContent,
    input_token_count,
    usageLimit,
    totalLimit
  ) => {
    try {
      if (!sentences.length) {
        setRpLoader(false);
        setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
        return;
      }

      let paraphraseOutput = [];
      let paraphraseData = [];
      let nth = 0;

      const urlWithData = new URL(apiRouts.stream_paraphre_openai_v4);
      let input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        type: reportOption,
        n: userData.user.no_of_cards,
        temperature: userData.user.temperature,
        maxToken: userData.user.size,
        genre: userData.user.genre,
        prompt: reportPrompt,
        sentence: quillContent
      }

      const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json" },
        payload: JSON.stringify(input_data),
      });

      source.onreadystatechange = async function (event) {
        if (event.readyState === source.OPEN) {
          setRpLoader(true);
        }
   
        if (event.readyState === source.CLOSED) {
          setRpLoader(false);
          source.close();
          paraphraseData[nth] = {
            start: start,
            end: start + length,
            input: quillContent,
            type: type,
            output: paraphraseOutput[nth],
          };

          let highlightData = [{ start: start, end: start + length, input: quillContent }];

          quillContentInsertBlotByContentIndex(quill, 0, highlightData, "start", "end",
            [{
                blotName: "span",
                id: `paraphrase-${start}-${start + length}`,
                classes: [`paraphrase`, `paraphrase-{input}`],
                classDynamicKeys: ["", { replaceBy: "{input}", replaceToKey: "input" }],
              },
            ]
          );

          setHighlightIndex({ 'start': start, 'lenght': length });

          let scrollTop = document.getElementById(`paraphrase-${start}-${start + length}`).offsetTop;
          document.querySelector("html").scrollTop = scrollTop - 100;

          let all_output = '', total_used_limit = 0;

          for (const data of paraphraseData) {
            if (data.output && data.output != undefined) {
              all_output += data.output + "";
              let charCount = await updateTokenUsages("paraphrasing-report-v1", book_id, "Paraphrasing "+type+": " + data.input, data.output);
              charCount = charCount ? charCount : 0;
              usageLimit = totalLimit - (Number(charCount) + Number(usageLimit)) > 0 ? totalLimit - (Number(charCount) + Number(usageLimit)) : 0;
              total_used_limit += charCount;
              setAvailableTokens(usageLimit);
            }
          }

          if (all_output.length < 3) {
            setErrMsg({ type: "warning", title: "Content Processed", message: "Could not generate content for the given selection or Looks like it is already valid content, Please try with some different Content." });
          }
        }
      };
   
      source.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data?.content) {
          nth = data?.nth;
          paraphraseOutput[nth] = (paraphraseOutput[nth] || "") + data?.content;

          paraphraseData[nth] = {
            start: start,
            end: start + length,
            input: quillContent,
            type: type,
            output: paraphraseOutput[nth],
          };

          setReport({
            lastReportIndexes: { start: start, end: start + length },
            data: { paraPhrasingReport: paraphraseData },
          });
        }
      };
   
      source.onerror = function (event) {
        source.close();
        setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please check the details provided for the report query are valid and try again." });
        setRpLoader(false);
      };
    } catch (error) {
      setRpLoader(false);
      setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
    }
  };

  const loadParaphrasing = async (start, length, type) => {
    setRpLoader(true);
    // await sanitizeResetQuillAndGetContent(quill);
    setTimeout(async () => {
      try {
        let usage = await getReportUsage();
        if (usage[0].data.result.statusCode == 200) {
          var usageLimit = usage[0].data.result.result;
          if (userData.packages[0] && userData.user) {
            const quillContent = await sanitizeResetQuillAndGetContentByRange(quill, start, length);

            if (quillContent.length > 4) {
              let input_token_data = await getTokenCount(quillContent);
              let input_token_count = input_token_data.data.result.length;
  
              if (input_token_count > 8000) {
                setRpLoader(false);
                setErrMsg({ type: "warning", title: "Limit exceed!", message: "Character limit exceeded. You can not select more than 8000 characters." });
              } else {
                let nlpService = new NLPService("");
                nlpService.setContent(quillContent);
                let data = nlpService.getSentaces();
  
                let addOnTokens = await getAddedTokens(userData.user.id);
                addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                let totalLimit = 200000 + addOnTokens;

                if (usageLimit < totalLimit) {
                  if (Number(input_token_count) + Number(usageLimit) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                    await handleSseParaphrasing(data, start, length, type, quillContent, input_token_count, usageLimit, totalLimit);
                  } else {
                    setRpLoader(false);
                    setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your Paraphrasing Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan." });
                  }
                } else {
                  setRpLoader(false);
                  setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your Paraphrasing Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                }
              }
            } else {
              setRpLoader(false);
              setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
            }

          } else {
            setRpLoader(false);
            setErrMsg({ type: "warning", title: "Session missing!", message: "You can login and try this report again!" });
          }
        } else {
          setRpLoader(false);
          setErrMsg({ type: "warning", title: "Permission denied!", message: "Paraphrasing service is not available for this account." });
        }
      } catch (error) {
        setRpLoader(false);
        setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
      }
    }, 1000);
  };

  const checkData = async (e) => {
    e.preventDefault();
    setReport({});
    setErrMsg({});
    await loadParaphrasing(selectionStart, selectionLength, reportOption);
  }

  let handleTextParaphrasing = async (replaceString, className) => {
    await highlightTextParaphrasing(quill, replaceString, highlightIndex, setHighlightIndex, className);
    setErrMsg({ errMsg: null });
  };

  let paraPhrasingReport = [];
  if (report?.data) {
    paraPhrasingReport = report.data.paraPhrasingReport || [];
  }

  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <button className="theme-link btn p-0 border-0" onClick={(e) => navigate(-1)}>
            <span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span>
          </button>
          {/* <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a> */}
          <span>Paraphrasing</span>
          <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/paraphrasing-selection-tools">
            <span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?
          </a>
        </div>
      </div>
      <div className="result_card_cover text-start">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-12 text-start mb-3">
                <p className="mb-2 fs-12px fw-medium">Paraphrase Option</p>
                <select className="form-control fs-12px" value={reportOption} onChange={(e) => setReportOption(e.target.value)}>
                  <option value="Simple">Simple</option>
                  <option value="Fluent">Fluent</option>
                  <option value="Creative">Creative</option>
                  <option value="Formal">Formal</option>
                  <option value="Short">Short</option>
                  <option value="Prompt">Customize Prompt</option>
                </select>
              </div>
              {reportOption && reportOption == 'Prompt' &&  
                <div className="col-12 text-start mb-3">
                  <p className="mb-2 fs-12px fw-medium">Custom Prompt</p>
                  <textarea placeholder="add more intensity" cols="30" rows="3" className="form-control fs-12px" value={reportPrompt} onChange={(e) => setReportPrompt(e.target.value)}></textarea>
                </div>
              }
              <div className="col-12 text-end mb-1">
                <button onClick={(e) => checkData(e)} className="btn theme-btn3 fs-12px">
                  {rpLoader && 
                    <div className="spinner-border mai-spinner" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }
                  Generate Content
                </button>
              </div>
            </div>
          </div>
        </div>
        {errMsg.hasOwnProperty("message") && (
          <Card2 title={errMsg.title} description={errMsg.message}></Card2>
        )}
        {rpLoader && !errMsg.hasOwnProperty("message") && (
          <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
        )}
        {paraPhrasingReport && (
          <div className="reportContainer">
            {paraPhrasingReport.map((v, i) => (
              <div key={i} className={`reportcard-1 shadow-sm paraphrase-${_.kebabCase(v.input)}`}>
                <input className="form-check-input" name="update_content" id={`paraphrase-${_.kebabCase(v.input)}-${i}-cb`} type="checkbox" />
                <Card3 title={v.type} description={v.output} index={i}>
                  <div className="num"></div>
                  <Button1 onClick={(e) => handleTextParaphrasing(`${v.output}`, `paraphrase-${_.kebabCase(v.input)}-${i}`)}>
                    <span className="mdi mdi-chevron-left me-2"></span>Insert Text
                  </Button1>
                </Card3>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
