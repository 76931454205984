import { useEffect, useState } from "react";
import _ from "lodash";
import { getPlagiarismCitation, getReportUsage, createAnalytics } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContentByRange } from "../../services/highlightContent";
import { Link, useOutletContext } from "react-router-dom";
import Card2 from "../common/Cards/Card2";

export default function PlagiarismCitation() {
  // { docId, report, setReport, quill, setQuill, userData, setUserData, propsRouter, setAvailableTokens, loader, handleLoaderOff, checkPage, setCheckPage, setAiResultOutput, setShowAIResultPopup, posStart, posEnd }
  const [ docId, report, setReport, quill, setQuill, userData, setUserData, propsRouter, setAvailableTokens, loader, handleLoaderOff, checkPage, setCheckPage, setAiResultOutput, setShowAIResultPopup, currentDoc ] = useOutletContext();
  const [errMsg, setErrMsg] = useState({});
  const [rpLoader, setRpLoader] = useState(false);
  let book_id = 0;
  useEffect(() => {
      setCheckPage('plagiarism');
      setReport({});
      setErrMsg({});
      setAiResultOutput([]);
      setShowAIResultPopup(false);
      if (quill && quill.getLength() > 3) {
        let queryParams = new URLSearchParams(propsRouter.location.search);
        let source = new URLSearchParams(propsRouter.location.search).get("src");

        if (source == "selection") {
          let start = parseInt(queryParams.get("s"));
          let length = parseInt(queryParams.get("l"));
          let type = queryParams.get("type");
          // let start = posStart;
          // let length = posEnd;

          if (start >= 0 && length >= 0 && quill) {
            loadPlagiarismCitation(start, length);
          } else {
            setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
          }
        } else {
          setErrMsg({ type: "warning", title: "Invalid Access", message: "Selection is not provided please select content and Re-run the report." });
        }
      } else {
        setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
      }
  }, [propsRouter]);

  const loadPlagiarismCitation = async (start, length) => {
    let processStart = performance.now();
    setRpLoader(true);

    setTimeout(async () => {
      try {
        if (length > 3000) {
          setRpLoader(false);
          setErrMsg({ type: "warning", title: "Limit exceed!", message: "Character limit exceeded. You can not select more than 3000 characters." });
        } else {
          let usage = await getReportUsage();
          if (usage[1].data.result.statusCode == 200) {
            var usageLimit = usage[1].data.result.result;
            if (userData.packages[0] && userData.user) {
              const quillContent = await sanitizeResetQuillAndGetContentByRange(quill, start, length);

              if (usageLimit < 50) {
                let response = await getPlagiarismCitation(quillContent);
                setReport({
                  lastReportIndexes: { start: 0, end: start + length },
                  data: { reportData: response.data.webs },
                });

                if (!response.data.webs || response.data.webs.length == 0)
                  setErrMsg({ type: "warning", title: "No result found!", message: "We do not found any results for this content, You may select different content and try to run the report again!" });

                const reportCount = !response.data.webs ? 0 : response.data.webs.length;
                setRpLoader(false);
                let end = performance.now();
                await createAnalytics({ reportName: "plagiarism-citation-report-v1", charCount: 1, executionTime: 1000, book_id: currentDoc.id, input: quillContent, output: reportCount+' Plagrism results found' });
                usageLimit = 50 - (1 + Number(usageLimit)) > 0 ? 50 - (1 + Number(usageLimit)) : 0;
              } else {
                setRpLoader(false);
                setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your Plagrism Report Usage limit of 50 requests has been expired, You can upgrade plan for additional limit." });
              }
            } else {
              setRpLoader(false);
              setErrMsg({ type: "warning", title: "Session missing!", message: "You can login and try this report again!" });
            }
          } else {
            setRpLoader(false);
            setErrMsg({ type: "warning", title: "Permission denied!", message: "Plagrism service is not available for this account." });
          }
        }
      } catch (error) {
        console.log(error);
        setRpLoader(false);
        setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later!" });
      }
    }, 1000);
  };

  let reportData = [];
  if (report?.data) {
    reportData = report.data.reportData || [];
  }

  return (
    <div className="col-lg-3 px-3 shadow-sm px-0 min-vh-100 position-fixed end-0" style={{ background: 'rgb(255 255 255)', zIndex: '999' }}>
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
          <h5 className="mt-3 text-start px-1 w-100">Plagiarism Citation
              <a href={`/editor?doc=${docId}`} className="btn theme-btn4 fs-10px py-1 px-2 float-end">Close <i className="mdi mdi-close"></i></a>
          </h5>
        </div>
        <hr />
      </div>
      <div className="result_card_cover text-start" style={{ height: 'calc(100vh - 200px)', overflowY: 'scroll', paddingRight: '10px' }}>
        <div className="reportContainer">
            {rpLoader && 
                <div className="card border-0 text-center">
                  <div className="card-body">
                    <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
                    <p className="fs-10px my-2">Fetching Data ...</p>
                  </div>
                </div>
            }
          {errMsg.hasOwnProperty("message") && (
            <Card2 title={errMsg.title} description={errMsg.message}></Card2>
          )}
          {rpLoader && reportData.length == 0 && 
              <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
          }
          {reportData && reportData.map((v, i) => (
            <div className={`card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1`} key={i}>
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>{v.title}<br /><small>{v.desc && _.truncate(v.desc, {length: 40})}</small><br />
                    <a href={`${v.url}`} className="theme-link" target="_blank"><small>{v.url.substring(0, 20)}</small></a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
