import React from 'react';
import Highcharts from 'highcharts'
import wordCloud from "highcharts/modules/wordcloud";
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash';

export default function WordCloudGraph({ wordFreqWords }) {

    let data = [];

    if (wordFreqWords.length <= 1000) {
        data = wordFreqWords.filter(d => d.weight > 2);
    } if (wordFreqWords.length <= 5000) {
        data = wordFreqWords.filter(d => d.weight > 2);
    } if (wordFreqWords.length <= 10000) {
        data = wordFreqWords.filter(d => d.weight > 3);
    } else {
        data = wordFreqWords.filter(d => d.weight > 5);
    }

    const options = {
        accessibility: {
            screenReaderSection: {
                beforeChartFormat: '<h5>{chartTitle}</h5>' + '<div>{chartSubtitle}</div>' + '<div>{chartLongdesc}</div>' + '<div>{viewTableButton}</div>'
            }
        },
        credits: { enabled: false },
        series: [{
            type: 'wordcloud',
            data: data,
            name: 'Occurrences'
        }],
        title: { text: null },
        subtitle: { text: null }
    };

    wordCloud(Highcharts);

    return (
        <HighchartsReact
            highcharts={ Highcharts }
            options={ options }
        />
    );
}