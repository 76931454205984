import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash';

export default function InitialsGraph({sentenseStarter, initialNoun, initialProNoun, initialIng, initialConjunction }) {

    const otherCount = sentenseStarter.length - (initialNoun.length + initialProNoun.length + initialIng.length + initialConjunction.length);

    const options = {
        colors: ['#ff9f4380', '#feca5780', '#10ac8480', '#2e86de80', '#33333380'],
        chart: { plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false, type: 'pie' },
        title: { text: null },
        subtitle: { text: null },
        tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>' },
        accessibility: { point: { valueSuffix: '%' } },
        credits: { enabled: false },
        plotOptions: { pie: { allowPointSelect: true, cursor: 'pointer', dataLabels: { enabled: true, format: '<b>{point.name}</b>'} } },
        series: [{
            name: 'Initial Words', colorByPoint: true,
            data: [
                { name: 'Nouns', yval: initialNoun.length, y: (initialNoun.length * 100) / sentenseStarter.length }, 
                { name: 'Pronouns', yval: initialProNoun.length, y: (initialProNoun.length * 100) / sentenseStarter.length }, 
                { name: 'Ing', yval: initialIng.length, y: (initialIng.length * 100) / sentenseStarter.length }, 
                { name: 'Conjunctions', yval: initialConjunction.length, y: (initialConjunction.length * 100) / sentenseStarter.length }, 
                { name: 'Others', yval: otherCount.length, y: (otherCount.length * 100) / sentenseStarter.length }
            ]
        }]
    };

    return (
        <HighchartsReact
            highcharts={ Highcharts }
            options={ options }
        />
    );
}