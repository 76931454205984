import { useEffect, useState } from "react";
import _ from "lodash";

import { markdown_converter } from "../../services/deltaToMarkdown";
import { quillContentInsertBlotByContentIndex, sanitizeResetQuillAndGetContent, sanitizeResetQuillAndGetContentByRange } from "../../services/highlightContent";
import { checkDifference, createAnalytics, getReportUsage, highchartsExport, triggerHumanizerAPI } from "../../services/reportServices";
import Button1 from "./Buttons/Button1";
import NLPService from "../../services/nlpService";

export default function Humanizer({ userData, inputQuill, start, end, doc, aiReportSettings, setAiReportSettings, showAIResultPopup, setShowAIResultPopup, aiResultOutput, setAiResultOutput, errMsg, setErrMsg, setCheckPage }) {

    const [loader, setLoader] = useState(false);
    const [highlightIndex, setHighlightIndex] = useState([]);
    const [styleAttr, setStyleAttr] = useState({})
    const [helpURI, setHelpURI] = useState('');
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionLength, setSelectionLength] = useState(0);
    const [resultOutput, setResultOutput] = useState('');
    const [upgradeOption, setUpgradeOption] = useState(false);
    const [reportOption, setReportOption] = useState('');

    useEffect(() => {
        setLoader(true);
        document.querySelector("#dummy-editor").innerText = '';
        checkLimit()
        .then(status => {
            if (status) {
                setCheckPage('')
                setHighlightIndex({ 'start': start, 'length': end });
                setStyleAttr({ display: 'block', minWidth: '300px', right: '0', position: 'fixed', zIndex: '999' });
                setErrMsg({});
                setAiResultOutput([]);
        
                sanitizeAndBlotInputQuillData();
        
                setReportOption(aiReportSettings.report_sub);
                setHelpURI('https://manuscripts.ai/')
        
                if (start >= 0 && end > 0) {
                    setSelectionStart(start);
                    setSelectionLength(end);
                    loadHumanizer(start, end, reportOption);
                } else {
                    setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, You need to select the content using mouse selection or keyboard selection to proceed with the report!" });
                    setSelectionStart(0);
                    setSelectionLength(0);
                }
            }
        }).catch(error => console.log(error))

    }, [aiReportSettings])

    const checkLimit = async () => {
        setErrMsg({});
        setUpgradeOption(false);
        let quillContent = await sanitizeResetQuillAndGetContentByRange(inputQuill, start, end);
        if (parseInt(userData.packages[0].amount) <= 100) {
            if (quillContent.length > 500) {
                setErrMsg({ type: "warning", title: "Trial Plan Limit!", message: "Your account has limit of upto 500 characters, Please select content with in the limit to proceed!" });
                return false;
            } else {
                getReportUsage()
                .then((response) => {
                    const used = response[3].data.result.usage_count_day;
                    if (parseInt(used) > 5) {
                        setUpgradeOption(true);
                        setErrMsg({ type: "warning", title: "Trial Plan Limit!", message: "Your account has limit of upto 5 requests, Please purchase paid plan to get access to unlimited usage!" });
                        return false;
                    }
                    return true;
                }).catch(error => console.log(error))
            }
        }
        return true;
    }

    const sanitizeAndBlotInputQuillData = async () => {
        await sanitizeResetQuillAndGetContent(inputQuill);
        const input = await inputQuill.getText(start, end);
        let highlightData = [{ start: start, end: start+end, input: input }];
        let udate = await quillContentInsertBlotByContentIndex(inputQuill, 0, highlightData, "start", "end", [{
            blotName: "span",
            id: `paraphrase-${start}-${end}`,
            classes: [`info-bar`, `paraphrase-{input}`],
            classDynamicKeys: [ "", { replaceBy: "{input}", replaceToKey: "input" }],
        }]);
    }

    const loadHumanizer = (start, length, type) => {
        setErrMsg({});
        setLoader(true);

        setTimeout(async () => {
            try {
                let usage = await getReportUsage();
                let quillContent = await sanitizeResetQuillAndGetContentByRange(inputQuill, start, length);
                if (usage[3].data.result.statusCode == 200) {
                    // var usageLimit = usage[0].data.result.result;
                    triggerHumanizerAPI(quillContent, reportOption).then((response) => {
                        if (response.data.status == 'Success') {
                            setResultOutput(response.data.data)
                            setAiResultOutput([response.data.data]);
                            document.querySelector("#dummy-editor").innerText = response.data.data;
                            setLoader(false);
                            createAnalytics({ reportName: "humanizer", charCount: 1, executionTime: 1000, book_id: doc.id, input: quillContent, output: response.data.data }).catch((error) => { console.log(error) });

                            checkDifference(quillContent, response.data.data)
                            .then((diff) => {
                                let initial = 0;
                                let content = '';
                    
                                const showDeleted = false;
                    
                                diff.data.data.map((d) => {
                                    if (d.s != 'removed' || showDeleted) {
                                        if (initial == 0) {
                                            content += d.str.trim();
                                        } else {
                                            content += d.str;
                                        }
                        
                                        initial += 1;
                                    }
                                })
                    
                                
                                const nlpService = new NLPService("");
                                nlpService.setContent(content);
                                let sentences = nlpService.getSentaces();
                    
                                let index = 0;
                                let sentenceIndex = 0;
                                let sentenceStart = sentences[sentenceIndex].stringEndIndex;
                    
                                let blotHtml = `<customtag id="sentence-${sentenceStart}" class="sentence">`;
                    
                                diff.data.data.map((d, i) => {
                                    let chunk = d.str;
                                    if (index == 0) chunk = chunk.trim()
                    
                                    let arr = chunk.split(' ');
                    
                                    let html = '';
                    
                                    if (d.s !== 'removed' || showDeleted) {
                                        arr.map((str) => {
                                            if (str.length > 0) {
                                                let srtArr = str.split("\n")
                                                if (srtArr.length > 1) {
                                                    srtArr.map((words) => {
                                                        if (words.length > 0) {
                                                            index += words.length;
                                                            html += `<span id="word-${index}" class="word-${d.s}">${words}</span>`;
                                                        } else {
                                                            index += 1;
                                                            sentenceIndex += 1;
                                                            if (sentenceIndex < sentences.length) {
                                                                sentenceStart = sentences[sentenceIndex].sentenceStartIndex + sentences[sentenceIndex].stringEndIndex;
                                                            }
                                                            html += `<span class="word-${d.s}"> </span>`;
                                                            html += `</customtag><p></br></p><customtag id="sentence-${sentenceStart}" class="sentence">`;
                                                        }
                                                    })
                                                } else {
                                                    if (index > sentenceStart) {
                                                        sentenceIndex += 1;
                                                        if (sentenceIndex < sentences.length) {
                                                            sentenceStart = sentences[sentenceIndex].sentenceStartIndex + sentences[sentenceIndex].stringEndIndex;
                                                        }
                                                        html += `</customtag><customtag id="sentence-${sentenceStart}" class="sentence">`;
                                                    }
                                                    index += str.length;
                                                    html += `<span id="word-${index}" class="word-${d.s}">${str}</span>`;
                                                }
                                            } else {
                                                index += 1;
                                                html += `<span class="word-${d.s}"> </span>`;
                                            }
                                        })
                                    }
                    
                                    blotHtml += html;
                                })
                    
                                blotHtml += `</customtag>`;
                    
                                document.querySelector("#dummy-editor").innerHTML = blotHtml;
                            }).catch((error) => {
                                console.log("Unable to load difference api!")
                            })
                        } else {
                            setLoader(false);
                            setErrMsg({ type: "warning", title: "Network Error!", message: "Unable to generate humanize content. Please try again later." });
                        }
                    }).catch(error => setLoader(false))
                } else {
                    setLoader(false);
                    setErrMsg({ type: "warning", title: "Permission Denied!", message: "AI service is not available for this account." });
                }
            } catch (error) {
                setLoader(false);
                setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
            }
        }, 0);
    }

    const checkData = async (e) => {
        e.preventDefault();
        setErrMsg({});
        document.querySelector("#dummy-editor").innerText = '';
        checkLimit()
        .then(async (status) => {
            if (status) {
                await loadHumanizer(start, end, reportOption);
            }
        }).catch(error => console.log(error))
    }

    const clearAndCloseBar = async () => {
        await sanitizeResetQuillAndGetContent(inputQuill);
        setAiResultOutput([]);
        setAiReportSettings({})
        setShowAIResultPopup(false);
    }

    let handleTextParaphrasing = async (resultOutput, className) => {
        let ops = await markdown_converter(resultOutput);
        let updateOps = [{ retain: highlightIndex.start }, { delete: highlightIndex.length }]

        let startIndex = highlightIndex.start;

        ops.map((op) => {
            if (op?.attributes) {
                updateOps.push({ insert: op?.insert, attributes: op?.attributes })
            } else {
                updateOps.push({ insert: op?.insert })
            }
            startIndex += op.insert.length;
        })

        await inputQuill.updateContents(updateOps, 'user')

        setAiResultOutput([]);
        setAiReportSettings({})
        setShowAIResultPopup(false);
        inputQuill.setSelection(startIndex);
        setErrMsg({ errMsg: null });
    };

    let handleTextRewrite = async (resultOutput, className) => {
        let ops = await markdown_converter(resultOutput);
        let startIndex = highlightIndex.start + highlightIndex.length;
        let updateOps = [{ retain: startIndex }]

        ops.map((op) => {
            if (op?.attributes) {
                updateOps.push({ insert: op?.insert, attributes: op?.attributes })
            } else {
                updateOps.push({ insert: op?.insert })
            }
            startIndex += op.insert.length;
        })
        await inputQuill.updateContents(updateOps, 'user')
        setAiResultOutput([]);
        setAiReportSettings({})
        setShowAIResultPopup(false);
        inputQuill.setSelection(startIndex);
        setErrMsg({ errMsg: null });
    };

    let paraPhrasingReport = [];

    if (aiResultOutput?.length != 0) {
        paraPhrasingReport = aiResultOutput || [];
    }

    return (
        <div style={styleAttr} className="col-xl-5 col-lg-4 min-vh-100 bg-light">
            <div className="row text-white theme-background">
                <div className="col-4 my-auto text-start">
                    <p className="fw-semibold mb-0">{aiReportSettings.report}</p>
                </div>
                <div className="col-8 my-2 text-end">
                    {upgradeOption && 
                        <a target="_blank" href={`https://manuscripts.ai/pricing-plans/`} className="btn text-bg-danger py-1 px-2 fs-12px mx-2">Upgrade Now!</a>
                    }
                    <a target="_blank" href={helpURI} className="btn theme-btn py-1 px-2 fs-12px mx-2">Need Help ?</a>
                    <button onClick={(e) => clearAndCloseBar()} className="btn text-white theme-btn2 py-1 px-2 fs-12px mx-2"><span className="mdi mdi-close me-2"></span>Close</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-3">
                    <div className="bg-white overflow-scroll mb-2" data-gramm="false" translate="no" spellCheck="false" style={{ maxHeight: '70vh', height: '70vh' }} id="dummy-editor"></div>
                </div>
                <div className="col-12 text-end">
                    {paraPhrasingReport.length == 0 && 
                        <>
                            <button onClick={(e) => checkData(e)} className="btn theme-btn6 py-2 fs-12px m-2" disabled={loader ? true : false}>{loader && <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>} Generate Content</button>
                            <button onClick={(e) => clearAndCloseBar()} className="btn theme-btn2 py-1 px-2 fs-12px m-2"><span className="mdi mdi-close me-2"></span>Close</button>
                        </>
                    }
                    {paraPhrasingReport && paraPhrasingReport.length > 0 && 
                        <>
                            {aiReportSettings.report != 'Expand Content with AI' && aiReportSettings.report != 'Complete Story with AI' && aiReportSettings.report != 'Summary Generator' && aiReportSettings.report != 'Opposing Arguments' && (
                                <>
                                    <Button1 disabled={loader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextParaphrasing(resultOutput, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                        <span className="mdi mdi-swap-horizontal me-2"></span>Replace
                                    </Button1>
                                    <Button1 disabled={loader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextRewrite(resultOutput, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                        <span className="mdi mdi-arrow-left-bottom me-2"></span>Insert Below
                                    </Button1>
                                </>

                            )}
                            {(aiReportSettings.report == 'Expand Content with AI' || aiReportSettings.report == 'Complete Story with AI' || aiReportSettings.report == 'Summary Generator' || aiReportSettings.report == 'Opposing Arguments') && (
                                <Button1  disabled={loader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextRewrite(resultOutput, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                    <span className="mdi mdi-check me-2"></span>Insert Below
                                </Button1>
                            )}
                            <button onClick={(e) => checkData(e)} className="btn theme-btn4 px-2 fs-12px m-1" disabled={loader ? true : false}> {loader ? <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : <span className="mdi mdi-refresh me-2"></span> } Regenerate</button>
                            <button onClick={(e) => clearAndCloseBar()} className="btn theme-btn2 py-1 px-2 fs-12px m-2"><span className="mdi mdi-close me-2"></span>Reject & Close</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}