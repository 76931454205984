import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import _, { round } from 'lodash';

export default function DialogueTagsGraph({ dialogueReport, dialogueReportFiltered }) {
    const otherTags = round(( dialogueReport - dialogueReportFiltered )* 100 / dialogueReport );
    const saidaskedTags = round( dialogueReportFiltered * 100 / dialogueReport );

    const options = {
        colors: ['#2d9cdb80', '#ef5d1780'],
        chart: { plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false, type: 'pie' },
        title: { text: null },
        subtitle: { text: null },
        tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>' },
        accessibility: { point: { valueSuffix: '%' } },
        credits: { enabled: false },
        plotOptions: {
            pie: { allowPointSelect: true, cursor: 'pointer', dataLabels: { enabled: false, format: '<b>{point.name}</b>: {point.percentage:.1f} %'}, showInLegend: true },
        },
        series: [{
            name: 'Tags', colorByPoint: true,
            legend: { enabled: true }, 
            data: [{
                name: 'Said & Asked',
                y: saidaskedTags,
                sliced: true,
                selected: true
            }, {
                name: 'Dialogue Tags',
                y: otherTags
            }]
        }]
    };

    return (
        <HighchartsReact
            highcharts={ Highcharts }
            options={ options }
        />
    );
}