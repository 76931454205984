import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import GoogleLogin from '@leecheuk/react-google-login';

import { setData } from "../../services/dataService";
import { userRegisterGoogle } from "../../services/authService";
import { handleHttpError } from "../../services/httpErrorService";

import AuthorImg from "../../assets/author.png"
import StudentImg from "../../assets/student.png"
import { checkEmail } from "../../services/authService";

const SocialAuth = ({ page, destination }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [profile, setProfile] = useState({});
  const [checkUser, setCheckUser] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "", login: "" });

  setTimeout(() => {
    switch (destination) {
      case 'Facebook':
        var el = document.querySelector('.facebook-login-button');
        el.click();
        navigate(`/login`);
        break;
      case 'Google':
        var el = document.querySelector('.google-login button');
        el.click();
        break;
      default:
        break;
    }
  }, 2000);

  const responseGoogle = (response) => {
    if (response.profileObj) {
      setEmail(response.profileObj.email);
      setName(response.profileObj.name);
      const errors = validateCridentials( response.profileObj.email, response.profileObj.name );
      setProfile(response.profileObj);
      checkEmail({ email: response.profileObj.email })
      .then((response) => {
        if (response.status == 200) {
          setCheckUser(true);
        } else {
          setErrors({ email: "", name: "", all: "Somthing went wrong!" });
        }
      })
      .catch((error) => {
        if (error.response.data?.error?.email) {
          let user_mode = 'Author';
          if (error.response.data?.error?.data?.user_mode) {
            user_mode = error.response.data?.error?.data?.user_mode;
          }
          continueToSignUp(response.profileObj, user_mode);
          setErrors({ email: error.response.data?.error?.email, name: "", all: "" });
        } else {
          setErrors({ email: "", name: "", all: "Somthing went wrong!" });
        }
      })

      if (errors) return setErrors(errors);

      setErrors({ email: "", name: "", all: "" });
    }
  };

  const continueToSignUp = (profileData, user_mode) => {
      setLoader(true);
      userRegisterGoogle({
        email: profileData.email,
        name: profileData.name,
        username: profileData.email,
        img: profileData.imageUrl,
        social_id: profileData.googleId,
        account_type: "Google",
        user_type: user_mode
      })
      .then((httpResponse) => handleSuccess(httpResponse))
      .catch((httpResponse) => handleError(httpResponse.response));
  }

  const handleSuccess = (response) => {
    if (response.status == 200) {
      let { token_type, token, user, type } = response.data.result;
      if (type == "new") {
        setLoader(false);
        if (user.user_mode == "Student") {
          window.location.href = `https://manuscripts.thrivecart.com/students-professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`;
        } else {
          window.location.href = `https://manuscripts.thrivecart.com/professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`;
        }
      } else {
        setData("token", token);
        setData("token_type", token_type);
        setData("user", user);
        setLoader(false);
        window.location.reload();
      }
    }
    // if (response.status == 200) {
    //   let { token_type, token, user, type } = response.data.result;
    //   if (type == "new") {
    //     window.location.href = `https://manuscripts.thrivecart.com/professional-plan/?passthrough[customer_name]=${user.name}&passthrough[customer_email]=${user.email}&passthrough[customer_contactno]=${user.mobile}`;
    //   } else {
    //     setData("token_type", token_type);
    //     setData("token", token);
    //     setData("user", user);
    //     window.location.reload();
    //   }
    // }
  };

  const handleError = (error) => {
    if (error && (error.status == 500 || error.status == 400)) {
      let { login, email, password } = error.data.error || {};
      setErrors({
        login: login != undefined ? login : "",
        username: email,
        password: password, 
        all: ""
      });
      setLoader(false);
    } else {
      setLoader(false);
      handleHttpError(error);
    }
  };

  const validateCridentials = (email, name) => {
    const schema = Joi.object({
      email: Joi.string().email({ tlds: { allow: false } }).min(5).max(50).required(),
      name: Joi.string().min(3).max(30).required().label("Full Name"),
    });
    const { error } = schema.validate({ email, name }, { abortEarly: false });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  return (
    <div className="row">
      {checkUser && 
        <div className="position-fixed z-9 w-100 h-100">
          <div className="modal show">
              <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalToggleLabel">Select Occupation</h5>
                      </div>
                      <div className="modal-body p-5">
                          <div className="row">
                              <div className="col-lg-6">
                                  <div className="card h-100 p-4">
                                      <div className="card-body">
                                          <h6 className="fw-normal lh-base">Are you an author, want to write and edit your story?</h6>
                                          <img className="img-fluid" src={AuthorImg} alt="" />
                                      </div>
                                      <div className="card-footer bg-transparent border-0 my-2">
                                          <button onClick={(e) => continueToSignUp(profile, "Author")} className="fs-14px btn px-4 theme-btn6">
                                            <span className="me-2">Continue</span>
                                            {loader && 
                                              <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                                            }
                                            {!loader && 
                                              <span className="mdi mdi-chevron-right"></span>
                                            }
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6">
                                  <div className="card h-100 p-4">
                                      <div className="card-body">
                                          <h6 className="fw-normal lh-base">Are you a student or a researcher, want to write and edit your research paper?</h6>
                                          <img className="img-fluid" src={StudentImg} alt="" />
                                      </div>
                                      <div className="card-footer bg-transparent border-0 my-2">
                                          <button onClick={(e) => continueToSignUp(profile, "Student")} className="fs-14px btn px-4 theme-btn6">
                                            <span className="me-2">Continue</span>
                                            {loader && 
                                              <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                                            }
                                            {!loader && 
                                              <span className="mdi mdi-chevron-right"></span>
                                            }
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      }
      <div className="col-12 google-login-custom">
        <GoogleLogin
          clientId="995464585929-bogg8rbq43vvt8lueqnt6osgdhruhmg1.apps.googleusercontent.com"
          buttonText={`${page} with Google`}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </div>
      {errors?.all && (
        <div className="form-text text-danger">{errors?.all}</div>
      )}
      {errors?.email && (
        <div className="form-text text-danger">{errors?.email}</div>
      )}
      {errors?.accountType && (
        <div className="form-text text-danger">{errors?.accountType}</div>
      )}
      {errors?.img && (
        <div className="form-text text-danger">{errors?.img}</div>
      )}
      {errors?.username && (
        <div className="form-text text-danger">{errors?.username}</div>
      )}
      {errors?.socialId && (
        <div className="form-text text-danger">{errors?.socialId}</div>
      )}
    </div>
  );
};

export default SocialAuth;
